import { ReactNode, useCallback, useContext, useRef, useState } from "react"
import classNames from "classnames"
import { useHistory } from "react-router-dom"

import Button from "components/UI/elements/Button/Button"
import { ChannelWizardContext } from "../channelWizardContext"
import ConfirmModal from "components/UI/components/ConfirmModal"
import IconButton from "components/UI/elements/IconButton/IconButton"
import Paper from "components/UI/elements/Paper"
import { goBackInHistory } from "helpers/backButton.helper"
import { MODAL } from "sharedConstants"
import { getRoutePath } from "routes"

import styles from "./ChannelPaper.module.scss"

type ChannelPaperProps = {
  children: ReactNode
  isWizard: boolean
  title: string
  description?: string | ReactNode
  expandable?: boolean
  hasUnsavedChanges?: boolean
  hideSubmitButton?: boolean
  id?: string
  isSubmitting?: boolean
  skipSubmit?: boolean
  submitDisabled?: boolean
}

export default function ChannelPaper({
  children,
  description,
  hideSubmitButton,
  id,
  isSubmitting,
  isWizard,
  skipSubmit,
  submitDisabled,
  title,
  expandable = false,
  hasUnsavedChanges = false,
}: ChannelPaperProps) {
  const [modalOpen, setModalOpen] = useState(false)
  const {
    currentStep,
    isFirstStep,
    isLastStep,
    steps,
    expandedSection,
    decrement,
    increment,
    setExpandedSection,
  } = useContext(ChannelWizardContext)

  const scrollToRef = useRef<HTMLDivElement>(null)

  const scrollTo = useCallback(() => {
    if (scrollToRef && scrollToRef.current)
      scrollToRef.current.scrollIntoView({
        behavior: "smooth",
      })
  }, [scrollToRef])

  const history = useHistory()

  const goBack = () => {
    if (isFirstStep) goBackInHistory(history, getRoutePath("administration.channels"))()
    else decrement()
  }

  const closeModal = () => setModalOpen(false)

  const isExpanded = id && expandedSection ? expandedSection === id : false

  const headerContent = !hideSubmitButton && (
    <Button
      disabled={submitDisabled}
      icon={isWizard ? "arrow-right" : undefined}
      iconPosition="end"
      loading={isSubmitting}
      type={isLastStep ? "submit" : skipSubmit ? "button" : "submit"}
      onClick={() => {
        if (skipSubmit) increment()
      }}
    >
      {isWizard ? (
        isLastStep ? (
          "Finish"
        ) : (
          <>
            {currentStep}
            <span className={styles.steps}>/{steps}</span> Continue
          </>
        )
      ) : (
        "Save"
      )}
    </Button>
  )

  return (
    <>
      <Paper className={styles.paper} ref={scrollToRef}>
        <div className={classNames(styles.paperHeader, { [styles.expanded]: isExpanded })}>
          <div className={styles.wrapper}>
            {isWizard && (
              <IconButton
                onClick={() => {
                  if (hasUnsavedChanges) setModalOpen(true)
                  else goBack()
                }}
                icon="chevron-left"
                variant="transparent"
                className={styles.backButton}
              />
            )}
            <div className={styles.titleWrapper}>
              <h3>{title}</h3>
              {description && <p>{description}</p>}
            </div>
          </div>
          {expandable ? (
            <div>
              {isExpanded && headerContent}
              <IconButton
                color="grey"
                icon={isExpanded ? "chevron-up" : "chevron-down"}
                onClick={() => {
                  if (isExpanded) setExpandedSection(null)
                  else if (id) {
                    setExpandedSection(id)
                    setTimeout(scrollTo, 1e3 / 60) // wait 16ms (single frame) to use scrollIntoView - little trick from MUI
                  }
                }}
                variant="transparent"
                className={styles.expandButton}
              />
            </div>
          ) : (
            headerContent
          )}
        </div>
        {expandable && !isExpanded ? null : children}
      </Paper>
      <ConfirmModal
        open={modalOpen}
        title="Are you sure?"
        text="Changes you made will not be saved."
        type={MODAL.TYPE.CANCEL}
        handleClose={closeModal}
        handleConfirm={() => {
          closeModal()
          goBack()
        }}
      />
    </>
  )
}
