import React from "react"
import styles from "./NewReleaseModal.module.scss"
import Modal from "components/UI/elements/Modal"
import Button from "components/UI/elements/Button/Button"
import { useModifyUser } from "resources/user/userQueries"
import { useFetchCurrentUser } from "resources/user/currentUserQueries"
import IconButton from "components/UI/elements/IconButton/IconButton"
import oneTimeSegmentExport from "./one-time-segment-export.gif"

const CURRENT_NEW_FEATURE = "one_time_segment_export"

export default function NewReleaseModal() {
  const { data: currentUser } = useFetchCurrentUser()
  const { mutate: modifyUser } = useModifyUser()

  const { id, frontend_settings } = currentUser!

  const handleClose = () => {
    modifyUser({
      id,
      data: {
        frontend_settings: {
          ...(frontend_settings ?? {}),
          lastReleaseModalShown: CURRENT_NEW_FEATURE,
        },
      },
    })
  }

  const { lastReleaseModalShown } = frontend_settings ?? {}

  // @ts-ignore
  if (window.Cypress) return null

  return (
    <Modal
      open={lastReleaseModalShown !== CURRENT_NEW_FEATURE}
      customHeader={
        <header>
          <h3>🎉 Check out the updates:</h3>
          <IconButton
            color="grey"
            icon={["fas", "times"]}
            tooltip="Close"
            variant="transparent"
            onClick={handleClose}
            data-testid="close-button"
          />
        </header>
      }
      handleClose={handleClose}
      className={styles.modal}
      contentClassName={styles.content}
    >
      <h3>Gain more control over exporting segments to the preferred destination</h3>

      <p>
        When exporting segments, you can now choose between a one-time and recurring schedule. With
        the one-time schedule, specify a date and time for exporting to be executed once.
        Alternatively, select a recurring schedule to send exports repeatedly at a chosen time of
        the day. Learn more{" "}
        <a
          href="https://docs.meiro.io/books/meiro-business-explorer/page/segment-detail-page#bkmrk-choose-destination"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        .
      </p>
      <img src={oneTimeSegmentExport} alt="" />

      <div className={styles.buttonWrapper}>
        <Button size="md" onClick={handleClose}>
          Got it
        </Button>
      </div>
    </Modal>
  )
}
