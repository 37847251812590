import { useEffect, useState } from "react"
import Tippy from "@tippyjs/react"
import classNames from "classnames"
import { update, whereEq } from "ramda"

import { showToast } from "app/toast"
import Button from "components/UI/elements/Button/Button"
import SegmentationNumbers from "components/UI/components/SegmentationNumbers"
import Scheduler from "../Scheduler/Scheduler"
import SrcDstIcon from "components/UI/elements/SrcDstIcon/SrcDstIcon"
import ParametersController from "../ParametersController"
import {
  useCancelSegmentExport,
  useRunSegmentExport,
} from "resources/segment/segmentExport/segmentExportQueries"
import { Segment, SegmentSchedule } from "resources/segment/segment/segmentTypes"
import { SegmentExportDestination } from "resources/exportDestination/exportDestinationTypes"
import { useAllowedDestinationsIds } from "resources/user/currentUserQueries"
import { useSegmentCountsStore } from "resources/segment/segment/segmentCounts"
import {
  SegmentExport,
  SegmentExportRunResponse,
} from "resources/segment/segmentExport/segmentExportTypes"
import { useModifySegment } from "resources/segment/segment/segmentQueries"

import styles from "./SegmentExportRow.module.scss"

type SegmentExportRowProps = {
  isEditable: boolean
  destination: SegmentExportDestination
  segment: Segment
  refetchExports: () => void
  refetchSegmentNumbers: () => void
  segmentExport?: SegmentExport
}

export function SegmentExportRow({
  isEditable,
  destination,
  segment,
  segmentExport,
  refetchExports,
  refetchSegmentNumbers,
}: SegmentExportRowProps) {
  const [segmentExportInProgress, setSegmentExportInProgress] =
    useState<SegmentExportRunResponse["segment_export"]>()

  useEffect(() => {
    setSegmentExportInProgress(segmentExport as SegmentExportRunResponse["segment_export"])
  }, [segmentExport])

  const allowedDestinationIds = useAllowedDestinationsIds()
  const { exportDestinationResultsCount, error: segmentNumbersError } = useSegmentCountsStore()

  const { mutateAsync: modifySegment } = useModifySegment()
  const { mutate: run, isLoading: isRunning } = useRunSegmentExport()
  const { mutate: cancel, isLoading: isCancelling } = useCancelSegmentExport()

  const runSegmentExport = () => {
    run(
      {
        id: segment.id,
        data: {
          name: `${destination.name} export`,
          segment_export_destination_id: destination.id,
        },
      },
      {
        onSuccess: ({ segment_export }) => {
          setSegmentExportInProgress(segment_export)
          refetchSegmentNumbers()
        },
        onError: () => refetchExports(),
      },
    )
  }

  const cancelSegmentExport = () => {
    if (!segmentExportInProgress) return

    cancel(
      { exportId: segmentExportInProgress.id, segmentId: segment.id },
      {
        onError: refetchExports,
      },
    )
  }

  const modifySegmentDestinationParameters = (dstObj: any): Promise<{ segment: Segment }> => {
    let dataToSend = {
      settings: {
        destination_parameters: dstObj,
      },
    }
    if (segment.settings) {
      const settings = segment.settings
      if (settings.destination_parameters) {
        // merge with destination_parameters
        dataToSend = {
          settings: {
            ...settings,
            destination_parameters: {
              ...settings.destination_parameters,
              ...dstObj,
            },
          },
        }
      } else {
        // merge destination_parameters with existing settings
        dataToSend = {
          settings: {
            ...settings,
            destination_parameters: dstObj,
          },
        }
      }
    }

    return modifySegment({ id: segment.id, data: dataToSend })
  }

  const modifySegmentScheduling = (
    schedule: Required<SegmentSchedule>["schedules"] | Required<SegmentSchedule>["schedule_time"],
  ): Promise<{ segment: Segment }> | undefined => {
    const newSchedule: SegmentSchedule = Array.isArray(schedule)
      ? {
          destination_id: destination.id,
          schedule_type: "repetitive",
          schedules: schedule,
          schedule_time: undefined,
        }
      : {
          destination_id: destination.id,
          schedule_type: "static",
          schedule_time: schedule,
          schedules: undefined,
        }

    const destinationScheduleIndex = segment.schedules.findIndex(
      whereEq({ destination_id: destination.id }),
    )
    const schedules =
      destinationScheduleIndex === -1
        ? segment.schedules?.concat(newSchedule)
        : update(destinationScheduleIndex, newSchedule, segment.schedules)

    return modifySegment(
      { id: segment.id, data: { schedules } },
      {
        onSuccess: () => {
          showToast("Segment export schedule modified.")
        },
      },
    )
  }

  const destinationStatus = segmentExportInProgress?.status
  let segmentNumberDestinationIndex = -1
  segmentNumberDestinationIndex = exportDestinationResultsCount.findIndex(
    ({ exportDestinationId }) => exportDestinationId === destination.id,
  )

  const destinationSchedule = segment.schedules.find(whereEq({ destination_id: destination.id }))
  const schedules = destinationSchedule?.schedules ?? []
  const staticSchedule = destinationSchedule?.schedule_time

  let countOfRequiredParameters = 0
  let countOfFilledParameters = 0
  const destinationParameters = segment.settings?.destination_parameters?.[destination.id] ?? {}
  const miWorkspaceVariables = destination.settings?.mi_workspace_variables ?? []

  miWorkspaceVariables.forEach((param: { name: string; required: boolean }) => {
    if (param.required) {
      countOfRequiredParameters += 1
      if (destinationParameters[param.name]) {
        countOfFilledParameters += 1
      }
    }
  })

  const schedulesExist = schedules.length > 0 || Boolean(staticSchedule)
  const isExportAllowed =
    countOfFilledParameters >= countOfRequiredParameters &&
    allowedDestinationIds.includes(destination.id)
  const canSheduleBeOpened = (isExportAllowed && isEditable) || schedulesExist
  const schedulerPermission = !canSheduleBeOpened
    ? "disabled"
    : allowedDestinationIds.includes(destination.id) && isEditable
    ? "edit"
    : "view"

  const isSegmentExportInProgress =
    destinationStatus && ["waiting", "running"].includes(destinationStatus)

  return (
    <tr data-testid={`row-${destination.id}`} className={styles.segmentExportSettingsRow}>
      <td className={styles.destination}>
        <div className={styles.destinationChipWrapper}>
          <div
            className={classNames(
              styles.destinationChip,
              styles[destination.frontend_settings?.color],
            )}
          >
            <SrcDstIcon destination={destination} white className={styles.destinationIcon} />
          </div>
          <div className={styles.destinationInfo} data-testid="destination-info">
            {destination.name}
          </div>
        </div>
      </td>
      <td>
        <SegmentationNumbers
          totalNumber={
            segmentNumberDestinationIndex === -1
              ? undefined
              : exportDestinationResultsCount[segmentNumberDestinationIndex].totalCount
          }
          segmentedNumber={
            segmentNumberDestinationIndex === -1
              ? undefined
              : exportDestinationResultsCount[segmentNumberDestinationIndex].count
          }
          isLoading={
            typeof (segmentNumberDestinationIndex === -1
              ? undefined
              : exportDestinationResultsCount[segmentNumberDestinationIndex].totalCount) !==
              "number" ||
            typeof (segmentNumberDestinationIndex === -1
              ? undefined
              : exportDestinationResultsCount[segmentNumberDestinationIndex].count) !== "number"
          }
          uniqueDataTipId={`tooltip-destination-${destination.id}`}
          showLabel={false}
          error={!!segmentNumbersError}
        />
      </td>
      <td>
        {destination.id === 2 ? (
          "—"
        ) : (
          <Scheduler
            id={destination.id}
            repetitiveSchedules={schedules}
            staticSchedule={staticSchedule}
            onSaveRepetitiveSchedules={modifySegmentScheduling}
            onSaveStaticSchedule={schedule => modifySegmentScheduling(schedule ?? [])}
            permission={schedulerPermission}
            tooltip={
              !isEditable
                ? "You need edit permission for this segment to add export schedules."
                : !allowedDestinationIds.includes(destination.id)
                ? "You don't have permission to schedule exports for this destination."
                : "You need to fill all the required parameters to be able to schedule exports."
            }
            tooltipDisabled={canSheduleBeOpened}
          />
        )}
      </td>
      <td>
        {
          <ParametersController
            exportDestinationId={destination.id}
            initialValues={destinationParameters}
            onSaveParameters={modifySegmentDestinationParameters}
            fieldsConfig={miWorkspaceVariables}
            countOfRequiredParameters={countOfRequiredParameters}
            countOfFilledParameters={countOfFilledParameters}
            isEditable={isEditable}
          />
        }
      </td>
      <td>
        <div className={styles.exportActions}>
          <Tippy
            content={
              !allowedDestinationIds.includes(destination.id)
                ? "You don't have permission to run export for this destination."
                : "You need to fill all the required parameters to be able to run export."
            }
            disabled={isExportAllowed}
          >
            <div>
              <Button
                disabled={!isExportAllowed}
                icon={isSegmentExportInProgress ? ["far", "hourglass"] : undefined}
                loading={isRunning || isCancelling}
                spinIcon={destinationStatus === "waiting"}
                onClick={() =>
                  isSegmentExportInProgress ? cancelSegmentExport() : runSegmentExport()
                }
                className={classNames({
                  [styles.running]: destinationStatus === "running",
                  [styles.waiting]: destinationStatus === "waiting",
                })}
              >
                {isSegmentExportInProgress ? "Cancel" : "Export"}
              </Button>
            </div>
          </Tippy>
        </div>
      </td>
    </tr>
  )
}
