import React from "react"
import styles from "./Transformation.module.scss"
import {
  DeleteTranformation,
  MapValuesTranformation,
  ReplaceStringTranformation,
  StripStringTranformation,
  Transformation as TransformationType,
} from "resources/event/eventTypes"
import { DraggableSyntheticListeners } from "@dnd-kit/core"
import {
  Controller,
  FieldErrorsImpl,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form"
import { EventFormValues } from "../EventForm/EventForm"
import SelectField from "components/UI/elements/SelectField"
import { SelectOption } from "types/util"
import DelayedTooltip from "components/UI/elements/DelayedTooltip/DelayedTooltip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ToggleButton from "components/UI/elements/ToggleButton/ToggleButton"
import IconButton from "components/UI/elements/IconButton/IconButton"
import { required, requiredArray } from "helpers/validators.helper"
import TextInput from "components/UI/elements/TextInput/TextInput"
import Button from "components/UI/elements/Button/Button"
import PathField from "components/UI/elements/PathField/PathField"

type TransformationProps = {
  index: number
  dragListeners?: DraggableSyntheticListeners
  removeSelf?: () => void
  isEditable?: boolean
}

const typeOptions: SelectOption<TransformationType["type"]>[] = [
  { value: "Move", label: "Move" },
  { value: "MapValues", label: "Map values" },
  { value: "NamePopUpBanner", label: "Name pop up banner" },
  { value: "NameNativeBanner", label: "Name native banner" },
  { value: "CzechVocative", label: "Czech vocative" },
  { value: "CzechGender", label: "Czech gender" },
  { value: "ReplaceString", label: "Replace in string" },
  { value: "StripString", label: "Strip string" },
  { value: "StripWhiteSpaces", label: "Strip whitespaces" },
  { value: "LowercaseString", label: "Lowercase string" },
  { value: "ValidateEmail", label: "Validate email" },
  { value: "Delete", label: "Delete" },
]

export default function Transformation({
  index,
  dragListeners,
  removeSelf,
  isEditable = true,
}: TransformationProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext<EventFormValues>()
  const transformationErrors = errors?.auto_load?.transformations?.[index]
  const type = useWatch({ control, name: `auto_load.transformations.${index}.type` })

  let inputs = (
    <div data-testid="source-destination-paths" className={styles.srcDstRow}>
      <Controller
        name={`auto_load.transformations.${index}.source`}
        control={control}
        rules={{ validate: requiredArray }}
        render={({ field }) => (
          <PathField
            {...field}
            label="Source path"
            error={transformationErrors?.source?.message}
            disabled={!isEditable}
          />
        )}
      />
      <Controller
        name={`auto_load.transformations.${index}.destination`}
        control={control}
        rules={{ validate: requiredArray }}
        render={({ field }) => (
          <PathField
            {...field}
            label="Destination path"
            error={
              (
                transformationErrors as FieldErrorsImpl<
                  Exclude<TransformationType, DeleteTranformation>
                >
              )?.destination?.message
            }
            disabled={!isEditable}
          />
        )}
      />
    </div>
  )

  if (type === "MapValues") {
    inputs = (
      <>
        {inputs}
        {/* Separate component so that the values_map field array unmounts when the type changes */}
        <MapValuesArray index={index} isEditable={isEditable} />
      </>
    )
  }

  if (type === "ReplaceString") {
    inputs = (
      <>
        {inputs}
        <div className={styles.srcDstRow}>
          <Controller
            name={`auto_load.transformations.${index}.old`}
            control={control}
            rules={{ validate: required }}
            defaultValue=""
            render={({ field }) => (
              <TextInput
                {...field}
                placeholder="Substring to replace"
                label="Substring to replace"
                error={
                  (transformationErrors as FieldErrorsImpl<ReplaceStringTranformation>)?.old
                    ?.message
                }
                disabled={!isEditable}
              />
            )}
          />
          <Controller
            name={`auto_load.transformations.${index}.new`}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextInput
                {...field}
                placeholder="Replace with"
                label="Replace with"
                error={
                  (transformationErrors as FieldErrorsImpl<ReplaceStringTranformation>)?.new
                    ?.message
                }
                disabled={!isEditable}
              />
            )}
          />
        </div>
      </>
    )
  }

  if (type === "StripString") {
    inputs = (
      <>
        {inputs}
        <Controller
          name={`auto_load.transformations.${index}.characters`}
          control={control}
          defaultValue=""
          rules={{ validate: required }}
          render={({ field }) => (
            <TextInput
              {...field}
              placeholder="Characters"
              label="Characters"
              error={
                (transformationErrors as FieldErrorsImpl<StripStringTranformation>)?.characters
                  ?.message
              }
              disabled={!isEditable}
            />
          )}
        />
      </>
    )
  }

  if (type === "Delete") {
    inputs = (
      <Controller
        name={`auto_load.transformations.${index}.source`}
        control={control}
        rules={{ validate: requiredArray }}
        render={({ field }) => (
          <PathField
            {...field}
            label="Path"
            error={transformationErrors?.source?.message}
            disabled={!isEditable}
          />
        )}
      />
    )
  }

  return (
    <div className={styles.container}>
      <DelayedTooltip content="Move condition">
        {isEditable && (
          <div className={styles.dragHandle} {...dragListeners}>
            <FontAwesomeIcon icon={["fas", "grip-vertical"]} />
          </div>
        )}
      </DelayedTooltip>
      <div className={styles.main}>
        <div className={styles.topRow}>
          <Controller
            name={`auto_load.transformations.${index}.enabled`}
            control={control}
            render={({ field }) => (
              <ToggleButton
                value={field.value}
                handleToggle={() => field.onChange(!field.value)}
                size="sm"
                disabled={!isEditable}
              />
            )}
          />
          <Controller
            control={control}
            name={`auto_load.transformations.${index}.type`}
            render={({ field }) => (
              <SelectField
                input={field}
                options={typeOptions}
                label="Type"
                isSimpleValue
                disabled={!isEditable}
              />
            )}
          />
        </div>
        {inputs}
      </div>
      {isEditable && (
        <IconButton
          data-testid="delete-button"
          size="xs"
          color="red"
          onClick={removeSelf}
          icon="trash-alt"
          tooltip="Delete"
          variant="outlined"
        />
      )}
    </div>
  )
}

function MapValuesArray({ index, isEditable = true }: { index: number; isEditable?: boolean }) {
  const {
    control,
    formState: { errors },
  } = useFormContext<EventFormValues>()
  const { fields, append, remove } = useFieldArray({
    control,
    name: `auto_load.transformations.${index}.values_map`,
  })
  const transformationErrors = errors?.auto_load?.transformations?.[index]

  return (
    <>
      {fields.map((field, valuesMapIndex) => (
        <div className={styles.valuesMapRow} key={field.id}>
          <Controller
            name={`auto_load.transformations.${index}.values_map.${valuesMapIndex}.0`}
            control={control}
            rules={{ validate: required }}
            render={({ field }) => (
              <TextInput
                {...field}
                placeholder="Map from"
                label="Map from"
                error={
                  (transformationErrors as FieldErrorsImpl<MapValuesTranformation>)?.values_map?.[
                    valuesMapIndex
                  ]?.[0]?.message
                }
                disabled={!isEditable}
              />
            )}
          />
          <Controller
            name={`auto_load.transformations.${index}.values_map.${valuesMapIndex}.1`}
            control={control}
            rules={{ validate: required }}
            render={({ field }) => (
              <TextInput
                {...field}
                placeholder="Map to"
                label="Map to"
                error={
                  (transformationErrors as FieldErrorsImpl<MapValuesTranformation>)?.values_map?.[
                    valuesMapIndex
                  ]?.[1]?.message
                }
                disabled={!isEditable}
              />
            )}
          />
          {isEditable && (
            <IconButton
              size="xs"
              color="red"
              onClick={() => remove(valuesMapIndex)}
              icon="trash-alt"
              tooltip="Delete"
              variant="outlined"
            />
          )}
        </div>
      ))}
      {isEditable && (
        <Button size="sm" onClick={() => append([["", ""]])} className={styles.addMappingButton}>
          + Add mapping
        </Button>
      )}
    </>
  )
}
