import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { api } from "api"
import { FunnelGroup } from "resources/funnelGroup/funnelGroupTypes"
import { PermissionsDiff } from "./diffPermissionsLists"

export function useFetchFunnelGroupPermissions(id: FunnelGroup["id"]) {
  return useQuery(["funnelGroupPermission", "list", id], () => api.funnelGroupPermission.list(id))
}

export function useModifyFunnelGroupPermissions() {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ id, data }: { id: FunnelGroup["id"]; data: PermissionsDiff }) => {
      const { create, modify, delete: deleteIds } = data
      const createPromises = create.map(payload => api.funnelGroupPermission.create(id, payload))
      const modifyPromises = modify.map(([userRoleId, payload]) =>
        api.funnelGroupPermission.modify(id, userRoleId, payload),
      )
      const deletePromises = deleteIds.map(userRoleId =>
        api.funnelGroupPermission.delete(id, userRoleId),
      )
      await Promise.all([...createPromises, ...modifyPromises, ...deletePromises])
    },
    {
      onSuccess(_, { id }) {
        queryClient.invalidateQueries(["funnelGroupPermission", "list", id])
      },
    },
  )
}
