import React from "react"
import { getRoutePath } from "routes"
import UserSettings from "components/UserSettings"
import { useFetchUserById, useModifyUser, useDeleteUser } from "resources/user/userQueries"
import { Redirect, useParams } from "react-router-dom"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { User, UserModifyPayload } from "resources/user/userTypes"
import { useFetchUserRoleOptions } from "resources/userRole/userRoleQueries"

export default function UserDetailPage() {
  const { id } = useParams<{ id: string }>()
  const { data: user, isSuccess: isUserFetched } = useFetchUserById(parseInt(id, 10))
  const { data: roleOptions = [] } = useFetchUserRoleOptions()

  const { mutateAsync } = useModifyUser()
  const modifyUser = (id: User["id"], data: UserModifyPayload) => mutateAsync({ id, data })

  const deleteMutation = useDeleteUser()
  const deleteUser = (id: User["id"]) => deleteMutation.mutateAsync({ id })

  if (!isUserFetched) {
    return <LoadingIndicator />
  }

  if (!user) {
    return <Redirect to={getRoutePath("not-found")} />
  }

  return (
    <UserSettings
      user={user}
      modifyUser={modifyUser}
      editablePassword={!user.okta_enabled}
      backButton
      deleteUser={deleteUser}
      deletableUser
      roles={roleOptions}
      editableRole
      showBackButton
    />
  )
}
