import React from "react"

import Button from "components/UI/elements/Button/Button"
import IconButton from "components/UI/elements/IconButton/IconButton"
import InfoTooltip from "components/UI/elements/InfoTooltip"
import Scheduler from "pages/Segments/components/SegmentDetail/components/SegmentExports/Scheduler/Scheduler"
import SegmentationNumbers from "components/UI/components/SegmentationNumbers"
import SegmentPicker from "components/SegmentPicker/SegmentPicker"
import { SegmentOption } from "components/SegmentPicker/types"
import { useFetchChannelSegmentCounts } from "resources/channel/channelQueries"
import { ChannelSegmentCount } from "resources/channel/channelTypes"
import { useFetchSegmentsByIds } from "resources/segment/segment/segmentQueries"
import { Segment, SegmentScheduleCron } from "resources/segment/segment/segmentTypes"

import styles from "./ActivationBatch.module.scss"

type ActivationBatchProps = {
  activatedSegmentIds: Array<Segment["id"]>
  channelType: ChannelSegmentCount["channel_type"]
  repetitiveSchedules: Array<SegmentScheduleCron>
  segmentIds: Array<Segment["id"]>
  removeEnabled?: boolean
  staticSchedule?: string
  tooltip?: string | JSX.Element
  pushNotificationId?: ChannelSegmentCount["push_notification_id"]
  activate?: (segmentIds: Array<Segment["id"]>) => Promise<any> | undefined
  remove?: (segments: Array<Segment>) => void
  schedule?: (schedule: SegmentScheduleCron[] | string) => Promise<any> | undefined
  send?: (segments: Array<Segment>) => void
}

export const MISSING_SEGMENT_TOOLTIP_MESSAGE = "Select segment first."

export default function ActivationBatch({
  activatedSegmentIds,
  channelType,
  repetitiveSchedules,
  staticSchedule,
  segmentIds,
  tooltip,
  pushNotificationId,
  activate,
  remove,
  schedule,
  send,
  removeEnabled = true,
}: ActivationBatchProps) {
  const segmentsResults = useFetchSegmentsByIds(segmentIds)

  const segmentsNumbers = useFetchChannelSegmentCounts({
    channel_type: channelType,
    segment_ids: segmentIds,
    push_notification_id: pushNotificationId,
  })

  const segments = segmentsResults
    .filter(({ data }) => data !== undefined)
    .map(({ data }) => data!.segment)

  const empty = segmentIds.length === 0

  return (
    <div data-testid="sending-batch" className={styles.batch}>
      <div className={styles.firstRow}>
        <SegmentPicker
          disableActivated
          label="Select segment *"
          multiple
          activatedOptions={activatedSegmentIds}
          value={segmentIds}
          onChange={newValue => activate?.(newValue as Array<SegmentOption["value"]>)}
          className={styles.segmentPicker}
        />
        <div className={styles.segmented}>
          <div className={styles.titleWrapper}>
            <span className={styles.title}>Segmented</span>
            {tooltip && (
              <InfoTooltip placement="top" interactive>
                {tooltip}
              </InfoTooltip>
            )}
          </div>
          <div className={styles.numbersWrapper}>
            <SegmentationNumbers
              isLoading={
                segmentsNumbers?.isLoading ||
                (segmentsNumbers?.isPlaceholderData && segmentsNumbers?.isFetching)
              }
              showLabel={false}
              errorMessage={segmentsNumbers?.error?.message}
              segmentedNumber={segmentsNumbers?.data?.count}
              totalNumber={segmentsNumbers?.data?.total_count}
              plusUpTo={segmentsNumbers?.data?.plus_up_to}
              showBar
            />
          </div>
        </div>
        <IconButton
          disabled={!removeEnabled}
          color="red"
          icon="trash-alt"
          size="xs"
          tooltip={removeEnabled ? "Remove sending batch" : MISSING_SEGMENT_TOOLTIP_MESSAGE}
          variant="outlined"
          onClick={() => remove?.(segments)}
        />
      </div>
      <div className={styles.secondRow}>
        <span className={styles.text}>Set schedule</span>

        <Scheduler
          permission="edit"
          id={segmentIds.length > 0 ? segmentIds[0] : undefined}
          repetitiveSchedules={repetitiveSchedules}
          staticSchedule={staticSchedule}
          tooltip={MISSING_SEGMENT_TOOLTIP_MESSAGE}
          tooltipDisabled={!empty}
          onSaveRepetitiveSchedules={newRepetitiveSchedules => schedule?.(newRepetitiveSchedules)}
          onSaveStaticSchedule={newStaticSchedule => schedule?.(newStaticSchedule ?? [])}
        />
        <span className={styles.text}>or</span>
        <Button
          data-testid="send"
          disabled={empty}
          icon={["far", "paper-plane"]}
          tooltip={empty ? MISSING_SEGMENT_TOOLTIP_MESSAGE : undefined}
          onClick={() => send?.(segments)}
        >
          Send now
        </Button>
      </div>
    </div>
  )
}
