import React, { useEffect } from "react"
import classNames from "classnames"
import AuthFormLayout from "components/AuthFormLayout/AuthFormLayout"
import Button from "components/UI/elements/Button/Button"
import { PASSWORD_SET } from "sharedConstants"
import { showToast } from "app/toast"
import { getRoutePath } from "routes"
import { api } from "api"

import styles from "./PasswordSet.module.scss"
import { logout } from "auth/auth"
import { useHistory, useLocation } from "react-router-dom"
import { useForm } from "react-hook-form"
import TextInput from "components/UI/elements/TextInput/TextInput"
import { minLength, required } from "helpers/validators.helper"

export default function PasswordSet() {
  const { search } = useLocation()
  const history = useHistory()
  const params = new URLSearchParams(search)
  const email = params.get("email")
  const resetToken = params.get("reset_token")
  const referrer = params.get("referrer")
  let heading = "Set new password"
  let passwordFieldLabel = "New password"
  let submitButtonText = "Set password"
  let successToast = "Password changed."

  if (referrer === PASSWORD_SET.REFERRER.INVITATION) {
    heading = "You've got invited"
    passwordFieldLabel = "Create password"
    submitButtonText = "Create account"
    successToast = "Password created, feel free to login!"
  }

  useEffect(() => {
    document.body.classList.add("toastify-on-top")
    return () => {
      document.body.classList.remove("toastify-on-top")
    }
  }, [])

  async function onSubmit({ password }) {
    await api.user.setPassword({
      email,
      password_reset_token: resetToken,
      password,
    })

    logout()
    showToast(successToast)
    history.push(getRoutePath("login"))
  }

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm()

  return (
    <AuthFormLayout>
      <div className={styles.body}>
        <h2 className={styles.title}>{heading}</h2>
        {referrer === PASSWORD_SET.REFERRER.INVITATION && (
          <p className={styles.invitedPerson}>
            on <strong>{email}</strong> email
          </p>
        )}
        <form
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          className={classNames("form-row", styles.form)}
        >
          <TextInput
            {...register("password", { validate: { required, minLength: minLength(12) } })}
            label={passwordFieldLabel}
            placeholder="Password"
            type="password"
            autoFocus
            error={errors.password?.message}
          />
          <TextInput
            {...register("repeatPassword", {
              validate: (value, { password }) =>
                password && value !== password ? "Password doesn't match" : undefined,
            })}
            placeholder="Repeat password"
            type="password"
            error={errors.repeatPassword?.message}
          />
          <Button loading={isSubmitting} size="md" type="submit" className={styles.submitButton}>
            {submitButtonText}
          </Button>
        </form>
      </div>
    </AuthFormLayout>
  )
}
