import { useEffect, useState } from "react"

import ApplicationSettings from "./ApplicationSettings/ApplicationSettings"
import { ChannelWizardContext } from "./components/channelWizardContext"
import FirebaseConfigurationFile from "./FirebaseConfigurationFile/FirebaseConfigurationFile"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Page from "components/UI/Page/Page"
import StitchingCategory from "./StitchingCategory/StitchingCategory"
import {
  useFetchPushNotificationApps,
  useFetchPushNotificationsChannel,
} from "resources/channel/channelQueries"
import { getRoutePath } from "routes"

import styles from "./PushNotificationsChannel.module.scss"

export default function PushNotificationsChannel() {
  const { data: channel, isLoading: isLoadingChannel } = useFetchPushNotificationsChannel()
  const { isLoading: isLoadingApplications } = useFetchPushNotificationApps()

  const isLoading = isLoadingChannel || isLoadingApplications

  return (
    <Page title="Mobile Push channel" backRouteFallback={getRoutePath("administration.channels")}>
      {isLoading && <LoadingIndicator />}
      {!isLoading && channel && (
        <>
          {channel.is_active ? (
            <div className={styles.page}>
              <FirebaseConfigurationFile firebase_account_data={channel.firebase_account_data} />
              <ApplicationSettings />
              <StitchingCategory stitching_category_id={channel.stitching_category_id} />
            </div>
          ) : (
            <PushNotificationsChannelWizard />
          )}
        </>
      )}
    </Page>
  )
}

const PUSH_NOTIFICATIONS_CHANNEL_WIZARD_STEPS = 3

const PushNotificationsChannelWizard = () => {
  const [currentStep, setCurrentStep] = useState(0)
  const [expandedSection, setExpandedSection] = useState<string | null>(null)

  const { data: channel } = useFetchPushNotificationsChannel()
  const { data: applications = [] } = useFetchPushNotificationApps()
  const { firebase_account_data, stitching_category_id } = channel!

  useEffect(() => {
    if (!firebase_account_data) setCurrentStep(1)
    else if (applications.length === 0) setCurrentStep(2)
    else if (!stitching_category_id) setCurrentStep(3)
  }, [firebase_account_data, stitching_category_id, applications])

  const increment = () => setCurrentStep(prev => prev + 1)
  const decrement = () => setCurrentStep(prev => prev - 1)

  const isFirstStep = currentStep === 1
  const isLastStep = currentStep === PUSH_NOTIFICATIONS_CHANNEL_WIZARD_STEPS

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <FirebaseConfigurationFile isWizard firebase_account_data={firebase_account_data} />
      case 2:
        return <ApplicationSettings isWizard />
      case 3:
        return <StitchingCategory isWizard stitching_category_id={stitching_category_id} />
      default:
        return null
    }
  }

  return (
    <ChannelWizardContext.Provider
      value={{
        expandedSection,
        setExpandedSection,
        decrement,
        increment,
        isFirstStep,
        isLastStep,
        currentStep: currentStep,
        steps: PUSH_NOTIFICATIONS_CHANNEL_WIZARD_STEPS,
      }}
    >
      {renderStep()}
    </ChannelWizardContext.Provider>
  )
}
