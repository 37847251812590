import React, { useEffect, useState } from "react"
import styles from "./LookalikeSettings.module.scss"
import Page from "components/UI/Page/Page"
import Button from "components/UI/elements/Button/Button"
import Paper from "components/UI/elements/Paper"
import {
  useFetchLookalikeSettings,
  useModifyLookalikeSettings,
} from "resources/lookalike/lookalikeQueries"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { Attribute } from "resources/attribute/attributeTypes"
import { append, equals, without } from "ramda"
import IconButton from "components/UI/elements/IconButton/IconButton"
import { useFetchAttributesMap } from "resources/attribute/attributeQueries"
import SrcDstIcon from "components/UI/elements/SrcDstIcon/SrcDstIcon"
import { Prompt } from "react-router-dom"
import AttributePicker from "components/AttributePicker/AttributePicker"

export default function LookalikeSettings() {
  const { data: fetchedIds, isLoading: isLoadingLookalikeSettings } = useFetchLookalikeSettings()
  const { data: attributeMap = {}, isLoading: isLoadingAttributes } = useFetchAttributesMap()
  const isLoading = isLoadingAttributes || isLoadingLookalikeSettings
  const modifyMutation = useModifyLookalikeSettings()
  const [isPickerShown, setIsPickerShown] = useState(false)
  const [ids, setIds] = useState<Attribute["id"][]>([])

  useEffect(() => {
    setIds(ids => (fetchedIds && !equals(fetchedIds, ids) ? fetchedIds : ids))
  }, [fetchedIds])

  function addAttribute(id: Attribute["id"] | null) {
    if (!id) {
      return
    }
    setIds(append(id))
    setIsPickerShown(false)
  }

  function removeAttribute(id: Attribute["id"]) {
    setIds(without([id]))
  }

  return (
    <Page
      title="Lookalike settings"
      headerContent={
        <Button
          loading={modifyMutation.isLoading}
          onClick={_ => modifyMutation.mutate({ data: { attribute_ids: ids } })}
        >
          Save
        </Button>
      }
    >
      {isLoading && <LoadingIndicator />}

      {!isLoading && (
        <Paper noPadding>
          <Prompt when={!equals(fetchedIds, ids)} message="Changes you made will not be saved." />
          <div className={styles.section}>
            <div className={styles.description}>
              <h2>Attributes</h2>
              <p>
                Define up to 10 string attributes. These attributes will be used to generate
                lookalike settings by identifying profiles that have similar attribute values.
              </p>
            </div>
            <div className={styles.content}>
              <div className={styles.label}>Select attributes</div>
              {ids.length > 0 && (
                <div data-testid="attached-attributes" className={styles.attributes}>
                  {ids.map(attributeId => {
                    const attribute = attributeMap[attributeId]

                    if (!attribute) {
                      return null
                    }

                    return (
                      <div className={styles.attribute} key={attributeId}>
                        <div className={styles.name}>
                          <SrcDstIcon source={attribute.source} className={styles.icon} />
                          <span className={styles.sourceName}>{attribute.source.name}:</span>
                          <span className={styles.attributeName}>{attribute.name}</span>
                        </div>
                        <IconButton
                          data-testid="remove-attribute"
                          variant="transparent"
                          icon={["fas", "trash-alt"]}
                          onClick={_ => removeAttribute(attributeId)}
                        />
                      </div>
                    )
                  })}
                </div>
              )}

              {isPickerShown && (
                <div className={styles.attrPickerWrapper}>
                  <AttributePicker
                    className={styles.attributePicker}
                    onChange={addAttribute}
                    stringOnly
                    usedIds={ids}
                    autoFocus
                  />
                  <IconButton
                    variant="transparent"
                    icon={["fas", "trash-alt"]}
                    onClick={_ => setIsPickerShown(false)}
                  />
                </div>
              )}

              {!isPickerShown && ids.length < 10 && (
                <Button onClick={_ => setIsPickerShown(true)}>+ Add attribute</Button>
              )}
            </div>
          </div>
        </Paper>
      )}
    </Page>
  )
}
