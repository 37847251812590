import React, { useState } from "react"
import { Redirect, useHistory, useParams } from "react-router-dom"

import ConfirmModal from "components/UI/components/ConfirmModal"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import {
  useDeleteMobilePushNotification,
  useFetchMobilePushNotificationById,
  useModifyMobilePushNotification,
} from "resources/mobilePushNotification/mobilePushNotificationQueries"
import { getRoutePath } from "routes"
import MobilePushNotificationForm from "../components/MobilePushNotificationForm/MobilePushNotificationForm"
import { showToast } from "app/toast"
import { ModifyMobilePushNotificationPayload } from "resources/mobilePushNotification/mobilePushNotificationTypes"
import Page from "components/UI/Page/Page"
import styles from "../components/MobilePushNotificationForm/MobilePushNotificationForm.module.scss"
import ChatGPT from "components/ChatGPT/ChatGPT"
import {
  useFetchPushNotificationApps,
  useFetchPushNotificationFirebaseApps,
} from "resources/channel/channelQueries"

export default function MobilePushNotificationDetail() {
  const [modalOpen, setModalOpen] = useState(false)
  const { id } = useParams<{ id: string }>()
  const history = useHistory()

  const {
    data: notification,
    isLoading: isLoadingNotification,
    isError,
  } = useFetchMobilePushNotificationById(id)
  const modifyMutation = useModifyMobilePushNotification()
  const deleteMutation = useDeleteMobilePushNotification()

  const { isLoading: isLoadingApps } = useFetchPushNotificationApps()
  const { isLoading: isLoadingFirebaseApps } = useFetchPushNotificationFirebaseApps()

  const isLoading = isLoadingNotification || isLoadingApps || isLoadingFirebaseApps

  const modifyMobilePush = (data: ModifyMobilePushNotificationPayload) =>
    modifyMutation.mutateAsync(
      { id, data },
      {
        onSuccess: () => {
          showToast("Mobile push modified.")
        },
      },
    )

  if (notification === null) {
    return <Redirect to={getRoutePath("not-found")} />
  }

  if (isError) {
    return null
  }

  return (
    <Page
      title={"Edit mobile push"}
      contentClassName={styles.container}
      backRouteFallback={getRoutePath("channels.mobile-push")}
      headerContent={<ChatGPT />}
    >
      {isLoading && <LoadingIndicator />}
      {!isLoading && (
        <MobilePushNotificationForm
          isSaving={modifyMutation.isLoading}
          notification={notification}
          onDelete={() => setModalOpen(true)}
          onSubmit={modifyMobilePush}
        />
      )}
      <ConfirmModal
        isLoading={deleteMutation.isLoading}
        open={modalOpen}
        action="delete"
        item={notification?.name}
        title="Are you sure?"
        type="delete"
        what="mobile push"
        handleClose={() => setModalOpen(false)}
        handleConfirm={() =>
          deleteMutation.mutate(
            { id },
            {
              onSuccess: () => {
                history.push(getRoutePath("channels.mobile-push"))
              },
            },
          )
        }
      />
    </Page>
  )
}
