import {
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import { showToast } from "app/toast"
import { api } from "api"
import {
  ActivateEmailsChannelOptInPayload,
  ChannelSegmentCount,
  EmailsChannel,
  EmailsChannelResponse,
  ListPushNotificationAppsResponse,
  ListPushNotificationFirebaseAppsResponse,
  ModifyEmailsChannelFrequencyCapPayload,
  ModifyEmailsChannelOptInPayload,
  ModifyEmailsChannelProviderConfigPayload,
  ModifyEmailsChannelStitchingCategoryPayload,
  ModifyEmailsChannelUnsubscribeBlock,
  ModifyPushNotificationsChannelFrequencyCapPayload,
  ModifyPushNotificationsChannelStitchingCategoryPayload,
  PushNotificationApp,
  PushNotificationAppPayload,
  PushNotificationChannel,
  PushNotificationChannelReponse,
} from "./channelTypes"
import { useContext } from "react"
import { SocketContext } from "context/socket"
import { equals, update } from "ramda"

const CHANNEL = "channel" as const
const CHANNEL_PUSH_NOTIFICATIONS_QK: QueryKey = [CHANNEL, "pushNotifications"] as const
const CHANNEL_PUSH_NOTIFICATIONS_APPS_QK: QueryKey = [
  ...CHANNEL_PUSH_NOTIFICATIONS_QK,
  "apps",
] as const
const CHANNEL_EMAILS_QK: QueryKey = [CHANNEL, "emails"] as const
const SEGMENT_COUNTS = "segmentCounts" as const
export const CHANNEL_SEGMENT_COUNTS_QK: QueryKey = [CHANNEL, SEGMENT_COUNTS]

export function useFetchChannels() {
  return useQuery([CHANNEL, "all"], () => api.channel.list(), {
    select: ({ channels }) => channels,
  })
}

export function useFetchPushNotificationsChannel(
  config?: UseQueryOptions<
    PushNotificationChannelReponse,
    unknown,
    PushNotificationChannel,
    QueryKey
  >,
) {
  return useQuery(CHANNEL_PUSH_NOTIFICATIONS_QK, api.channel.pushNotifications.retrieve, {
    ...config,
    select: ({ push_notification_channel }) => push_notification_channel,
  })
}

export function useModifyPushNotificationsChannel() {
  const queryClient = useQueryClient()

  return useMutation(({ data }: { data: FormData }) => api.channel.pushNotifications.modify(data), {
    onSuccess: ({ push_notification_channel }) => {
      queryClient.setQueryData(CHANNEL_PUSH_NOTIFICATIONS_QK, { push_notification_channel })
      showToast("Channel modified.")
    },
  })
}

export const useModifyPushNotificationsChannelFirebaseAccountData = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ data }: { data: FormData }) => api.channel.pushNotifications.modifyFirebaseAccountData(data),
    {
      onSuccess: ({ push_notification_channel }) => {
        queryClient.setQueryData(CHANNEL_PUSH_NOTIFICATIONS_QK, { push_notification_channel })
        showToast("Firebase configuration modified.")
      },
    },
  )
}

export const useModifyPushNotificationsChannelFrequencyCap = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ data }: { data: ModifyPushNotificationsChannelFrequencyCapPayload }) =>
      api.channel.pushNotifications.modifyFrequencyCap(data),
    {
      onSuccess: ({ push_notification_channel }) => {
        queryClient.setQueryData(CHANNEL_PUSH_NOTIFICATIONS_QK, { push_notification_channel })
        showToast("Frequency cap modified.")
      },
    },
  )
}

export const useModifyPushNotificationsChannelStitchingCategory = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ data }: { data: ModifyPushNotificationsChannelStitchingCategoryPayload }) =>
      api.channel.pushNotifications.modifyStitchingCategory(data),
    {
      onSuccess: ({ push_notification_channel }) => {
        queryClient.setQueryData(CHANNEL_PUSH_NOTIFICATIONS_QK, { push_notification_channel })
        showToast("Stitching category modified.")
      },
    },
  )
}

export const useFetchPushNotificationApps = () =>
  useQuery(CHANNEL_PUSH_NOTIFICATIONS_APPS_QK, () => api.channel.pushNotifications.apps.list(), {
    select: data => data.push_notifications_apps,
  })

export const useCreatePushNotificationApp = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ data }: { data: PushNotificationAppPayload }) =>
      api.channel.pushNotifications.apps.create(data),
    {
      onSuccess: ({ push_notifications_app }) => {
        queryClient.setQueryData<ListPushNotificationAppsResponse>(
          CHANNEL_PUSH_NOTIFICATIONS_APPS_QK,
          data => {
            if (!data) return

            return {
              ...data,
              push_notifications_apps: data.push_notifications_apps.concat(push_notifications_app),
            }
          },
        )
        showToast("App created.")
      },
    },
  )
}

export const useModifyPushNotificationApp = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, data }: { id: PushNotificationApp["id"]; data: PushNotificationAppPayload }) =>
      api.channel.pushNotifications.apps.modify(id, data),
    {
      onSuccess: ({ push_notifications_app }) => {
        queryClient.setQueryData<ListPushNotificationAppsResponse>(
          CHANNEL_PUSH_NOTIFICATIONS_APPS_QK,
          data => {
            if (!data) return

            const index = data.push_notifications_apps.findIndex(
              ({ id }) => id === push_notifications_app.id,
            )

            const pushNotificationApps =
              index === -1
                ? data.push_notifications_apps.concat(push_notifications_app)
                : update(index, push_notifications_app, data.push_notifications_apps)

            return { ...data, push_notifications_apps: pushNotificationApps }
          },
        )
      },
    },
  )
}

export const useDeletePushNotificationApp = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id }: { id: PushNotificationApp["id"] }) => api.channel.pushNotifications.apps.delete(id),
    {
      onSuccess: (_, { id }) => {
        queryClient.setQueriesData<ListPushNotificationAppsResponse>(
          CHANNEL_PUSH_NOTIFICATIONS_APPS_QK,
          data => {
            if (!data) return

            const pushNotificationApps = data.push_notifications_apps.filter(item => item.id !== id)
            return { ...data, push_notifications_apps: pushNotificationApps }
          },
        )
        showToast("App deleted.")
      },
    },
  )
}

function useFetchPushNotificationFirebaseAppsQuery<T = ListPushNotificationFirebaseAppsResponse>(
  config?: UseQueryOptions<ListPushNotificationFirebaseAppsResponse, unknown, T, QueryKey>,
) {
  return useQuery(
    [...CHANNEL_PUSH_NOTIFICATIONS_QK, "firebaseApps"] as QueryKey,
    () => api.channel.pushNotifications.firebase_apps.list(),
    { ...config },
  )
}

export const useFetchPushNotificationFirebaseApps = () =>
  useFetchPushNotificationFirebaseAppsQuery({
    select: ({ firebase_apps }) => firebase_apps,
  })

export const useFetchPushNotificationAndroidOptions = () =>
  useFetchPushNotificationFirebaseAppsQuery({
    select: data =>
      data.firebase_apps.android_apps.map(({ app_id, name }) => ({
        label: name,
        value: app_id,
      })),
  })

export const useFetchPushNotificationIosOptions = () =>
  useFetchPushNotificationFirebaseAppsQuery({
    select: data =>
      data.firebase_apps.ios_apps.map(({ app_id, name }) => ({
        label: name,
        value: app_id,
      })),
  })

export function useFetchEmailChannel(
  config?: UseQueryOptions<EmailsChannelResponse, unknown, EmailsChannel, QueryKey>,
) {
  return useQuery(CHANNEL_EMAILS_QK, api.channel.emails.retrieve, {
    ...config,
    select: ({ email_channel }) => email_channel,
  })
}

export function useFetchChannelSegmentCounts<
  T = ChannelSegmentCount,
  U = { segment_ids: ChannelSegmentCount["segment_ids"]; message: string },
>(
  payload: Pick<ChannelSegmentCount, "channel_type" | "segment_ids" | "push_notification_id"> & {
    preventEventOff?: boolean
  },
  config?: UseQueryOptions<ChannelSegmentCount, U, T, QueryKey>,
) {
  const { channel_type, segment_ids, push_notification_id, preventEventOff = false } = payload
  const socket = useContext(SocketContext)

  return useQuery(
    [...CHANNEL_SEGMENT_COUNTS_QK, channel_type, segment_ids] as QueryKey,
    () =>
      new Promise<ChannelSegmentCount>((resolve, reject) => {
        socket.on("channel_segment_counts_response", (msg: ChannelSegmentCount) => {
          if (
            msg.count_type === "channel_results_count" &&
            msg.channel_type === channel_type &&
            equals(Array.from(msg.segment_ids).sort(), Array.from(segment_ids).sort())
          ) {
            if (msg.error) {
              reject({ segment_ids: msg.segment_ids, message: msg.error })
            } else {
              resolve(msg)
            }
          }
        })

        socket.emit("channel_segment_counts", {
          channel_type,
          segment_ids,
          push_notification_id,
        })
      }),
    {
      ...config,
      enabled: segment_ids.length > 0,
      placeholderData: { channel_type, segment_ids },
      refetchOnWindowFocus: false,
      retry: 0,
      onSuccess: () => {
        if (!preventEventOff) socket.off("channel_segment_counts_response")
      },
    },
  ) as UseQueryResult<T, U>
}

export function useModifyEmailsChannelFrequencyCap() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ data }: { data: ModifyEmailsChannelFrequencyCapPayload }) =>
      api.channel.emails.modifyFrequencyCap(data),
    {
      onSuccess: ({ email_channel }) => {
        queryClient.setQueryData(CHANNEL_EMAILS_QK, { email_channel })
        showToast("Channel frequency cap modified.")
      },
    },
  )
}

export function useModifyEmailsChannelProviderConfig() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ data }: { data: ModifyEmailsChannelProviderConfigPayload }) =>
      api.channel.emails.modifyProviderConfig(data),
    {
      onSuccess: ({ email_channel }) => {
        queryClient.setQueryData(CHANNEL_EMAILS_QK, { email_channel })
        showToast("Channel provider config modified.")
      },
    },
  )
}

export function useModifyEmailsChannelStitchingCategory() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ data }: { data: ModifyEmailsChannelStitchingCategoryPayload }) =>
      api.channel.emails.modifyStitchingCategory(data),
    {
      onSuccess: ({ email_channel }) => {
        queryClient.setQueryData(CHANNEL_EMAILS_QK, { email_channel })
        showToast("Channel stitching category modified.")
      },
    },
  )
}

export const useModifyEmailsChannelOptIn = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ data }: { data: ModifyEmailsChannelOptInPayload }) => api.channel.emails.modifyOptIn(data),
    {
      onSuccess: ({ email_channel }) => {
        queryClient.setQueryData(CHANNEL_EMAILS_QK, { email_channel })
        showToast("Channel opt-in modified.")
      },
    },
  )
}

export const useActivateEmailsChannelOptIn = () => {
  return useMutation(
    ({ data }: { data: ActivateEmailsChannelOptInPayload }) =>
      api.channel.emails.activateOptIn(data),
    {
      onSuccess: () => {
        showToast("Test opt-in email sent.")
      },
    },
  )
}

export const useModifyEmailsChannelUnsubscribeBlock = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ data }: { data: ModifyEmailsChannelUnsubscribeBlock }) =>
      api.channel.emails.modifyUnsubscribeBlock(data),
    {
      onSuccess: ({ email_channel }) => {
        queryClient.setQueryData(CHANNEL_EMAILS_QK, { email_channel })
        showToast("Channel unsubscribe block modified.")
      },
    },
  )
}
