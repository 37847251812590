import { useState } from "react"

import ApplicationModal from "../ApplicationModal/ApplicationModal"
import Badge from "components/UI/elements/Badge/Badge"
import Button from "components/UI/elements/Button/Button"
import ChannelPaper from "../components/ChannelPaper/ChannelPaper"
import ConfirmModal from "components/UI/components/ConfirmModal"
import IconButton from "components/UI/elements/IconButton/IconButton"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import {
  useCreatePushNotificationApp,
  useDeletePushNotificationApp,
  useFetchPushNotificationApps,
  useFetchPushNotificationFirebaseApps,
  useModifyPushNotificationApp,
} from "resources/channel/channelQueries"
import { PushNotificationApp, PushNotificationAppPayload } from "resources/channel/channelTypes"

import styles from "./ApplicationSettings.module.scss"

type ApplicationSettingsProps = {
  isWizard?: boolean
}

export default function ApplicationSettings({ isWizard = false }: ApplicationSettingsProps) {
  const [modalOpen, setModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [selectedApp, setSelectedApp] = useState<PushNotificationApp>()

  const { data: applications = [], isLoading: isLoadingApplications } =
    useFetchPushNotificationApps()
  const { data: firebaseApps, isLoading: isLoadingFirebaseApps } =
    useFetchPushNotificationFirebaseApps()

  const { mutate: createApp, isLoading: isCreating } = useCreatePushNotificationApp()
  const { mutate: modifyApp, isLoading: isModifying } = useModifyPushNotificationApp()
  const { mutate: deleteApp, isLoading: isDeleting } = useDeletePushNotificationApp()

  const closeModal = () => {
    setModalOpen(false)
    setSelectedApp(undefined)
  }

  const closeDeleteModal = () => {
    setDeleteModalOpen(false)
    setSelectedApp(undefined)
  }

  const onModalSubmit = (data: PushNotificationAppPayload) => {
    if (selectedApp)
      modifyApp(
        {
          data,
          id: selectedApp.id,
        },
        {
          onSuccess: closeModal,
        },
      )
    else
      createApp(
        { data },
        {
          onSuccess: closeModal,
        },
      )
  }

  const isLoading = isLoadingApplications || isLoadingFirebaseApps

  return (
    <>
      <ChannelPaper
        title="Application settings"
        description="Application list is based on your Firebase configuration."
        hideSubmitButton={!isWizard}
        isWizard={isWizard}
        skipSubmit={isWizard}
        submitDisabled={applications.length === 0}
      >
        <div className={styles.applications}>
          {isLoading && <LoadingIndicator />}
          {!isLoading && (
            <>
              {applications?.map(application => {
                const hasAnyAndroidApp = firebaseApps
                  ? firebaseApps.android_apps.some(({ app_id }) =>
                      application.app_ids.includes(app_id),
                    )
                  : false
                const hasAnyIosApp = firebaseApps
                  ? firebaseApps.ios_apps.some(({ app_id }) => application.app_ids.includes(app_id))
                  : false

                return (
                  <div key={application.id} className={styles.application}>
                    <div className={styles.name}>
                      <span className={styles.text}>{application.name}</span>
                      {hasAnyAndroidApp && (
                        <Badge background="white" icon={["fab", "android"]} text="Android" />
                      )}
                      {hasAnyIosApp && (
                        <Badge background="white" icon={["fab", "apple"]} text="iOS" />
                      )}
                    </div>
                    <div className={styles.buttons}>
                      <IconButton
                        color="grey"
                        icon="pencil-alt"
                        variant="transparent"
                        onClick={() => {
                          setSelectedApp(application)
                          setModalOpen(true)
                        }}
                      />
                      <IconButton
                        color="red"
                        icon="trash-alt"
                        variant="transparent"
                        onClick={() => {
                          setSelectedApp(application)
                          setDeleteModalOpen(true)
                        }}
                      />
                    </div>
                  </div>
                )
              })}
              <Button
                variant="link"
                onClick={() => setModalOpen(true)}
                className={styles.addApplication}
              >
                + Add application
              </Button>
            </>
          )}
        </div>
      </ChannelPaper>
      <ApplicationModal
        isLoading={isCreating || isModifying}
        open={modalOpen}
        application={selectedApp}
        handleClose={closeModal}
        onSubmit={onModalSubmit}
      />
      <ConfirmModal
        isLoading={isDeleting}
        open={deleteModalOpen}
        action="delete"
        item={selectedApp?.name}
        title="Are you sure?"
        type="delete"
        what="application"
        handleClose={closeDeleteModal}
        handleConfirm={() =>
          deleteApp({ id: selectedApp!.id }, { onSuccess: () => closeDeleteModal() })
        }
      />
    </>
  )
}
