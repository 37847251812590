import {
  QueryKey,
  UseInfiniteQueryOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import { showToast } from "app/toast"
import { api } from "api"
import {
  FeaturedSegmentListResponse,
  Segment,
  SegmentListResponse,
  RegularSegmentSelectionState,
  SmartSegmentListResponse,
  SegmentSelectionState,
  SegmentType,
  SegmentCustomerEntitiesMessage,
  SegmentModifyPayload,
  SegmentCreatePayload,
  LookalikeSegmentListResponse,
  SegmentListDeletedResponse,
  FeaturedSegmentListDeletedResponse,
  SmartSegmentListDeletedResponse,
  LookalikeSegmentListDeletedResponse,
} from "./segmentTypes"
import { useContext } from "react"
import { SocketContext } from "context/socket"
import { SEGMENT as _SEGMENT } from "sharedConstants"
import fetchAll from "helpers/fetchAll.helper"
import { useHasAccess } from "resources/user/currentUserQueries"
import { isNil, prop, sort } from "ramda"
import { ascend } from "utilities/comparators"
import { getRoutePath } from "routes"
import { TrashItem } from "types/trash"

export const SEGMENT = "segment" as const
const CUSTOM = "custom" as const
const FEATURED = "featured" as const
const SMART = "smart" as const
const LOOKALIKE = "lookalike" as const
const CUSTOMERS = "customers" as const
const ALL = "all" as const
const TRASH = "trash" as const
const CUSTOM_SEGMENT_QK: QueryKey = [SEGMENT, CUSTOM]
const CUSTOM_SEGMENT_TRASH_QK: QueryKey = [SEGMENT, CUSTOM, TRASH]
const FEATURED_SEGMENT_QK: QueryKey = [SEGMENT, FEATURED]
const FEATURED_SEGMENT_TRASH_QK: QueryKey = [SEGMENT, FEATURED, TRASH]
const SMART_SEGMENT_QK: QueryKey = [SEGMENT, SMART]
const SMART_SEGMENT_TRASH_QK: QueryKey = [SEGMENT, SMART, TRASH]
const LOOKALIKE_SEGMENT_QK: QueryKey = [SEGMENT, LOOKALIKE]
const LOOKALIKE_SEGMENT_TRASH_QK: QueryKey = [SEGMENT, LOOKALIKE, TRASH]

export const useFetchRegularSegments = (
  options?: Partial<
    Pick<
      RegularSegmentSelectionState,
      | "orderBy"
      | "orderDir"
      | "searchTerm"
      | "selectedTags"
      | "showMy"
      | "showSharedWithMe"
      | "showForeign"
    > & { limit: number }
  >,
) => {
  const { data, ...rest } = useInfiniteQuery<
    SegmentListResponse<0>,
    string,
    SegmentListResponse<0>
  >(
    [
      ...CUSTOM_SEGMENT_QK,
      options?.limit,
      options?.orderBy,
      options?.orderDir,
      options?.searchTerm,
      options?.selectedTags,
      options?.showMy,
      options?.showSharedWithMe,
      options?.showForeign,
    ],
    ({ pageParam }) =>
      api.segment.list(
        pageParam,
        options?.limit,
        options?.orderBy,
        options?.orderDir,
        options?.searchTerm,
        options?.selectedTags,
        0,
        options?.showMy ? 1 : 0,
        options?.showSharedWithMe ? 1 : 0,
        options?.showForeign ? 1 : 0,
      ),
    {
      getNextPageParam: last => {
        if (
          last.selection_settings.limit === null ||
          last.selection_settings.offset === null ||
          last.segments.length < last.selection_settings.limit
        )
          return

        return last.selection_settings.offset + last.selection_settings.limit
      },
    },
  )

  return { ...rest, data: data ? data.pages.flatMap(m => m.segments) : [] }
}

export const useFetchFeaturedSegments = (
  options?: Partial<
    Pick<SegmentSelectionState, "orderBy" | "orderDir" | "searchTerm" | "selectedTags"> & {
      limit: number
    }
  >,
) => {
  const { data, ...rest } = useInfiniteQuery<
    FeaturedSegmentListResponse<0>,
    string,
    FeaturedSegmentListResponse<0>
  >(
    [
      ...FEATURED_SEGMENT_QK,
      options?.limit,
      options?.orderBy,
      options?.orderDir,
      options?.searchTerm,
      options?.selectedTags,
    ],
    ({ pageParam }) =>
      api.featuredSegment.list(
        pageParam,
        options?.limit,
        options?.searchTerm,
        options?.orderBy,
        options?.orderDir,
        options?.selectedTags,
        0,
      ),
    {
      getNextPageParam: last => {
        if (
          last.selection_settings.limit === null ||
          last.selection_settings.offset === null ||
          last.featured_segments.length < last.selection_settings.limit
        )
          return

        return last.selection_settings.offset + last.selection_settings.limit
      },
    },
  )

  return { ...rest, data: data ? data.pages.flatMap(m => m.featured_segments) : [] }
}

export const useFetchSmartSegments = (searchTerm: string) => {
  const { data, ...rest } = useInfiniteQuery<SmartSegmentListResponse<0>, string>(
    SMART_SEGMENT_QK,
    ({ pageParam }) => api.smartSegment.list(pageParam, 50, "name", "ASC", 0),
    {
      getNextPageParam: last => {
        if (
          last.selection_settings.limit === null ||
          last.selection_settings.offset === null ||
          last.smart_segments.length < last.selection_settings.limit
        )
          return

        return last.selection_settings.offset + last.selection_settings.limit
      },
      // BE doesn't offer possibility to filter by name
      select: !searchTerm
        ? undefined
        : ({ pages, ...restPages }) => ({
            ...restPages,
            pages: pages.map(({ smart_segments, ...restSegment }) => ({
              ...restSegment,
              smart_segments: smart_segments.filter(({ name }) =>
                name.toLowerCase().includes(searchTerm.toLowerCase()),
              ),
            })),
          }),
    },
  )

  return { ...rest, data: data ? data.pages.flatMap(m => m.smart_segments) : [] }
}

export const useFetchLookalikeSegments = (
  options?: Partial<
    Pick<SegmentSelectionState, "orderBy" | "orderDir" | "searchTerm" | "selectedTags"> & {
      limit: number
    }
  >,
) => {
  const { data, ...rest } = useInfiniteQuery<
    LookalikeSegmentListResponse,
    string,
    LookalikeSegmentListResponse
  >(
    [
      ...LOOKALIKE_SEGMENT_QK,
      options?.limit,
      options?.orderBy,
      options?.orderDir,
      options?.searchTerm,
      options?.selectedTags,
    ],
    ({ pageParam }) =>
      api.lookalikeSegment.list(
        pageParam,
        options?.limit,
        options?.searchTerm,
        options?.orderBy,
        options?.orderDir,
        options?.selectedTags,
      ),
    {
      getNextPageParam: last => {
        if (
          last.selection_settings.limit === null ||
          last.selection_settings.offset === null ||
          last.lookalike_segments.length < last.selection_settings.limit
        )
          return

        return last.selection_settings.offset + last.selection_settings.limit
      },
    },
  )

  return { ...rest, data: data ? data.pages.flatMap(m => m.lookalike_segments) : [] }
}

export function useFetchAllSegments({ searchTerm }: { searchTerm?: string } = {}) {
  return useQuery([SEGMENT, "all"], api.segment.listAll, {
    select({ segments }) {
      return searchTerm
        ? segments.filter(({ name }) =>
            name.toLowerCase().trim().includes(searchTerm.toLowerCase().trim()),
          )
        : segments
    },
  })
}

export function useFetchAllSegmentsFull() {
  const hasAccess = useHasAccess()

  return useQuery([SEGMENT, "allFull"], async function () {
    const customPromise = fetchAll({
      fetchFn: (offset, limit) =>
        api.segment.list(
          offset,
          limit,
          "name",
          "ASC",
          undefined,
          undefined,
          0,
          1,
          1,
          hasAccess.segments.listForeign ? 1 : 0,
        ),
      key: "segments",
    })

    const featuredPromise = hasAccess.segments.featured.view
      ? fetchAll({
          fetchFn: (offset, limit) => api.featuredSegment.list(offset, limit),
          key: "featured_segments",
        })
      : Promise.resolve([])

    // const smartPromise = fetchAll({
    //   fetchFn: (offset, limit) => api.smartSegment.list(offset, limit),
    //   key: "smart_segments",
    // })

    const lookalikePromise = fetchAll({
      fetchFn: (offset, limit) => api.lookalikeSegment.list(offset, limit),
      key: "lookalike_segments",
    })

    return sort(ascend(prop("name")), [
      ...(await customPromise),
      ...(await featuredPromise),
      // ...(await smartPromise),
      ...(await lookalikePromise),
    ])
  })
}

export const useFetchAllSmartSegments = () =>
  useQuery([SEGMENT, SMART, ALL], () =>
    fetchAll({
      fetchFn: (offset, limit) => api.smartSegment.list(offset, limit, "id", "ASC", 0),
      key: "smart_segments",
    }),
  )

export function useFetchSegmentById(
  id: Segment["id"] | null,
  config?: UseQueryOptions<{ segment: Segment }, unknown, Segment, QueryKey>,
) {
  return useQuery([SEGMENT, id] as QueryKey, () => api.segment.retrieve(id!), {
    ...config,
    select: ({ segment }) => segment,
    enabled: !isNil(id),
  })
}

export function useFetchSegmentsByIds(
  ids: Segment["id"][],
  queryConfig?: UseQueryOptions<{ segment: Segment }, unknown, { segment: Segment }, QueryKey>,
) {
  return useQueries({
    queries: ids.map(id => ({
      queryKey: [SEGMENT, id] as QueryKey,
      queryFn: () => api.segment.retrieve(id),
      ...queryConfig,
    })),
  })
}

export function useDeleteSegment(type: SegmentType = "custom") {
  const queryClient = useQueryClient()

  const toast =
    type === "featured"
      ? "Featured segment deleted."
      : type === "smart"
      ? "Smart segment deleted."
      : type === "lookalike"
      ? "Lookalike segment deleted."
      : "Segment deleted."

  return useMutation((id: Segment["id"]) => api.segment.delete(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(CUSTOM_SEGMENT_QK)
      queryClient.invalidateQueries(FEATURED_SEGMENT_QK)
      queryClient.invalidateQueries(SMART_SEGMENT_QK)
      queryClient.invalidateQueries(LOOKALIKE_SEGMENT_QK)
      showToast(toast)
    },
  })
}

export const useCopySegmentToCustomSegments = () =>
  useMutation(({ id }: { id: Segment["id"] }) => api.segment.clone(id, { featured: 0 }), {
    onSuccess: () => showToast("Segment copied."),
  })

export const useCopySegmentToFeaturedSegments = () =>
  useMutation(({ id }: { id: Segment["id"] }) => api.segment.clone(id, { featured: 1 }), {
    onSuccess: () => showToast("Segment copied."),
  })

export const useFetchSegmentCustomers = (
  id: Segment["id"],
  config?: UseInfiniteQueryOptions<SegmentCustomerEntitiesMessage, unknown>,
) => {
  const socket = useContext(SocketContext)

  const { data, ...rest } = useInfiniteQuery(
    [SEGMENT, id, CUSTOMERS] as QueryKey,
    ({ pageParam }) =>
      new Promise<SegmentCustomerEntitiesMessage>((resolve, reject) => {
        socket.on("segment_customer_entities_response", (msg: SegmentCustomerEntitiesMessage) => {
          if (msg.error) reject(msg)
          else resolve(msg)
        })

        socket.emit("segment_customer_entities", {
          segment_id: id,
          offset: pageParam,
          limit: _SEGMENT.CUSTOMER.ITEMS_PER_PAGE,
        })
      }),
    {
      ...config,
      refetchOnWindowFocus: false,
      getNextPageParam: last => {
        if (
          last.selection_settings.limit === null ||
          last.selection_settings.offset === null ||
          last.customer_entities === undefined ||
          last.customer_entities?.length < last.selection_settings.limit
        )
          return

        return last.selection_settings.offset + last.selection_settings.limit
      },
      onSuccess: () => {
        socket.off("segment_customer_entities_response")
      },
    },
  )

  return { ...rest, data: data ? data.pages.flatMap(m => m.customer_entities) : [] }
}

export const useModifySegment = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, data }: { id: Segment["id"]; data: SegmentModifyPayload }) =>
      api.segment.modify(id, data),
    {
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries(CUSTOM_SEGMENT_QK)
        queryClient.invalidateQueries(FEATURED_SEGMENT_QK)
        queryClient.invalidateQueries(SMART_SEGMENT_QK)
        queryClient.invalidateQueries(LOOKALIKE_SEGMENT_QK)
        queryClient.invalidateQueries([SEGMENT, id])
      },
    },
  )
}

export const useCreateSegment = (type: SegmentType) => {
  const queryClient = useQueryClient()

  return useMutation((data: SegmentCreatePayload) => api.segment.create(data), {
    onSuccess: () => {
      switch (type) {
        case "custom":
          queryClient.invalidateQueries(CUSTOM_SEGMENT_QK)
          showToast("Custom segment created.")
          break
        case "featured":
          queryClient.invalidateQueries(FEATURED_SEGMENT_QK)
          showToast("Featured segment created.")
          break
        case "smart":
          queryClient.invalidateQueries(SMART_SEGMENT_QK)
          showToast("Smart segment created.")
          break
        case "lookalike":
          queryClient.invalidateQueries(LOOKALIKE_SEGMENT_QK)
          showToast("Lookalike segment created.")
          break
        default:
          queryClient.invalidateQueries(CUSTOM_SEGMENT_QK)
          queryClient.invalidateQueries(FEATURED_SEGMENT_QK)
          queryClient.invalidateQueries(SMART_SEGMENT_QK)
          queryClient.invalidateQueries(LOOKALIKE_SEGMENT_QK)
          showToast("Segment created.")
      }
    },
  })
}

export const useFetchCustomSegmentTrashItems = (searchTerm: string) => {
  const { data, ...rest } = useInfiniteQuery<
    SegmentListDeletedResponse,
    string,
    Omit<SegmentListDeletedResponse, "trashed_segments"> & { trashed_segments: Array<TrashItem> },
    QueryKey
  >(
    [...CUSTOM_SEGMENT_TRASH_QK, searchTerm],
    ({ pageParam }) =>
      api.segment.listDeleted({ offset: pageParam, limit: 20, searched_text: searchTerm }),
    {
      getNextPageParam: last => {
        if (
          last.selection_settings.limit === null ||
          last.selection_settings.offset === null ||
          last.trashed_segments.length < last.selection_settings.limit
        )
          return

        return last.selection_settings.offset + last.selection_settings.limit
      },
      select: data => {
        return {
          ...data,
          pages: data.pages.map(p => ({
            ...p,
            trashed_segments: p.trashed_segments.map(({ id, name, created, user_id }) => {
              const trashItem: TrashItem = {
                id,
                name,
                deleted_at: created,
                deleted_by: user_id,
                type: "custom_segments",
              }

              return trashItem
            }),
          })),
        }
      },
    },
  )

  return { ...rest, data: data ? data.pages.flatMap(m => m.trashed_segments) : [] }
}

export const useFetchFeaturedSegmentTrashItems = (searchTerm: string) => {
  const { data, ...rest } = useInfiniteQuery<
    FeaturedSegmentListDeletedResponse,
    string,
    Omit<FeaturedSegmentListDeletedResponse, "trashed_featured_segments"> & {
      trashed_featured_segments: Array<TrashItem>
    },
    QueryKey
  >(
    [...FEATURED_SEGMENT_TRASH_QK, searchTerm],
    ({ pageParam }) =>
      api.featuredSegment.listDeleted({ offset: pageParam, limit: 20, searched_text: searchTerm }),
    {
      getNextPageParam: last => {
        if (
          last.selection_settings.limit === null ||
          last.selection_settings.offset === null ||
          last.trashed_featured_segments.length < last.selection_settings.limit
        )
          return

        return last.selection_settings.offset + last.selection_settings.limit
      },
      select: data => {
        return {
          ...data,
          pages: data.pages.map(p => ({
            ...p,
            trashed_featured_segments: p.trashed_featured_segments.map(
              ({ id, name, created, user_id }) => {
                const trashItem: TrashItem = {
                  id,
                  name,
                  deleted_at: created,
                  deleted_by: user_id,
                  type: "featured_segments",
                }

                return trashItem
              },
            ),
          })),
        }
      },
    },
  )

  return { ...rest, data: data ? data.pages.flatMap(m => m.trashed_featured_segments) : [] }
}

export const useFetchSmartSegmentTrashItems = (searchTerm: string) => {
  const { data, ...rest } = useInfiniteQuery<
    SmartSegmentListDeletedResponse,
    string,
    Omit<SmartSegmentListDeletedResponse, "trashed_smart_segments"> & {
      trashed_smart_segments: Array<TrashItem>
    },
    QueryKey
  >(
    [...SMART_SEGMENT_TRASH_QK, searchTerm],
    ({ pageParam }) =>
      api.smartSegment.listDeleted({ offset: pageParam, limit: 20, searched_text: searchTerm }),
    {
      getNextPageParam: last => {
        if (
          last.selection_settings.limit === null ||
          last.selection_settings.offset === null ||
          last.trashed_smart_segments.length < last.selection_settings.limit
        )
          return

        return last.selection_settings.offset + last.selection_settings.limit
      },
      select: data => {
        return {
          ...data,
          pages: data.pages.map(p => ({
            ...p,
            trashed_smart_segments: p.trashed_smart_segments.map(
              ({ id, name, created, user_id }) => {
                const trashItem: TrashItem = {
                  id,
                  name,
                  deleted_at: created,
                  deleted_by: user_id,
                  type: "smart_segments",
                }

                return trashItem
              },
            ),
          })),
        }
      },
    },
  )

  return { ...rest, data: data ? data.pages.flatMap(m => m.trashed_smart_segments) : [] }
}

export const useFetchLookalikeSegmentTrashItems = (searchTerm: string) => {
  const { data, ...rest } = useInfiniteQuery<
    LookalikeSegmentListDeletedResponse,
    string,
    Omit<LookalikeSegmentListDeletedResponse, "trashed_lookalike_segments"> & {
      trashed_lookalike_segments: Array<TrashItem>
    },
    QueryKey
  >(
    [...LOOKALIKE_SEGMENT_TRASH_QK, searchTerm],
    ({ pageParam }) =>
      api.lookalikeSegment.listDeleted({ offset: pageParam, limit: 20, searched_text: searchTerm }),
    {
      getNextPageParam: last => {
        if (
          last.selection_settings.limit === null ||
          last.selection_settings.offset === null ||
          last.trashed_lookalike_segments.length < last.selection_settings.limit
        )
          return

        return last.selection_settings.offset + last.selection_settings.limit
      },
      select: data => {
        return {
          ...data,
          pages: data.pages.map(p => ({
            ...p,
            trashed_lookalike_segments: p.trashed_lookalike_segments.map(
              ({ id, name, created, user_id }) => {
                const trashItem: TrashItem = {
                  id,
                  name,
                  deleted_at: created,
                  deleted_by: user_id,
                  type: "lookalike_segments",
                }

                return trashItem
              },
            ),
          })),
        }
      },
    },
  )

  return { ...rest, data: data ? data.pages.flatMap(m => m.trashed_lookalike_segments) : [] }
}

export const useRestoreCustomSegment = () => {
  const queryClient = useQueryClient()

  return useMutation(({ id }: { id: Segment["id"] }) => api.segment.restoreDeleted(id), {
    onSuccess: ({ segment }) => {
      queryClient.invalidateQueries(CUSTOM_SEGMENT_TRASH_QK)
      queryClient.invalidateQueries(CUSTOM_SEGMENT_QK)

      showToast(
        "Custom segment restored.",
        undefined,
        getRoutePath("segments.custom.detail", { id: segment.id }),
      )
    },
  })
}

export const useRestoreFeaturedSegment = () => {
  const queryClient = useQueryClient()

  return useMutation(({ id }: { id: Segment["id"] }) => api.featuredSegment.restoreDeleted(id), {
    onSuccess: ({ segment }) => {
      queryClient.invalidateQueries(FEATURED_SEGMENT_TRASH_QK)
      queryClient.invalidateQueries(FEATURED_SEGMENT_QK)

      showToast(
        "Featured segment restored.",
        undefined,
        getRoutePath("segments.featured.detail", { id: segment.id }),
      )
    },
  })
}

export const useRestoreSmartSegment = () => {
  const queryClient = useQueryClient()

  return useMutation(({ id }: { id: Segment["id"] }) => api.smartSegment.restoreDeleted(id), {
    onSuccess: ({ segment }) => {
      queryClient.invalidateQueries(SMART_SEGMENT_TRASH_QK)
      queryClient.invalidateQueries(SMART_SEGMENT_QK)

      showToast(
        "Smart segment restored.",
        undefined,
        getRoutePath("segments.smart.detail", { id: segment.id }),
      )
    },
  })
}

export const useRestoreLookalikeSegment = () => {
  const queryClient = useQueryClient()

  return useMutation(({ id }: { id: Segment["id"] }) => api.lookalikeSegment.restoreDeleted(id), {
    onSuccess: ({ segment }) => {
      queryClient.invalidateQueries(LOOKALIKE_SEGMENT_TRASH_QK)
      queryClient.invalidateQueries(LOOKALIKE_SEGMENT_QK)

      showToast(
        "Lookalike segment restored.",
        undefined,
        getRoutePath("segments.lookalike.detail", { id: segment.id }),
      )
    },
  })
}
