import create from "zustand"

import { ChannelSegmentCount, ChannelSegmentCountError, ChannelType } from "./channelTypes"
import { Segment } from "resources/segment/segment/segmentTypes"
import { socket } from "context/socket"
import { useEffect } from "react"
import { equals } from "ramda"

export const useSegmentChannelCountsStore = create<{
  emails?: ChannelSegmentCount
  emailsError?: ChannelSegmentCountError
  pushNotifications?: ChannelSegmentCount
  pushNotificationsError?: ChannelSegmentCountError
  reset: () => void
  setEmails: (emails: ChannelSegmentCount) => void
  setEmailsError: (error: ChannelSegmentCountError) => void
  setPushNotifications: (pushNotifications: ChannelSegmentCount) => void
  setPushNotificationsError: (error: ChannelSegmentCountError) => void
}>(set => ({
  reset: () =>
    set(() => ({
      emails: undefined,
      emailsError: undefined,
      pushNotifications: undefined,
      pushNotificationsError: undefined,
    })),
  setEmails: emails =>
    set(() => ({
      emails,
    })),
  setEmailsError: error =>
    set(() => ({
      emailsError: error,
    })),
  setPushNotifications: pushNotifications =>
    set(() => ({
      pushNotifications,
    })),
  setPushNotificationsError: error =>
    set(() => ({
      pushNotificationsError: error,
    })),
}))

export const emitSegmentChannelCounts = ({
  segmentIds,
  channelType,
  onAck,
}: {
  segmentIds: Array<Segment["id"]>
  channelType: ChannelType
  onAck?: () => void
}) => {
  if (segmentIds.length > 0)
    socket.emit(
      "channel_segment_counts",
      {
        channel_type: channelType,
        segment_ids: segmentIds,
      },
      () => {
        onAck?.()
      },
    )
}

export const useListenSegmentChannelCounts = (segmentIds: Array<Segment["id"]>) => {
  const { setEmails, setEmailsError, setPushNotifications, setPushNotificationsError } =
    useSegmentChannelCountsStore()

  useEffect(() => {
    socket.on("channel_segment_counts_response", (msg: ChannelSegmentCount) => {
      if (
        msg.count_type === "channel_results_count" &&
        equals(Array.from(msg.segment_ids).sort(), Array.from(segmentIds).sort())
      ) {
        if (msg.error) {
          if (msg.channel_type === "emails")
            setEmailsError({ segment_ids: msg.segment_ids, error: msg.error })
          else setPushNotificationsError({ segment_ids: msg.segment_ids, error: msg.error })
        } else {
          if (msg.channel_type === "emails") setEmails(msg)
          else setPushNotifications(msg)
        }
      }
    })

    return () => {
      socket.off("channel_segment_counts_response")
    }
  }, [segmentIds, setEmails, setEmailsError, setPushNotifications, setPushNotificationsError])
}
