import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import ReCAPTCHA from "react-google-recaptcha"
import classNames from "classnames"
import AuthFormLayout from "components/AuthFormLayout/AuthFormLayout"
import Button from "components/UI/elements/Button/Button"
import { required, email } from "helpers/validators.helper"
import { NOTIFICATION } from "sharedConstants"
import { showToast } from "app/toast"
import { getRoutePath } from "routes"
import { api } from "api"
import styles from "./PasswordReset.module.scss"
import { useForm } from "react-hook-form"
import TextInput from "components/UI/elements/TextInput/TextInput"

let recaptchaSiteKey = null
if (process.env.NODE_ENV === "production") {
  recaptchaSiteKey = "[[RECAPTCHA_SITE_KEY]]"
} else {
  recaptchaSiteKey =
    process.env.REACT_APP_RECAPTCHA_SITE_KEY === '""'
      ? ""
      : process.env.REACT_APP_RECAPTCHA_SITE_KEY
}

export default function PasswordReset() {
  const [recaptchaToken, setRecaptchaToken] = useState("")
  const history = useHistory()

  useEffect(() => {
    document.body.classList.add("toastify-on-top")
    return () => {
      document.body.classList.remove("toastify-on-top")
    }
  }, [])

  async function onSubmit({ email }) {
    await api.user.resetPassword({ email, recaptcha_token: recaptchaToken })
    showToast(NOTIFICATION.PASSWORD_RESET)
    history.push(getRoutePath("login"))
  }

  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm()

  return (
    <AuthFormLayout>
      <form className={styles.form} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <h2 className={styles.formTitle}>Password reset</h2>
        <div className="form-row">
          <TextInput
            {...register("email", { validate: { required, email } })}
            label="Email"
            placeholder="Email"
            autoFocus
            error={errors.email?.message}
          />
        </div>
        {recaptchaSiteKey && (
          <div className={classNames("form-row", styles.recaptcha)}>
            <ReCAPTCHA sitekey={recaptchaSiteKey} onChange={setRecaptchaToken} />
          </div>
        )}
        <Button
          fullWidth
          disabled={recaptchaSiteKey && !recaptchaToken}
          loading={isSubmitting}
          size="big"
          type="submit"
          data-testid="reset-password-button"
        >
          Reset Password
        </Button>
        <Link className={styles.underformLink} to={getRoutePath("login")}>
          Back to login
        </Link>
      </form>
    </AuthFormLayout>
  )
}
