import React, { useCallback, useEffect, useState } from "react"
import styles from "./SegmentUsersModal.module.scss"
import Modal from "components/UI/elements/Modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { SegmentUser } from "types/segmentUsers"
import { User, UserFull } from "resources/user/userTypes"
import { Segment, SegmentType } from "resources/segment/segment/segmentTypes"
import Button from "components/UI/elements/Button/Button"
import classNames from "classnames"
import Avatar from "components/UI/elements/Avatar"
import IconButton from "components/UI/elements/IconButton/IconButton"
import { PERMISSION, SYSTEM_USER_ID } from "sharedConstants"
import ToggleSwitch from "components/UI/elements/ToggleSwitch"
import { Permission } from "types/util"
import { adjust, append, equals, remove, without } from "ramda"
import SelectField from "components/UI/elements/SelectField"
import { showToast } from "app/toast"
import Tippy from "@tippyjs/react"
import {
  useCreateSegmentPermissionsBySegmentId,
  useDeleteSegmentPermissionsBySegmentId,
  useModifySegmentPermissionsBySegmentId,
} from "resources/segmentPermission/segmentPermissionQueries"
import {
  SegmentPermission,
  UserPermission,
} from "resources/segmentPermission/segmentPermissionTypes"

type SegmentUsersModalProps = {
  isOpen: boolean
  onClose: () => void
  segmentId: Segment["id"]
  authorId: Segment["author_id"]
  acl: SegmentPermission[]
  allUsers: Record<User["id"], UserFull>
  isEditable: boolean
  segmentType: SegmentType
}

const getOppositePermission = (permission: Permission) =>
  permission === PERMISSION.READ ? PERMISSION.WRITE : PERMISSION.READ

const canEditAllSegments = (user: UserFull, segmentType: SegmentType) =>
  segmentType === "featured"
    ? user.role.features.includes("featured_segments/edit")
    : user.role.features.includes("foreign_segments/edit")

const canViewAllSegments = (user: UserFull, segmentType: SegmentType) =>
  segmentType === "featured"
    ? user.role.features.includes("featured_segments/view")
    : user.role.features.includes("foreign_segments/view")

export default function SegmentUsersModal({
  isOpen,
  onClose,
  segmentId,
  authorId,
  acl,
  allUsers,
  isEditable,
  segmentType,
}: SegmentUsersModalProps) {
  const [isAddingUsers, setIsAddingUsers] = useState(false)

  // creating manually ACL object as user may be deleted and then he's not included in acl[]
  const authorAccess: SegmentUser = {
    user_id: authorId,
    segment_id: segmentId,
    permission: PERMISSION.WRITE,
    created: "",
  }
  const sortedAcl = [authorAccess, ...acl.filter(({ user_id }) => user_id !== authorId)]

  const [pendingRemoves, setPendingRemoves] = useState<User["id"][]>([])
  const [pendingChanges, setPendingChanges] = useState<UserPermission[]>([])
  const [pendingInvites, setPendingInvites] = useState<Partial<UserPermission>[]>([{}])

  useEffect(() => {
    setPendingRemoves([])
    setPendingChanges([])
    setPendingInvites([{}])
    setIsAddingUsers(false)
  }, [isOpen])

  // existing users

  const toggleUserPermission = useCallback(
    toggledId => {
      setPendingChanges(changes => {
        const existingChange = changes.find(({ user_id }) => user_id === toggledId)
        const existingPermission = acl.find(({ user_id }) => user_id === toggledId)!

        return existingChange
          ? without([existingChange], changes)
          : changes.concat({
              user_id: toggledId,
              permission: getOppositePermission(existingPermission.permission),
            })
      })
    },
    [acl],
  )

  const removeUser = useCallback(userId => {
    setPendingChanges(without([userId]))
    setPendingRemoves(append(userId))
  }, [])

  // invites

  const remainingUsersToInvite = Object.values(allUsers).filter(
    user =>
      !user.disabled &&
      !user.deleted &&
      user.id !== SYSTEM_USER_ID &&
      !acl.find(({ user_id }) => user_id === user.id) && // user is not already in segment ACL
      !pendingInvites.find(({ user_id }) => user_id === user.id), // user is not already selected among invites
  )

  const getInviteeDropdownOptions = useCallback(
    (index: number) => {
      const selectedUserId = pendingInvites[index].user_id

      // if a user is selected in the dropdown they must be included in the dropdown options
      return (
        selectedUserId
          ? remainingUsersToInvite.concat(allUsers[selectedUserId])
          : remainingUsersToInvite
      )
        .map(({ id, name, email }) => ({ value: id, name, email, label: name }))
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
    },
    [allUsers, pendingInvites, remainingUsersToInvite],
  )

  const areThereUnusedOptions = getInviteeDropdownOptions(0).length > 1
  const areAllRowsFilledOut = pendingInvites.every(({ user_id }) => user_id)

  const toggleInviteePermission = useCallback(index => {
    setPendingInvites(invites =>
      adjust(
        index,
        ({ user_id, permission }) => ({
          user_id,
          permission: getOppositePermission(permission!),
        }),
        invites,
      ),
    )
  }, [])

  const removeInvitee = useCallback(
    index => {
      setPendingInvites(pendingInvites.length > 1 ? remove(index, 1) : [{}])
    },
    [pendingInvites.length],
  )

  const addRow = useCallback(() => {
    setPendingInvites(append({}))
  }, [])

  const setInvitee = useCallback(
    (userId, index) => {
      setPendingInvites(invites =>
        adjust(
          index,
          ({ permission }) => ({
            user_id: userId,
            permission: canEditAllSegments(allUsers[userId], segmentType)
              ? PERMISSION.WRITE
              : permission ?? PERMISSION.READ,
          }),
          invites,
        ),
      )
    },
    [allUsers, segmentType],
  )

  const inviteAllUsers = useCallback(() => {
    setPendingInvites(invites => {
      if (remainingUsersToInvite.length === 0) {
        return invites
      }

      const toAdd = remainingUsersToInvite.map(user => ({
        user_id: user.id,
        permission: canEditAllSegments(user, segmentType) ? PERMISSION.WRITE : PERMISSION.READ,
      }))

      return without([{}], invites).concat(toAdd)
    })
    setIsAddingUsers(true)
  }, [remainingUsersToInvite, segmentType])

  // general

  const createSegmentPermissionsMutation = useCreateSegmentPermissionsBySegmentId()
  const modifySegmentPermissionsMutation = useModifySegmentPermissionsBySegmentId()
  const deleteSegmentPermissionsMutation = useDeleteSegmentPermissionsBySegmentId()

  const isSaving =
    createSegmentPermissionsMutation.isLoading ||
    modifySegmentPermissionsMutation.isLoading ||
    deleteSegmentPermissionsMutation.isLoading

  const saveChanges = useCallback(async () => {
    const invitePromise = createSegmentPermissionsMutation.mutateAsync({
      segmentId,
      segmentPermissions: without([{}], pendingInvites) as UserPermission[],
    })
    const changePromise = modifySegmentPermissionsMutation.mutateAsync({
      segmentId,
      segmentPermissions: pendingChanges,
    })
    const removePromise = deleteSegmentPermissionsMutation.mutateAsync({
      segmentId,
      userIds: pendingRemoves,
    })

    await changePromise
    await removePromise
    await invitePromise
    showToast("Segment access list updated.")
    onClose()
  }, [
    createSegmentPermissionsMutation,
    modifySegmentPermissionsMutation,
    deleteSegmentPermissionsMutation,
    onClose,
    pendingChanges,
    pendingInvites,
    pendingRemoves,
    segmentId,
  ])

  const goBack = () => {
    setPendingInvites([{}])
    setIsAddingUsers(false)
  }

  return (
    <Modal open={isOpen} handleClose={onClose} className={styles.container} hideHeader>
      <div className={styles.header}>
        <div className={styles.title}>
          {isAddingUsers ? "invite users to segment" : "segment users"}

          <Tippy
            content="Collaborate with other users on the segment. Invited users will receive notification for this segment."
            placement="top"
          >
            <div className={styles.infoIcon}>
              <FontAwesomeIcon icon={["fas", "info-circle"]} />
            </div>
          </Tippy>
        </div>
        <div className={styles.stretch} />
        <div className={styles.closeButton} onClick={onClose}>
          <FontAwesomeIcon icon={["fas", "times"]} />
        </div>
      </div>
      <div className={styles.body}>
        {!isAddingUsers && (
          <>
            {sortedAcl
              .filter(({ user_id }) => !pendingRemoves.includes(user_id))
              .map(({ user_id, permission }) => {
                const user = allUsers[user_id]
                const isAuthor = user_id === authorId

                return (
                  <div key={user_id} className={styles.userRow}>
                    <Avatar name={user.name} email={user.email} />
                    <div className={styles.userName}>{user.name}</div>
                    <div className={styles.stretch} />
                    {isAuthor ? (
                      <Tippy
                        content="Author's rights cannot be edited. Author has full access to the segment."
                        placement="top"
                      >
                        <div className={styles.authorTogglePlaceholder}>author</div>
                      </Tippy>
                    ) : (
                      <Tippy
                        content="This user has the permission to edit all segments."
                        disabled={!canEditAllSegments(user, segmentType)}
                        placement="top"
                      >
                        <div>
                          <ToggleSwitch
                            name={`permission-${user_id}`}
                            checked={
                              pendingChanges.find(({ user_id: userId }) => userId === user_id)
                                ? getOppositePermission(permission)
                                : permission
                            }
                            leftLabel="View"
                            leftValue={PERMISSION.READ}
                            rightLabel="Edit"
                            rightValue={PERMISSION.WRITE}
                            width="112px"
                            handleToggle={() => toggleUserPermission(user_id)}
                            disabled={canEditAllSegments(user, segmentType) || !isEditable}
                          />
                        </div>
                      </Tippy>
                    )}
                    {isEditable && (
                      <Tippy
                        content={
                          canEditAllSegments(user, segmentType)
                            ? "User will still have permission to edit the segment, but will not get notifications about segment changes."
                            : canViewAllSegments(user, segmentType)
                            ? "User will still have permission to view the segment, but will not get notifications about segment changes."
                            : "User will lose access to the segment."
                        }
                        placement="top"
                      >
                        <div className={styles.removeButtonWrapper}>
                          <IconButton
                            color="red"
                            size="xs"
                            variant="outlined"
                            onClick={() => removeUser(user_id)}
                            icon="trash-alt"
                            className={classNames(styles.removeButton, {
                              [styles.hidden]: isAuthor,
                            })}
                          />
                        </div>
                      </Tippy>
                    )}
                  </div>
                )
              })}
            {isEditable && (
              <div className={styles.inviteButtonsWrapper}>
                <button className={styles.addButton} onClick={() => setIsAddingUsers(true)}>
                  <FontAwesomeIcon icon={["far", "user-plus"]} className={styles.icon} />
                  invite users
                </button>
                <button
                  className={classNames(styles.addButton, styles.secondary)}
                  onClick={inviteAllUsers}
                >
                  <FontAwesomeIcon icon={["far", "users"]} className={styles.icon} />
                  invite all users
                </button>
              </div>
            )}
          </>
        )}
        {isAddingUsers && (
          <>
            {remainingUsersToInvite.length === 0 && equals(pendingInvites, [{}]) ? (
              <div className={styles.noUsersMessage}>
                All existing users are already added to the segment.
              </div>
            ) : (
              pendingInvites.map(({ user_id: inviteeId, permission }, index) => {
                const user = inviteeId && allUsers[inviteeId]
                const options = getInviteeDropdownOptions(index)

                return (
                  <div key={inviteeId ?? "empty"} className={styles.userRow}>
                    <SelectField
                      input={{
                        value: options.find(({ value }) => value === inviteeId),
                        onChange: ({ value }: { value: User["id"] }) => setInvitee(value, index),
                      }}
                      options={options}
                      isSearchable
                      className={styles.inviteeSelect}
                    />
                    <div className={styles.stretch} />
                    {user && permission && (
                      <Tippy
                        content="This user has the permission to edit all segments."
                        disabled={!canEditAllSegments(user, segmentType)}
                        placement="top"
                      >
                        <div>
                          <ToggleSwitch
                            name={`permission-invite-${user.id}`}
                            checked={permission}
                            leftLabel="View"
                            leftValue={PERMISSION.READ}
                            rightLabel="Edit"
                            rightValue={PERMISSION.WRITE}
                            width="112px"
                            handleToggle={() => toggleInviteePermission(index)}
                            disabled={canEditAllSegments(user, segmentType)}
                          />
                        </div>
                      </Tippy>
                    )}
                    <Tippy content="Remove user from invites." placement="top">
                      <div className={styles.removeButtonWrapper}>
                        <IconButton
                          color="red"
                          size="xs"
                          variant="outlined"
                          onClick={() => removeInvitee(index)}
                          icon="trash-alt"
                          className={classNames(styles.removeButton, {
                            [styles.hidden]: equals(pendingInvites, [{}]),
                          })}
                        />
                      </div>
                    </Tippy>
                  </div>
                )
              })
            )}
            {areThereUnusedOptions && areAllRowsFilledOut && (
              <div className={styles.inviteButtonsWrapper}>
                <button className={styles.addButton} onClick={addRow}>
                  <FontAwesomeIcon icon={["far", "plus-circle"]} className={styles.icon} />
                  add another
                </button>
              </div>
            )}
          </>
        )}
      </div>
      {isEditable && (
        <div className={styles.footer}>
          {!isAddingUsers && (
            <Button color="grey" size="md" variant="outlined" onClick={onClose}>
              cancel
            </Button>
          )}
          {isAddingUsers && (
            <Button color="grey" size="md" variant="outlined" onClick={goBack}>
              back
            </Button>
          )}
          <div className={styles.stretch} />
          <Button
            className={styles.saveButton}
            onClick={saveChanges}
            disabled={isSaving}
            loading={isSaving}
            size="md"
          >
            save changes
          </Button>
        </div>
      )}
    </Modal>
  )
}
