import React from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import { getRoutePath } from "routes"
import UserSettings from "pages/UserSettings/UserSettings"
import NotAuthorizedPage from "pages/NotAuthorized/NotAuthorized"
import NotFoundPage from "pages/NotFound/NotFound"
import HomePage from "pages/HomePage/HomePage"
import Segments from "pages/Segments/SegmentsRoutes"
import AuthorizedRoute from "components/AuthorizedRoute/AuthorizedRoute"
import Data from "pages/Data/DataRoutes"
import Analytics from "pages/Analytics/AnalyticsRoutes"
import Administration from "pages/Administration/AdministrationRoutes"
import Profiles from "pages/Profiles/ProfilesRoutes"
import Channels from "pages/Channels/ChannelsRoutes"
import { useHasAccess } from "resources/user/currentUserQueries"

export default function AuthenticatedRoutesSwitch() {
  const hasAccess = useHasAccess()

  return (
    <Switch>
      <Route path={getRoutePath("not-authorized")} component={NotAuthorizedPage} />
      <Route path={getRoutePath("not-found")} component={NotFoundPage} />

      <Route path={getRoutePath("home")} exact component={HomePage} />
      <Route path={getRoutePath("profiles")} component={Profiles} />
      <Route path={getRoutePath("segments")} component={Segments} />
      <AuthorizedRoute
        path={getRoutePath("analytics")}
        component={Analytics}
        hasAccess={hasAccess.data.dashboard || hasAccess.data.insights || hasAccess.reports}
      />
      <Route path={getRoutePath("channels")} component={Channels} />
      <AuthorizedRoute
        hasAccess={hasAccess.data.general}
        path={getRoutePath("data")}
        component={Data}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.administration.general || hasAccess.setup.general}
        path={getRoutePath("administration")}
        component={Administration}
      />
      <Route path={getRoutePath("user-settings")} component={UserSettings} />

      <Route
        exact
        path={getRoutePath("DEPRECATED.customers")}
        render={() => <Redirect to={getRoutePath("profiles")} />}
      />
      <Route
        path={getRoutePath("DEPRECATED.customers.detail")}
        render={({
          match: {
            params: { id },
          },
        }) => <Redirect to={getRoutePath("profiles.detail", { id })} />}
      />

      {/* Deprecated routes for segments moved to SegmentsRoutes because the segments routes need to match in a specific order */}

      <Route
        path={getRoutePath("DEPRECATED.reporting")}
        render={() => <Redirect to={getRoutePath("analytics.reporting")} />}
      />

      <Route
        exact
        path={getRoutePath("DEPRECATED.personalization")}
        render={() => <Redirect to={getRoutePath("channels")} />}
      />
      <Route
        exact
        path={getRoutePath("DEPRECATED.personalization.popup-web-banners")}
        render={() => <Redirect to={getRoutePath("channels.popup-banners")} />}
      />
      <Route
        path={getRoutePath("DEPRECATED.personalization.popup-web-banners.create")}
        render={() => <Redirect to={getRoutePath("channels.popup-banners.create")} />}
      />
      <Route
        path={getRoutePath("DEPRECATED.personalization.popup-web-banners.detail")}
        render={({
          match: {
            params: { id },
          },
        }) => <Redirect to={getRoutePath("channels.popup-banners.detail", { id })} />}
      />
      <Route
        exact
        path={getRoutePath("DEPRECATED.personalization.embedded-web-banners")}
        render={() => <Redirect to={getRoutePath("channels.native-banners")} />}
      />
      <Route
        path={getRoutePath("DEPRECATED.personalization.embedded-web-banners.create")}
        render={() => <Redirect to={getRoutePath("channels.native-banners.create")} />}
      />
      <Route
        path={getRoutePath("DEPRECATED.personalization.embedded-web-banners.detail")}
        render={({
          match: {
            params: { id },
          },
        }) => <Redirect to={getRoutePath("channels.native-banners.detail", { id })} />}
      />
      <Route
        exact
        path={getRoutePath("DEPRECATED.personalization.mobile-push-notifications")}
        render={() => <Redirect to={getRoutePath("channels.mobile-push")} />}
      />
      <Route
        path={getRoutePath("DEPRECATED.personalization.mobile-push-notifications.create")}
        render={() => <Redirect to={getRoutePath("channels.mobile-push.create")} />}
      />
      <Route
        path={getRoutePath("DEPRECATED.personalization.mobile-push-notifications.detail")}
        render={({
          match: {
            params: { id },
          },
        }) => <Redirect to={getRoutePath("channels.mobile-push.detail", { id })} />}
      />

      <Route
        path={getRoutePath("DEPRECATED.personalization.emails")}
        render={() => <Redirect to={getRoutePath("channels.emails")} />}
      />
      <Route
        path={getRoutePath("DEPRECATED.personalization.promo-codes")}
        render={() => <Redirect to={getRoutePath("channels.promo-codes")} />}
      />

      <Route
        exact
        path={getRoutePath("DEPRECATED.setup.sources")}
        render={() => <Redirect to={getRoutePath("administration.sources")} />}
      />
      <Route
        path={getRoutePath("DEPRECATED.setup.sources.create")}
        render={() => <Redirect to={getRoutePath("administration.sources.create")} />}
      />
      <Route
        path={getRoutePath("DEPRECATED.setup.sources.detail")}
        render={({
          match: {
            params: { id },
          },
        }) => <Redirect to={getRoutePath("administration.sources.detail", { id })} />}
      />
      <Route
        exact
        path={getRoutePath("DEPRECATED.setup.events")}
        render={() => <Redirect to={getRoutePath("administration.events")} />}
      />
      <Route
        path={getRoutePath("DEPRECATED.setup.events.create")}
        render={() => <Redirect to={getRoutePath("administration.events.create")} />}
      />
      <Route
        path={getRoutePath("DEPRECATED.setup.events.detail")}
        render={({
          match: {
            params: { id },
          },
        }) => <Redirect to={getRoutePath("administration.events.detail", { id })} />}
      />
      <Route
        exact
        path={getRoutePath("DEPRECATED.setup.identity-stitching")}
        render={() => <Redirect to={getRoutePath("administration.identity-stitching")} />}
      />
      <Route
        path={getRoutePath("DEPRECATED.setup.identity-stitching.detail")}
        render={({
          match: {
            params: { id },
          },
        }) => <Redirect to={getRoutePath("administration.identity-stitching.detail", { id })} />}
      />
      <Route
        exact
        path={getRoutePath("DEPRECATED.setup.attributes")}
        render={() => <Redirect to={getRoutePath("administration.attributes")} />}
      />
      <Route
        path={getRoutePath("DEPRECATED.setup.attributes.detail")}
        render={({
          match: {
            params: { id },
          },
        }) => <Redirect to={getRoutePath("administration.attributes.detail", { id })} />}
      />
      <Route
        path={getRoutePath("DEPRECATED.setup.attributes.create")}
        render={() => <Redirect to={getRoutePath("administration.attributes.create")} />}
      />
      <Route
        exact
        path={getRoutePath("DEPRECATED.setup.metaAttributes")}
        render={() => <Redirect to={getRoutePath("administration.metaAttributes")} />}
      />
      <Route
        path={getRoutePath("DEPRECATED.setup.metaAttributes.detail")}
        render={({
          match: {
            params: { id },
          },
        }) => <Redirect to={getRoutePath("administration.metaAttributes.detail", { id })} />}
      />
      <Route
        path={getRoutePath("DEPRECATED.setup.metaAttributes.create")}
        render={() => <Redirect to={getRoutePath("administration.metaAttributes.create")} />}
      />
      <Route
        path={getRoutePath("DEPRECATED.setup.labels")}
        render={() => <Redirect to={getRoutePath("administration.labels")} />}
      />
      <Route
        path={getRoutePath("DEPRECATED.setup.segments.smart.detail.builder")}
        render={({
          match: {
            params: { id },
          },
        }) => <Redirect to={getRoutePath("segments.smart.detail.builder", { id })} />}
      />
      <Route
        path={getRoutePath("DEPRECATED.setup.segments.smart.detail.exports")}
        render={({
          match: {
            params: { id },
          },
        }) => <Redirect to={getRoutePath("segments.smart.detail.exports", { id })} />}
      />
      <Route
        exact
        path={getRoutePath("DEPRECATED.setup.segments.smart.detail")}
        render={({
          match: {
            params: { id },
          },
        }) => <Redirect to={getRoutePath("segments.smart.detail", { id })} />}
      />
      <Route
        exact
        path={getRoutePath("DEPRECATED.setup.segments.smart")}
        render={() => <Redirect to={getRoutePath("segments.smart")} />}
      />
      <Route
        path={getRoutePath("DEPRECATED.setup.insights")}
        render={() => <Redirect to={getRoutePath("administration.insights")} />}
      />
      <Route
        exact
        path={getRoutePath("DEPRECATED.setup.destinations")}
        render={() => <Redirect to={getRoutePath("administration.destinations")} />}
      />
      <Route
        path={getRoutePath("DEPRECATED.setup.destinations.detail")}
        render={({
          match: {
            params: { id },
          },
        }) => <Redirect to={getRoutePath("administration.destinations.detail", { id })} />}
      />
      <Route
        exact
        path={getRoutePath("DEPRECATED.setup.reports")}
        render={() => <Redirect to={getRoutePath("administration.reports")} />}
      />
      <Route
        path={getRoutePath("DEPRECATED.setup.reports.create")}
        render={() => <Redirect to={getRoutePath("administration.reports.create")} />}
      />
      <Route
        path={getRoutePath("DEPRECATED.setup.reports.detail")}
        render={({
          match: {
            params: { id },
          },
        }) => <Redirect to={getRoutePath("administration.reports.detail", { id })} />}
      />
      <Route
        path={getRoutePath("DEPRECATED.data.sources-destinations")}
        render={() => <Redirect to={getRoutePath("data.sources")} />}
      />

      <Route
        path={getRoutePath("DEPREDACTED.administration.accounts")}
        render={() => <Redirect to={getRoutePath("administration.channels")} />}
      />

      <Route component={NotFoundPage} />
    </Switch>
  )
}
