import React, { PureComponent, useState } from "react"
import TimeAgo from "react-timeago"
import Select from "react-select"

// ui components
import Avatar from "components/UI/elements/Avatar"
import Paper from "components/UI/elements/Paper"
import ToggleButton from "components/UI/elements/ToggleButton/ToggleButton"
import IconButton from "components/UI/elements/IconButton/IconButton"
import Button from "components/UI/elements/Button/Button"
import ConfirmModal from "components/UI/components/ConfirmModal"
import { DropdownIndicator } from "components/UI/elements/SelectField"

// helpers, constants
import { TOAST, MODAL } from "sharedConstants"
import { api } from "api"
import { selectStyles } from "helpers/customSelectStyle.helper"
import { getRoutePath } from "routes"
import { copyStringToClipboard } from "helpers/string.helper"

import "./UsersList.scss"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import create from "zustand"
import { useDeleteUser, useFetchActiveUsers, useModifyUser } from "resources/user/userQueries"
import { showToast } from "app/toast"
import Page from "components/UI/Page/Page"
import { Link } from "react-router-dom"
import SearchField from "components/UI/elements/SearchField"
import { ISOwoTZtoDate } from "utilities/date"
import Table, { Name } from "components/Table/Table"
import { useFetchUserRoleOptions } from "resources/userRole/userRoleQueries"

class UsersList extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      deleteModal: {
        open: false,
        item: null,
      },
      resendingInvitation: false,
      copyingLink: false,
    }
  }

  deleteUser = () => {
    const { deleteModal } = this.state
    const { deleteUser } = this.props
    deleteUser(deleteModal.item.id)
    this.closeDeleteUserModal()
  }

  openDeleteUserModal = user => () => {
    this.setState({
      deleteModal: {
        open: true,
        item: user,
      },
    })
  }

  closeDeleteUserModal = () => {
    this.setState({
      deleteModal: {
        ...this.state.deleteModal,
        open: false,
      },
    })
  }

  resendInvitation = (name, email) => () => {
    const { resendingInvitation } = this.state
    if (!resendingInvitation) {
      this.setState({ resendingInvitation: true })
      api.user
        .resendInvitation(email)
        .then(() => {
          showToast(
            `The user has been successfully re-invited. Invitation will expire in 5 days.`,
            TOAST.TYPE.SUCCESS,
          )
        })
        .catch()
        .finally(() => {
          this.setState({ resendingInvitation: false })
        })
    }
  }

  goToUser = user => () => {
    this.props.history.push({
      pathname: getRoutePath("administration.users.detail", { id: user.id }),
      state: { goBack: true },
    })
  }

  copyInvitationLink = userId => async () => {
    try {
      this.setState({
        copyingLink: true,
      })
      const response = await api.user.getInvitationLink(userId)
      const link = response.user_invitation_link
      copyStringToClipboard(link)
      this.setState({
        copyingLink: false,
      })
      showToast(
        "New invitation link is copied to clipboard! Use it before it expires in 5 days.",
        TOAST.TYPE.SUCCESS,
      )
    } catch (err) {
      this.setState({
        copyingLink: false,
      })
    }
  }

  columns = [
    {
      id: "avatar",
      gridTemplate: "max-content",
      renderCell: user => (
        <Avatar name={user.name} email={user.email} disabledLook={user.disabled} />
      ),
    },
    {
      id: "name",
      label: "Name",
      gridTemplate: "1fr",
      onSort: () => {
        this.props.filters.setSort("name")
      },
      renderCell: user => (
        <div data-testid="td-name">
          <Name className="name" name={user.name} />
        </div>
      ),
    },
    {
      id: "email",
      label: "Email",
      gridTemplate: "1fr",
      onSort: () => {
        this.props.filters.setSort("email")
      },
      renderCell: user => <div className="email">{user.email}</div>,
    },
    {
      id: "last_login",
      label: "Last login",
      gridTemplate: "175px",
      onSort: () => {
        this.props.filters.setSort("last_login")
      },
      renderCell: user => (
        <div className="last-login">
          {user.last_login && <TimeAgo date={ISOwoTZtoDate(user.last_login)} />}

          {!user.last_login && (
            <>
              <div>Never,</div>
              <span
                className={`resend-inv ${this.state.resendingInvitation ? "sending" : ""}`}
                onClick={this.resendInvitation(user.name, user.email)}
              >
                Resend invitation
              </span>{" "}
              <span>or</span>{" "}
              <span
                className={`copy-inv-link ${this.state.copyingLink ? "copying" : ""}`}
                onClick={this.copyInvitationLink(user.id)}
              >
                Create new invitation link
              </span>
            </>
          )}
        </div>
      ),
    },
    {
      id: "role",
      label: "Role",
      gridTemplate: "200px",
      onSort: () => {
        this.props.filters.setSort("role.name")
      },
      renderCell: user => (
        <Select
          value={this.props.roleOptions?.find(opt => opt.value === user.role.id) ?? null}
          onChange={opt => this.props.setUserRole(user.id, opt.value)}
          options={this.props.roleOptions ?? []}
          styles={selectStyles("small")}
          simpleValue
          isSearchable={true}
          isLoading={this.props.isLoadingRoles}
          className="select-input"
          components={{
            DropdownIndicator: DropdownIndicator,
          }}
          classNamePrefix="role_select"
          noOptionsMessage={() => "Empty"}
        />
      ),
    },
    {
      id: "disabled",
      label: "Enabled",
      gridTemplate: "max-content",
      onSort: () => {
        this.props.filters.setSort("disabled")
      },
      renderCell: user => (
        <ToggleButton value={!user.disabled} handleToggle={() => this.props.toggleDisabled(user)} />
      ),
    },
    {
      id: "actions",
      gridTemplate: "max-content",
      renderCell: user => (
        <div className="actions">
          <IconButton
            color="black"
            onClick={this.goToUser(user)}
            size="xs"
            icon={["far", "user-edit"]}
            tooltip="Edit"
            variant="outlined"
          />
          <IconButton
            color="red"
            onClick={this.openDeleteUserModal(user)}
            size="xs"
            className="trash"
            icon="trash-alt"
            tooltip="Delete"
            variant="outlined"
          />
        </div>
      ),
    },
  ]

  render() {
    const {
      filters,
      users,
      areUsersFulfilled,
      history,
      showAutomated,
      toggleShowAutomated,
      isLoadingRoles,
    } = this.props
    const { deleteModal } = this.state
    const { orderBy, orderDir } = filters

    const isLoading = isLoadingRoles || !areUsersFulfilled

    return (
      <Page
        title="Users"
        className="users"
        headerContent={
          <>
            <SearchField
              input={{ value: filters.searchTerm, onChange: filters.setSearchTerm }}
              placeholder="Search for users"
              onClear={() => filters.setSearchTerm("")}
              wrapperClassName="users-search"
            />
            <Button onClick={() => history.push(getRoutePath("administration.users.create"))}>
              + Create user
            </Button>
          </>
        }
      >
        <>
          {isLoading && <LoadingIndicator />}
          {!isLoading && (
            <Paper noPaddingTop>
              <div className="trash-button-wrapper">
                <div>Show system users</div>
                <ToggleButton value={showAutomated} handleToggle={toggleShowAutomated} size="sm" />
                <Link to={getRoutePath("administration.users.trash")}>
                  <Button type="button" color="grey" variant="outlined" icon={["far", "trash-alt"]}>
                    Trash
                  </Button>
                </Link>
              </div>
              <Table
                columns={this.columns}
                data={users}
                sortBy={orderBy}
                sortDir={orderDir}
                getRowClassName={user => (user.disabled ? "disabled" : undefined)}
              />
            </Paper>
          )}
          <ConfirmModal
            open={deleteModal.open}
            type={MODAL.TYPE.DELETE}
            handleClose={this.closeDeleteUserModal}
            handleConfirm={this.deleteUser}
            title="Delete user"
            action="delete"
            what="user"
            item={deleteModal.item?.name ?? ""}
          />
        </>
      </Page>
    )
  }
}

const useStore = create(set => ({
  orderBy: "name",
  orderDir: "ASC",
  searchTerm: "",
  setSort: orderBy =>
    set(state => ({
      orderDir: state.orderBy === orderBy && state.orderDir === "ASC" ? "DESC" : "ASC",
      orderBy: orderBy,
    })),
  setSearchTerm: searchTerm => set({ searchTerm }),
}))

export default props => {
  const [showAutomated, setShowAutomted] = useState(false)
  const filters = useStore()
  const { orderBy, orderDir, searchTerm } = filters

  const { data: users, isSuccess } = useFetchActiveUsers({
    orderBy,
    orderDir,
    showAutomated,
    searchTerm: searchTerm.trim(),
  })

  const modifyMutation = useModifyUser()

  const toggleDisabled = user =>
    modifyMutation.mutateAsync({ id: user.id, data: { disabled: !user.disabled } })

  const setUserRole = (userId, roleId) =>
    modifyMutation.mutate({ id: userId, data: { role_id: roleId } })

  const deleteMutation = useDeleteUser()
  const deleteUser = id => deleteMutation.mutate({ id })

  const { data: roleOptions = [], isLoading: isLoadingRoles } = useFetchUserRoleOptions()

  return (
    <UsersList
      {...props}
      users={users}
      filters={filters}
      areUsersFulfilled={isSuccess}
      toggleDisabled={toggleDisabled}
      setUserRole={setUserRole}
      deleteUser={deleteUser}
      showAutomated={showAutomated}
      toggleShowAutomated={() => setShowAutomted(prev => !prev)}
      roleOptions={roleOptions}
      isLoadingRoles={isLoadingRoles}
    />
  )
}
