import React, { Fragment, useEffect, useState } from "react"
import classNames from "classnames"
import styles from "./RolePicker.module.scss"
import { UserRole } from "resources/userRole/userRoleTypes"
import ErrorTippy from "components/UI/elements/ErrorTippy/ErrorTippy"
import { useFetchAllUserRoles } from "resources/userRole/userRoleQueries"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import useClickOutHandler from "hooks/useClickOutHandler"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { allFeatures, featuresSections } from "resources/userRole/features"
import { useFetchChannels } from "resources/channel/channelQueries"
import { useFetchAllDestinations } from "resources/exportDestination/exportDestinationQueries"
import { whereEq } from "ramda"
import {
  ADMIN_ROLE_ID,
  EMAILS_CHANNEL_NAME,
  PUSH_NOTIFICATIONS_CHANNEL_NAME,
} from "sharedConstants"

type RolePickerProps = {
  errorMessage?: string
  value: UserRole["id"] | null
  onChange: (value: UserRole["id"]) => void
}

export default function RolePicker({ errorMessage, value, onChange }: RolePickerProps) {
  const { data: roles, isLoading } = useFetchAllUserRoles()
  const { close, isOpen, buttonRef, ref, toggle } = useClickOutHandler()
  const [hoveredRoleId, setHoveredRoleId] = useState<UserRole["id"] | null>(value)

  useEffect(() => {
    if (isOpen) {
      setHoveredRoleId(value ?? roles?.[0].id ?? null)
    }
  }, [isOpen, roles, value])

  if (isLoading) {
    return <LoadingIndicator />
  }
  const selectedRole = roles?.find(({ id }) => id === value)
  const hoveredRole = roles?.find(({ id }) => id === hoveredRoleId)
  const enabledFeatures = hoveredRole?.id === ADMIN_ROLE_ID ? allFeatures : hoveredRole?.features

  return (
    <ErrorTippy disabled={!errorMessage || isOpen} content={errorMessage}>
      <div
        className={classNames(styles.container, {
          [styles.open]: isOpen,
          [styles.error]: errorMessage,
          [styles.empty]: !value,
        })}
      >
        <button ref={buttonRef} type="button" onClick={toggle} className={styles.button}>
          <div className={styles.value}>{selectedRole?.name ?? "Select role"}</div>
          <div className={styles.caret}>
            <FontAwesomeIcon icon={["fas", "caret-down"]} flip={isOpen ? "vertical" : undefined} />
          </div>
        </button>
        {isOpen && (
          <div className={styles.dropdown} ref={ref}>
            <div className={styles.rolesList}>
              {roles?.map(role => (
                <div
                  key={role.id}
                  className={classNames(styles.roleItem, {
                    [styles.hovered]: hoveredRoleId === role.id,
                  })}
                  onMouseEnter={() => setHoveredRoleId(role.id)}
                  onClick={() => {
                    onChange(role.id)
                    close()
                  }}
                >
                  {role.name}
                </div>
              ))}
            </div>
            <div className={styles.permissions}>
              <div className={styles.permissionsColumn}>
                {featuresSections.slice(0, 3).map(section => (
                  <PermissionsSection
                    key={section.title}
                    section={section}
                    enabledFeatures={enabledFeatures}
                  />
                ))}
              </div>
              <div className={styles.permissionsColumn}>
                {featuresSections.slice(3).map(section => (
                  <PermissionsSection
                    key={section.title}
                    section={section}
                    enabledFeatures={enabledFeatures}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </ErrorTippy>
  )
}

function PermissionsSection({
  section,
  enabledFeatures,
}: {
  section: typeof featuresSections[number]
  enabledFeatures?: UserRole["features"]
}) {
  const { data: channels = [] } = useFetchChannels()
  const { data: destinations = [] } = useFetchAllDestinations()

  const areEmailsActive = channels?.find(whereEq({ name: EMAILS_CHANNEL_NAME }))?.is_active
  const areMobilePushesActive = channels?.find(
    whereEq({ name: PUSH_NOTIFICATIONS_CHANNEL_NAME }),
  )?.is_active

  return (
    <div className={styles.permissionsSection}>
      <div className={styles.title}>{section.title}</div>
      {section.items.map(({ feature, label }) => {
        if (
          (feature.startsWith("emails/") && !areEmailsActive) ||
          (feature.startsWith("push_notifications/") && !areMobilePushesActive)
        ) {
          return null
        }

        return (
          <Fragment key={feature}>
            <div
              className={classNames(styles.permissionItem, {
                [styles.enabled]: enabledFeatures?.includes(feature),
              })}
              key={feature}
            >
              <div>{label}</div>
              <div className={styles.indicator}></div>
            </div>
            {feature === "segments/export" && (
              <div className={styles.destinations}>
                {destinations.map(({ id, name }) => (
                  <div
                    className={classNames(styles.permissionItem, styles.destination, {
                      [styles.enabled]:
                        (enabledFeatures as string[])?.includes(`segments/export/${id}`) ||
                        enabledFeatures?.includes("segments/export"),
                    })}
                    key={id}
                  >
                    <div>{name}</div>
                    <div className={styles.indicator}></div>
                  </div>
                ))}
              </div>
            )}
          </Fragment>
        )
      })}
    </div>
  )
}
