import React, { useState } from "react"
import styles from "./RolesList.module.scss"
import Page from "components/UI/Page/Page"
import { useDeleteUserRole, useFetchAllUserRoles } from "resources/userRole/userRoleQueries"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Paper from "components/UI/elements/Paper"
import { Link } from "react-router-dom"
import { getRoutePath } from "routes"
import Button from "components/UI/elements/Button/Button"
import Table, { Column, Name } from "components/Table/Table"
import { UserRole } from "resources/userRole/userRoleTypes"
import ConfirmModal from "components/UI/components/ConfirmModal"
import { isNil, reject, startsWith, whereEq } from "ramda"
import {
  ADMIN_ROLE_ID,
  EMAILS_CHANNEL_NAME,
  PUSH_NOTIFICATIONS_CHANNEL_NAME,
} from "sharedConstants"
import SystemBadge from "components/UI/elements/SystemBadge/SystemBadge"
import { allFeatures, getFeatureTagLabel } from "resources/userRole/features"
import { useFetchDestinationsMap } from "resources/exportDestination/exportDestinationQueries"
import Tag from "components/UI/elements/Tag"
import IconButton from "components/UI/elements/IconButton/IconButton"
import { useFetchChannels } from "resources/channel/channelQueries"

export default function RolesList() {
  const { data: roles, isLoading: isLoadingUserRoles } = useFetchAllUserRoles()
  const deleteMutation = useDeleteUserRole()
  const [idToDelete, setIdToDelete] = useState<UserRole["id"] | null>(null)
  const { data: destinationsMap = {}, isLoading: isLoadingDestinations } = useFetchDestinationsMap()
  const { data: channels = [] } = useFetchChannels()
  const areEmailsActive = channels?.find(whereEq({ name: EMAILS_CHANNEL_NAME }))?.is_active
  const areMobilePushesActive = channels?.find(
    whereEq({ name: PUSH_NOTIFICATIONS_CHANNEL_NAME }),
  )?.is_active

  const columns: Column<UserRole>[] = [
    {
      id: "name",
      label: "Name",
      gridTemplate: "1fr",
      renderCell: role => (
        <div data-testid="td-name">
          <Name name={role.name} /> {role.id === ADMIN_ROLE_ID && <SystemBadge />}
        </div>
      ),
    },
    {
      id: "features",
      label: "List of enabled permissions",
      gridTemplate: "4fr",
      renderCell: role => {
        let features = role.id === ADMIN_ROLE_ID ? allFeatures : role.features
        const hasAllExports = features.includes("segments/export")
        if (hasAllExports) {
          features = reject(startsWith("segments/export/"), features)
        }
        if (!areEmailsActive) {
          features = reject(startsWith("emails/"), features)
        }
        if (!areMobilePushesActive) {
          features = reject(startsWith("push_notifications/"), features)
        }

        return (
          <div className={styles.features}>
            {features.map(featureName => {
              const title = getFeatureTagLabel(featureName, destinationsMap)
              if (title) {
                return (
                  <Tag key={featureName} color="site-background">
                    {title}
                  </Tag>
                )
              }
              return null
            })}
          </div>
        )
      },
    },
    {
      id: "actions",
      gridTemplate: "max-content",
      renderCell: ({ id }) => (
        <div className={styles.actions}>
          {id === ADMIN_ROLE_ID ? (
            <Link to={getRoutePath("administration.roles.detail", { id: id })}>
              <IconButton
                icon="magnifying-glass"
                color="black"
                size="xs"
                tooltip="View"
                variant="outlined"
              />
            </Link>
          ) : (
            <>
              <Link to={getRoutePath("administration.roles.detail", { id: id })}>
                <IconButton
                  icon="pencil-alt"
                  color="black"
                  size="xs"
                  tooltip="Edit"
                  variant="outlined"
                />
              </Link>
              <IconButton
                icon="trash-alt"
                color="red"
                onClick={() => setIdToDelete(id)}
                size="xs"
                tooltip="Delete"
                variant="outlined"
              />
            </>
          )}
        </div>
      ),
    },
  ]

  const isLoading = isLoadingDestinations || isLoadingUserRoles

  return (
    <Page
      title="User roles"
      headerContent={
        <Link to={getRoutePath("administration.roles.create")}>
          <Button>+ Create role</Button>
        </Link>
      }
    >
      <ConfirmModal
        open={!isNil(idToDelete)}
        type="delete"
        handleClose={() => setIdToDelete(null)}
        handleConfirm={() => {
          deleteMutation.mutate(
            { id: idToDelete! },
            {
              onSuccess() {
                setIdToDelete(null)
              },
            },
          )
        }}
        isLoading={deleteMutation.isLoading}
        title="Are you sure?"
        action="delete"
        item={roles?.find(whereEq({ id: idToDelete }))?.name ?? ""}
        what="role"
      />

      {isLoading && <LoadingIndicator />}

      {!isLoading && roles && (
        <Paper>
          <div className={styles.trashButtonWrapper}>
            <Link to={getRoutePath("administration.roles.trash")}>
              <Button type="button" color="grey" variant="outlined" icon={["far", "trash-alt"]}>
                Trash
              </Button>
            </Link>
          </div>

          <Table emptyMessage="No role exists yet." data={roles} columns={columns} />
        </Paper>
      )}
    </Page>
  )
}
