import { isEmpty } from "ramda"

import { AttributesMapById } from "resources/attribute/attributeTypes"

export function replaceAttributePlaceholders(text: string, attributesMapById: AttributesMapById) {
  return text.replaceAll(
    /(?:{{(?<attributeId>[^.]+?)(?:\.(?<dimensionId>.+?))?}})/g,
    (match, attributeId, dimensionId) => {
      const attribute = attributesMapById[attributeId]

      if (
        !attribute ||
        !attribute.examples ||
        isEmpty(attribute.examples) ||
        (!dimensionId && !Array.isArray(attribute.examples)) ||
        (dimensionId &&
          // @ts-ignore
          (!attribute.examples[dimensionId] || isEmpty(attribute.examples[dimensionId])))
      ) {
        return match
      }

      // @ts-ignore
      const examples: any[] = dimensionId ? attribute.examples[dimensionId] : attribute.examples

      // Return longest example to have a better chance to catch problems with message length
      return examples.reduce((a, b) => (a.toString().length >= b.toString().length ? a : b))
    },
  )
}

export const TTL_4_WEEKS_IN_SEC = 4 * 7 * 24 * 60 * 60 // 2419200

export const ttlOptions = [
  {
    label: "4 weeks",
    value: TTL_4_WEEKS_IN_SEC,
  },
  {
    label: "3 weeks",
    value: 3 * 7 * 24 * 60 * 60, // 1814400
  },
  {
    label: "2 weeks",
    value: 2 * 7 * 24 * 60 * 60, // 1209600
  },
  {
    label: "1 week",
    value: 7 * 24 * 60 * 60, // 604800
  },
  {
    label: "3 days",
    value: 3 * 24 * 60 * 60, // 259200
  },
  {
    label: "2 days",
    value: 2 * 24 * 60 * 60, // 172800
  },
  {
    label: "1 day",
    value: 24 * 60 * 60, // 86400
  },
  {
    label: "4 hours",
    value: 4 * 60 * 60, // 14400
  },
  {
    label: "2 hours",
    value: 2 * 60 * 60, // 7200
  },
  {
    label: "1 hour",
    value: 60 * 60, // 3600
  },
  {
    label: "30 minutes",
    value: 30 * 60, // 1800
  },
  {
    label: "10 minutes",
    value: 10 * 60, // 600
  },
  {
    label: "5 minutes",
    value: 5 * 60, // 300
  },
  {
    label: "3 minutes",
    value: 3 * 60, // 180
  },
  {
    label: "2 minutes",
    value: 2 * 60, // 120
  },
  {
    label: "1 minute",
    value: 60,
  },
]
