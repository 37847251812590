import {
  QueryKey,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import { isNil, prop, sort, update } from "ramda"

import { api } from "api"
import { showToast } from "app/toast"
import {
  CreateStitchingCategoryPayload,
  ModifyStitchingCategoryPayload,
  StitchingCategory,
  StitchingCategoryListResponse,
  StitchingCategoryOptions,
  StitchingCategoryRule,
  StitchingCategoryRuleListResponse,
} from "./stitchingCategoryTypes"
import { useCallback } from "react"
import { ascend, descend } from "utilities/comparators"

const STITCHING_CATEGORY = "stitchingCategory" as const
const STITCHING_CATEGORY_ALL_QK: QueryKey = [STITCHING_CATEGORY, "all"]
const STITCHING_RULE = "rule" as const

const IDENTITY_GRAPH_COLORS = [
  "#F15555",
  "#136067",
  "#7F44F3",
  "#49BB0F",
  "#E4C143",
  "#189FAB",
  "#410074",
  "#8ACDA6",
  "#0D3FA3",
  "#6C7EA3",
]

const addColor = (stitchingCategories: Array<StitchingCategory>) =>
  stitchingCategories.map((category, index) => ({
    ...category,
    identity_graph_color: IDENTITY_GRAPH_COLORS[index] ?? "#fe7f66",
  }))

function useStitchingCategoriesQuery<T = StitchingCategoryListResponse>(
  config?: UseQueryOptions<StitchingCategoryListResponse, unknown, T, QueryKey>,
) {
  return useQuery(STITCHING_CATEGORY_ALL_QK, api.stitchingCategory.list, {
    ...config,
    staleTime: 5000,
  })
}

export const useFetchAllStitchingCategories = (options?: StitchingCategoryOptions) =>
  useStitchingCategoriesQuery({
    select: useCallback(
      (data: StitchingCategoryListResponse) => {
        if (!options) return data.stitching_categories

        const comparator = options.orderDir === "ASC" ? ascend : descend
        const selector =
          options.orderBy === "count"
            ? (c: StitchingCategory) => c.stitching_rules.length
            : prop(options.orderBy)
        const sortedCategories = sort(comparator(selector), data.stitching_categories)

        return sortedCategories
      },
      [options],
    ),
  })

export const useFetchIdentityGraphStitchingCategories = () =>
  useStitchingCategoriesQuery({
    select: data => {
      return addColor(data.stitching_categories)
    },
  })

export const useFetchAudienceApiStitchingCategories = () =>
  useStitchingCategoriesQuery({
    select: data => {
      return data.stitching_categories.filter(category => category.audience_api_enabled)
    },
  })

export const useFetchStitchingCategoryById = (id?: StitchingCategory["id"]) => {
  const select = useCallback(
    ({ stitching_categories }: StitchingCategoryListResponse) =>
      stitching_categories.find(category => category.id === id) ?? null,
    [id],
  )

  return useStitchingCategoriesQuery({ enabled: !isNil(id), select })
}

export const useCreateStitchingCategory = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ data }: { data: CreateStitchingCategoryPayload }) => api.stitchingCategory.create(data),
    {
      onSuccess: ({ stitching_category }) => {
        queryClient.setQueryData<StitchingCategoryListResponse>(STITCHING_CATEGORY_ALL_QK, data => {
          if (!data) return

          const sortedCategories = sort(
            ascend(prop("name")),
            data.stitching_categories.concat(stitching_category),
          )

          return {
            ...data,
            stitching_categories: sortedCategories,
          }
        })

        showToast("Stitching category created.")
      },
    },
  )
}

export const useModifyStitchingCategory = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, data }: { id: StitchingCategory["id"]; data: ModifyStitchingCategoryPayload }) =>
      api.stitchingCategory.modify(id, data),
    {
      onSuccess: ({ stitching_category }) => {
        queryClient.setQueryData<StitchingCategoryListResponse>(STITCHING_CATEGORY_ALL_QK, data => {
          if (!data) return

          const index = data.stitching_categories.findIndex(
            ({ id }) => id === stitching_category.id,
          )
          const stitchingCategories =
            index === -1
              ? data.stitching_categories.concat(stitching_category)
              : update(index, stitching_category, data.stitching_categories)

          return { ...data, stitching_categories: stitchingCategories }
        })

        showToast(" Stitching category modified.")
      },
    },
  )
}

export const useDeleteStitchingCategory = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id }: { id: StitchingCategory["id"] }) => api.stitchingCategory.delete(id),
    {
      onSuccess: (_, { id }) => {
        queryClient.setQueryData<StitchingCategoryListResponse>(STITCHING_CATEGORY_ALL_QK, data => {
          if (!data) return

          const stitchingCategories = data.stitching_categories.filter(
            category => category.id !== id,
          )

          return { ...data, stitching_categories: stitchingCategories }
        })

        queryClient.removeQueries([STITCHING_CATEGORY, id, STITCHING_RULE, "all"])
        showToast("Stitching category deleted.")
      },
    },
  )
}

export const useModifyStitchingCategoryRules = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, rules }: { id: StitchingCategory["id"]; rules: Array<StitchingCategoryRule> }) =>
      api.stitchingCategory.rule.modify(id, rules),
    {
      onSuccess: ({ stitching_rules }, { id }) => {
        queryClient.setQueryData<StitchingCategoryRuleListResponse>(
          [STITCHING_CATEGORY, id, STITCHING_RULE, "all"],
          data => {
            if (!data) return

            return { ...data, stitching_rules }
          },
        )

        showToast("Stitching category rules modified.")
      },
    },
  )
}
