import React, { useState } from "react"
import styles from "./UserCreate.module.scss"
import Page from "components/UI/Page/Page"
import { useHistory } from "react-router-dom"
import Button from "components/UI/elements/Button/Button"
import { getRoutePath } from "routes"
import Paper from "components/UI/elements/Paper"
import ToggleButton from "components/UI/elements/ToggleButton/ToggleButton"
import { assoc, isNil, not, whereEq, without } from "ramda"
import { Controller, FormProvider, useFieldArray, useForm, useFormContext } from "react-hook-form"
import { UserRole } from "resources/userRole/userRoleTypes"
import { useCreateUser, useFetchAllUsers, useInviteUser } from "resources/user/userQueries"
import TextInput from "components/UI/elements/TextInput/TextInput"
import { required, email as emailValidator, minLength, maxLength } from "helpers/validators.helper"
import IconButton from "components/UI/elements/IconButton/IconButton"
import Avatar from "components/UI/elements/Avatar"
import { showToast } from "app/toast"
import RolePicker from "components/RolePicker/RolePicker"
import { Permission } from "types/util"
import { Segment, SegmentType } from "resources/segment/segment/segmentTypes"
import { useFetchUserRolesMap } from "resources/userRole/userRoleQueries"
import { useCreateSegmentPermissionsByUserId } from "resources/segmentPermission/segmentPermissionQueries"
import { useFetchAllSegmentsFull } from "resources/segment/segment/segmentQueries"
import { Tag } from "resources/tag/tagTypes"
import SegmentTagsPicker from "components/SegmentTagsPicker/SegmentTagsPicker"
import SearchField from "components/UI/elements/SearchField"
import Tippy from "@tippyjs/react"
import ToggleSwitch from "components/UI/elements/ToggleSwitch"
import Checkbox from "components/UI/elements/Checkbox/Checkbox"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import classNames from "classnames"
import { ADMIN_ROLE_ID } from "sharedConstants"

type UserFormValues = {
  users: Array<{
    name: string
    email: string
    password?: string
  }>
  role_id: UserRole["id"] | null
  segmentPermissions: Record<Segment["id"], { permission: Permission; enabled: boolean }>
}

export default function UserCreate() {
  const history = useHistory()
  const [isInvite, setIsInvite] = useState(true)

  const form = useForm<UserFormValues>({
    defaultValues: {
      users: [getEmptyUser()],
      role_id: null,
      segmentPermissions: {},
    },
  })
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    watch,
  } = form
  const {
    fields: users,
    append: appendUser,
    remove: removeUser,
  } = useFieldArray({ name: "users", control })

  const createSegmentPermissionsMutation = useCreateSegmentPermissionsByUserId()
  const createMutation = useCreateUser()
  const inviteMutation = useInviteUser()

  const { data: userRolesMap = {} } = useFetchUserRolesMap()
  const roleId = watch("role_id")
  const selectedRole = (!isNil(roleId) && userRolesMap[roleId]) || null

  const { data: segments = [], isLoading: isLoadingSegments } = useFetchAllSegmentsFull()

  async function submit({ users, role_id, segmentPermissions }: UserFormValues) {
    try {
      // TODO: What to do if only some fail?
      await Promise.all(
        users.map(async ({ name, email, password }) => {
          const permissionList = Object.entries(segmentPermissions)
            .filter(([_, { enabled }]) => enabled)
            .map(([segmentId, { permission }]) => {
              const segment = segments.find(whereEq({ id: +segmentId }))
              return [
                segmentId,
                hasWritePermission(selectedRole, segment!.segment_type) ? "write" : permission,
              ]
            })
          const permissionsPayload = Object.fromEntries(permissionList)

          const user = isInvite
            ? await inviteMutation.mutateAsync({ data: { name, email, role_id: role_id! } })
            : await createMutation.mutateAsync({
                data: { name, email, password: password!, role_id: role_id! },
              })

          if (Object.keys(segmentPermissions).length) {
            await createSegmentPermissionsMutation.mutateAsync({
              userId: user.id,
              segmentPermissions: permissionsPayload,
            })
          }
        }),
      )
      showToast(`User${users.length > 1 ? "s" : ""} ${isInvite ? "invited" : "created"}.`)
      history.push(getRoutePath("administration.users"))
    } catch {
      //
    }
  }

  const [tagIds, setTagIds] = useState<Tag["id"][]>([])
  const [searchTerm, setSearchTerm] = useState("")

  const filteredSegments = segments.filter(
    ({ name, tag_ids }) =>
      (tagIds.length === 0 || tag_ids?.some(tagId => tagIds.includes(tagId))) &&
      (searchTerm.length === 0 || name.toLowerCase().includes(searchTerm.toLowerCase().trim())),
  )

  const isToggleAllDisabled = segments.every(segment =>
    hasWritePermission(selectedRole, segment.segment_type),
  )

  return (
    <Page
      title="Create users"
      headerContent={
        <div className={styles.headerButtons}>
          <Button
            color="grey"
            variant="outlined"
            onClick={() => {
              history.push(getRoutePath("administration.users"))
            }}
          >
            Cancel
          </Button>
          <Button loading={isSubmitting} type="submit" form="userForm">
            Save
          </Button>
        </div>
      }
    >
      <form id="userForm" onSubmit={handleSubmit(submit)} className={styles.form}>
        <Paper className={styles.paper}>
          <div className={styles.inviteToggle}>
            <ToggleButton size="xs" value={isInvite} handleToggle={() => setIsInvite(not)} />
            Send email invitation
          </div>
          <FormProvider {...form}>
            <div>
              {users.map((field, index) => (
                <UserRow
                  index={index}
                  key={field.id}
                  removeSelf={users.length > 1 ? () => removeUser(index) : undefined}
                  isInvite={isInvite}
                />
              ))}
            </div>
          </FormProvider>

          <Button className={styles.addUserButton} onClick={() => appendUser(getEmptyUser())}>
            + Add user
          </Button>
        </Paper>
        <Paper className={styles.paper}>
          <div className={styles.roleSection}>
            <h3>Role</h3>
            <div className={styles.rolePicker}>
              <Controller
                control={control}
                rules={{ validate: required }}
                name={`role_id`}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <RolePicker value={value} onChange={onChange} errorMessage={error?.message} />
                )}
              />
            </div>
          </div>
          <div className={styles.segmentsSection}>
            <div className={styles.header}>
              <h3>Segments</h3>
              <div className={styles.filters}>
                <SegmentTagsPicker
                  selectedIds={tagIds}
                  addTagId={tagId => setTagIds(ids => [...ids, tagId])}
                  removeTagId={tagId => setTagIds(without([tagId]))}
                />
                <div>
                  <SearchField
                    input={{ value: searchTerm, onChange: setSearchTerm }}
                    placeholder="Search segments"
                    onClear={() => setSearchTerm("")}
                  />
                </div>
              </div>
            </div>
            {isLoadingSegments ? (
              <LoadingIndicator />
            ) : (
              <Controller
                control={control}
                name="segmentPermissions"
                render={({ field: { value: segmentPermissions, onChange } }) => {
                  function selectAll() {
                    onChange(
                      Object.fromEntries(
                        segments.map(({ id }) => [
                          id,
                          {
                            enabled: true,
                            permission: segmentPermissions[id]?.permission ?? "read",
                          },
                        ]),
                      ),
                    )
                  }
                  function deselectAll() {
                    onChange(
                      Object.fromEntries(
                        segments.map(({ id }) => [
                          id,
                          {
                            enabled: false,
                            permission: segmentPermissions[id]?.permission ?? "read",
                          },
                        ]),
                      ),
                    )
                  }
                  function toggleAll() {
                    const isAllRead = segments.every(
                      ({ id }) =>
                        !segmentPermissions[id] || segmentPermissions[id].permission === "read",
                    )
                    onChange(
                      Object.fromEntries(
                        segments.map(({ id }) => [
                          id,
                          {
                            enabled: segmentPermissions[id]?.enabled ?? false,
                            permission: isAllRead ? "write" : "read",
                          },
                        ]),
                      ),
                    )
                  }

                  return (
                    <>
                      <div className={styles.selectors}>
                        <Button variant="text" onClick={selectAll}>
                          Select all
                        </Button>
                        <Button variant="text" onClick={deselectAll}>
                          Deselect all
                        </Button>
                        <Tippy
                          content="Selected role has the permission to edit all segments."
                          disabled={!isToggleAllDisabled}
                        >
                          <div>
                            <Button
                              variant="text"
                              onClick={toggleAll}
                              disabled={isToggleAllDisabled}
                            >
                              Toggle all
                            </Button>
                          </div>
                        </Tippy>
                      </div>
                      {segments.length === 0 && (
                        <div className={styles.emptyMessage}>There are no segments.</div>
                      )}
                      {segments.length > 0 && filteredSegments.length === 0 && (
                        <div className={styles.emptyMessage}>No segments found.</div>
                      )}
                      {filteredSegments.length > 0 && (
                        <div className={styles.segmentsList}>
                          {filteredSegments.map(segment => {
                            const isAdmin = hasWritePermission(selectedRole, segment.segment_type)
                            const { permission = "read", enabled: checked = false } =
                              segmentPermissions[segment.id] ?? {}
                            const checkboxId = `segment-${segment.id}`
                            return (
                              <div className={styles.segment} key={segment.id}>
                                <Checkbox
                                  id={checkboxId}
                                  checked={checked}
                                  label={segment?.name}
                                  className={classNames(styles.segmentName, {
                                    [styles.checked]: checked,
                                  })}
                                  onChange={e =>
                                    onChange(
                                      assoc(
                                        segment.id,
                                        {
                                          enabled: e.target.checked,
                                          permission: permission,
                                        },
                                        segmentPermissions,
                                      ),
                                    )
                                  }
                                />
                                <Tippy
                                  content={`Selected role has the permission to edit all ${segment.segment_type} segments.`}
                                  disabled={!isAdmin}
                                >
                                  <div>
                                    <ToggleSwitch
                                      size="small"
                                      disabled={isAdmin || !checked}
                                      name=""
                                      width="110px"
                                      checked={isAdmin ? "write" : permission ?? "read"}
                                      leftValue={"read"}
                                      rightValue={"write"}
                                      leftLabel="View"
                                      rightLabel="Edit"
                                      handleToggle={() =>
                                        onChange(
                                          assoc(
                                            segment.id,
                                            {
                                              enabled: checked,
                                              permission: permission === "read" ? "write" : "read",
                                            },
                                            segmentPermissions,
                                          ),
                                        )
                                      }
                                    />
                                  </div>
                                </Tippy>
                              </div>
                            )
                          })}
                        </div>
                      )}
                    </>
                  )
                }}
              />
            )}
          </div>
        </Paper>
      </form>
    </Page>
  )
}

type UserRowProps = {
  index: number
  removeSelf?: () => void
  isInvite: boolean
}

function UserRow({ index, removeSelf, isInvite }: UserRowProps) {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext<UserFormValues>()

  const { data: allUsers = [] } = useFetchAllUsers()
  const email = watch(`users.${index}.email`)
  const name = watch(`users.${index}.name`)

  function isEmailUnique(value: string, { users }: UserFormValues) {
    if (
      users.filter(({ email }) => email.trim().toLowerCase() === value.trim().toLowerCase())
        .length > 1
    ) {
      return "Email has been used more than once"
    }
    const existingUser = allUsers.find(whereEq({ email: value }))
    if (existingUser) {
      if (existingUser.deleted) {
        return "User is already in Trash"
      } else {
        return "User already exists"
      }
    }
    return undefined
  }

  return (
    <div className={styles.user}>
      <div className={styles.credentials}>
        <Avatar email={email} name={name} className={styles.avatar} />
        <TextInput
          {...register(`users.${index}.email`, {
            validate: { required, email: emailValidator, isEmailUnique },
          })}
          label="Email"
          placeholder="Email"
          error={errors.users?.[index]?.email?.message}
        />
        <TextInput
          {...register(`users.${index}.name`, {
            validate: { required, maxLength: maxLength(100) },
          })}
          label="Name"
          placeholder="Name"
          maxLength={100}
          error={errors.users?.[index]?.name?.message}
        />
        {!isInvite && (
          <TextInput
            {...register(`users.${index}.password`, {
              validate: { required, minLength: minLength(12) },
              shouldUnregister: true,
            })}
            label="Password"
            placeholder="Password"
            type="password"
            error={errors.users?.[index]?.password?.message}
          />
        )}
        {removeSelf && (
          <div className={styles.iconWrapper}>
            <IconButton
              icon="trash-alt"
              color="red"
              onClick={removeSelf}
              size="xs"
              tooltip="Remove"
              variant="outlined"
            />
          </div>
        )}
      </div>
    </div>
  )
}

function hasWritePermission(role: UserRole | null, segmentType: SegmentType): boolean {
  if (!role) {
    return false
  }
  if (role.id === ADMIN_ROLE_ID) {
    return true
  }
  return (
    (role.features.includes("foreign_segments/edit") &&
      (segmentType === "custom" || segmentType === "lookalike")) ||
    (role.features.includes("featured_segments/edit") && segmentType === "featured") ||
    (role.features.includes("prebuilt_segments/edit") && segmentType === "smart")
  )
}

function getEmptyUser() {
  return {
    name: "",
    email: "",
    password: "",
    segmentPermissions: [],
  }
}
