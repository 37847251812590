import styles from "./FunnelGroupForm.module.scss"
import { FunnelGroup } from "resources/funnelGroup/funnelGroupTypes"
import { Controller, useForm } from "react-hook-form"
import { UserRole } from "resources/userRole/userRoleTypes"
import { Permission } from "types/util"
import Paper from "components/UI/elements/Paper"
import { FunnelGroupPermission } from "resources/funnelGroupPermission/funnelGroupPermissionTypes"
import { always, assoc, dissoc, mapObjIndexed, pick } from "ramda"
import TextInput from "components/UI/elements/TextInput/TextInput"
import { required } from "helpers/validators.helper"
import ToggleButton from "components/UI/elements/ToggleButton/ToggleButton"
import { useFetchAllUserRoles } from "resources/userRole/userRoleQueries"
import Button from "components/UI/elements/Button/Button"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Tippy from "@tippyjs/react"
import Checkbox from "components/UI/elements/Checkbox/Checkbox"
import ToggleSwitch from "components/UI/elements/ToggleSwitch"
import { ADMIN_ROLE_ID } from "sharedConstants"

type FunnelGroupFormProps = {
  funnelGroup?: FunnelGroup
  acl?: Array<FunnelGroupPermission>
  onSubmit: (data: FunnelGroupFormValues) => void
}

export type FunnelGroupFormValues = {
  funnelGroup: Pick<FunnelGroup, "name" | "disabled">
  acl: Record<UserRole["id"], Permission>
}

export default function FunnelGroupForm({ funnelGroup, acl = [], onSubmit }: FunnelGroupFormProps) {
  const isCreate = !funnelGroup
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<FunnelGroupFormValues>({
    defaultValues: {
      funnelGroup: funnelGroup
        ? pick(["name", "disabled"], funnelGroup)
        : { name: "", disabled: true },
      acl: Object.fromEntries(
        acl.map(({ user_role_id, permission }) => [user_role_id, permission]),
      ),
    },
  })

  const userRolesQuery = useFetchAllUserRoles()

  return (
    <Paper>
      <form id="funnelGroupForm" className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.nameRow}>
          <TextInput
            {...register("funnelGroup.name", { validate: { required } })}
            label="Name"
            error={errors.funnelGroup?.name?.message}
          />
          {!isCreate && (
            <Controller
              control={control}
              name="funnelGroup.disabled"
              render={({ field: { value, onChange } }) => (
                <div className={styles.toggleWrapper}>
                  <label className={styles.label}>Show in analytics tab</label>
                  {/*
                    Note: `value` corresponds to `disabled` but in the UI we display it as "enabled",
                    hence the negation of `value`
                  */}
                  <ToggleButton value={!value} handleToggle={() => onChange(!value)} />
                </div>
              )}
            />
          )}
        </div>
        <Controller
          control={control}
          name="acl"
          render={({ field: { value, onChange } }) => {
            function selectAll() {
              onChange(
                Object.fromEntries(userRolesQuery.data!.map(({ id }) => [id, value[id] ?? "read"])),
              )
            }
            function deselectAll() {
              onChange({})
            }
            function toggleAll() {
              const allWrite = Object.values(value).every(permission => permission === "write")
              onChange(mapObjIndexed(always(allWrite ? "read" : "write"), value))
            }
            function toggleSelection(id: UserRole["id"]) {
              onChange(value[id] ? dissoc(id, value) : assoc(id, "read", value))
            }
            function togglePermission(id: UserRole["id"]) {
              onChange(assoc(id, value[id] === "read" ? "write" : "read", value))
            }

            if (userRolesQuery.isLoading) {
              return <LoadingIndicator />
            }

            if (!userRolesQuery.data) {
              return <div></div>
            }

            return (
              <div className={styles.rolesWrapper}>
                <div className={styles.rolesHeader}>
                  <label className={styles.label}>Roles</label>

                  <div className={styles.selectors}>
                    <Button variant="text" onClick={selectAll}>
                      Select all
                    </Button>
                    <Button variant="text" onClick={deselectAll}>
                      Deselect all
                    </Button>
                    <div>
                      <Button variant="text" onClick={toggleAll}>
                        Toggle all
                      </Button>
                    </div>
                  </div>
                </div>
                <div className={styles.rolesList}>
                  {userRolesQuery.data.map(({ id, name, features }) => (
                    <div key={id} className={styles.role}>
                      <Tippy
                        disabled={
                          !features.includes("funnels/*/view") &&
                          !features.includes("funnels/*/edit") &&
                          id !== ADMIN_ROLE_ID
                        }
                        content={`This role has the permission to ${
                          features.includes("funnels/*/edit") || id === ADMIN_ROLE_ID
                            ? "edit"
                            : "view"
                        } all funnel groups.`}
                      >
                        <div>
                          <Checkbox
                            label={<div className={styles.roleName}>{name}</div>}
                            checked={!!value[id]}
                            onChange={() => toggleSelection(id)}
                            disabled={
                              features.includes("funnels/*/view") ||
                              features.includes("funnels/*/edit") ||
                              id === ADMIN_ROLE_ID
                            }
                          />
                        </div>
                      </Tippy>

                      <Tippy
                        disabled={!features.includes("funnels/*/edit") && id !== ADMIN_ROLE_ID}
                        content="This role has the permission to edit all funnel groups."
                      >
                        <ToggleSwitch
                          size="small"
                          disabled={
                            features.includes("funnels/*/edit") ||
                            id === ADMIN_ROLE_ID ||
                            !value[id]
                          }
                          name=""
                          width="110px"
                          checked={
                            features.includes("funnels/*/edit") ||
                            id === ADMIN_ROLE_ID ||
                            value[id] === "write"
                              ? "write"
                              : "read"
                          }
                          leftValue={"read"}
                          rightValue={"write"}
                          leftLabel="View"
                          rightLabel="Edit"
                          handleToggle={() => togglePermission(id)}
                        />
                      </Tippy>
                    </div>
                  ))}
                </div>
              </div>
            )
          }}
        />
      </form>
    </Paper>
  )
}
