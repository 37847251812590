import React, { Component } from "react"
import { Router, Route, Switch } from "react-router-dom"
import { QueryClientProvider } from "@tanstack/react-query"
import { createBrowserHistory } from "history"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Bowser from "bowser"
import "./fontAwesomeImports"
import { getRoutePath } from "routes"
import getUserConfirmation from "./getUserConfirmation"
import Login from "pages/Login/Login"
import OktaCallback from "pages/OktaCallback/OktaCallback"
import AuthenticatedLayout from "./components/AuthenticatedLayout/AuthenticatedLayout"
import PasswordSet from "pages/PasswordSet/PasswordSet"
import PasswordReset from "pages/PasswordReset/PasswordReset"
import LogoutPage from "pages/Logout/Logout"
import "./App.scss"
import "react-toastify/dist/ReactToastify.css"
import { queryClient } from "./queryClient"
import { ToastCenter } from "./components/ToastCenter/ToastCenter"

// own history instance
export const history = createBrowserHistory({
  getUserConfirmation: getUserConfirmation,
})

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isValidBrowser: true,
      invalidBrowserWarningClosed: false,
    }
  }

  componentDidMount() {
    const browser = Bowser.getParser(window.navigator.userAgent)
    const isValidBrowser = browser.satisfies({
      chrome: ">=58",
      edge: ">=16",
      firefox: ">=54",
      chromium: ">=58",
      safari: ">=10.1",
      opera: ">=44",
    })
    this.setState({
      isValidBrowser,
    })
  }

  closeInvalidBrowserWarning = () => {
    this.setState({
      invalidBrowserWarningClosed: true,
    })
  }

  render() {
    const { isValidBrowser, invalidBrowserWarningClosed } = this.state
    return (
      <QueryClientProvider client={queryClient}>
        <Router getUserConfirmation={getUserConfirmation} history={history}>
          <div className="app">
            {!isValidBrowser && !invalidBrowserWarningClosed && (
              <div className="invalid-browser-message">
                <div className="wrapper">
                  <div className="left-part">
                    <FontAwesomeIcon
                      icon={["far", "exclamation-triangle"]}
                      className="exclamation-icon"
                    />
                    <p>
                      Bear in mind that you use old and unsupported browser. We recommend upgrading
                      to the latest{" "}
                      <a
                        href="https://www.apple.com/safari/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Safari
                      </a>
                      ,{" "}
                      <a
                        href="https://www.google.com/chrome/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Chrome
                      </a>{" "}
                      or{" "}
                      <a
                        href="https://www.mozilla.org/en-US/firefox/new/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Firefox
                      </a>
                      .
                    </p>
                  </div>
                  <div className="right-part">
                    <span className="warning-close" onClick={this.closeInvalidBrowserWarning}>
                      Dismiss
                    </span>
                  </div>
                </div>
              </div>
            )}
            <Switch>
              <Route path={getRoutePath("password.set")} component={PasswordSet} />
              <Route path={getRoutePath("password.reset")} component={PasswordReset} />
              <Route path={getRoutePath("login")} component={Login} />
              <Route path={getRoutePath("okta.callback")} component={OktaCallback} />
              <Route path={getRoutePath("logout")} component={LogoutPage} />
              <Route path={getRoutePath("home")} component={AuthenticatedLayout} />
            </Switch>
            <ToastCenter />
          </div>
        </Router>
      </QueryClientProvider>
    )
  }
}

export default App
