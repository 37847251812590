import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { abbreviateNumber } from "helpers/number.helper"
import { ChannelType } from "resources/channel/channelTypes"

import styles from "./SegmentChannelNumbers.module.scss"
import Tippy from "@tippyjs/react"
import {
  emitSegmentChannelCounts,
  useSegmentChannelCountsStore,
} from "resources/channel/channelCounts"
import { Segment } from "resources/segment/segment/segmentTypes"

type SegmentChannelNumbersProps = {
  hasUnsavedConditions: boolean
  isRefetching: boolean
  channelType: ChannelType
  segmentId: Segment["id"]
}

export default function SegmentChannelNumbers({
  hasUnsavedConditions,
  isRefetching,
  channelType,
  segmentId,
}: SegmentChannelNumbersProps) {
  const [isInitialLoading, setIsInitialLoading] = useState(false)

  useEffect(() => {
    setIsInitialLoading(true)
    emitSegmentChannelCounts({
      channelType,
      segmentIds: [segmentId],
      onAck: () => setIsInitialLoading(false),
    })
  }, [channelType, segmentId])

  const store = useSegmentChannelCountsStore()
  const data = channelType === "emails" ? store.emails : store.pushNotifications
  const error = channelType === "emails" ? store.emailsError : store.pushNotificationsError
  const isLoading = isInitialLoading || isRefetching

  return (
    <div className={styles.flexBox}>
      <div className={styles.titleWrapper}>
        <div className={styles.icon}>
          <FontAwesomeIcon icon={channelType === "emails" ? "envelope" : "bell"} />
        </div>
        <span className={styles.title}>{channelType === "emails" ? "Emails" : "Mobile push"}</span>
      </div>
      <span className={styles.outOf}>
        {isLoading && <LoadingIndicator size="sm" />}
        {!isLoading && error && (
          <>
            Not active{" "}
            <Tippy
              content={
                <div>
                  {channelType === "emails" ? "Email" : "Mobile Push"} channel is not activated. To
                  set this up, please contact{" "}
                  <a href="mailto:support@meiro.io" target="_blank" rel="noreferrer">
                    support@meiro.io
                  </a>{" "}
                  or get in touch with your Meiro account manager!
                </div>
              }
              interactive
            >
              <FontAwesomeIcon icon={["fas", "info-circle"]} />
            </Tippy>
          </>
        )}
        {!isLoading && !error && hasUnsavedConditions && <>N/A</>}
        {!isLoading &&
          !error &&
          !hasUnsavedConditions &&
          data?.count !== undefined &&
          data?.total_count !== undefined && (
            <>
              <b>{abbreviateNumber(data.count)}</b> out of {abbreviateNumber(data.total_count)}
            </>
          )}
      </span>
    </div>
  )
}
