import { useContext } from "react"
import { Controller, useForm } from "react-hook-form"
import { Prompt } from "react-router-dom"

import ChannelPaper from "../components/ChannelPaper/ChannelPaper"
import { ChannelWizardContext } from "../components/channelWizardContext"
import FileUploadDnD from "components/UI/components/FileUploadDnD/FileUploadDnD"
import FileField from "components/UI/elements/FileField/FileField"
import { jsonFile, maxFileSize, required } from "helpers/validators.helper"
import { useModifyPushNotificationsChannelFirebaseAccountData } from "resources/channel/channelQueries"
import { PushNotificationChannel } from "resources/channel/channelTypes"

import styles from "./FirebaseConfigurationFile.module.scss"

const MAX_UPLOAD_BYTES = 2 * 1024 * 1024

type FirebaseConfigurationFileFormValues = {
  firebaseFileList: FileList | null
}

type FirebaseConfigurationFileProps = {
  firebase_account_data: PushNotificationChannel["firebase_account_data"]
  isWizard?: boolean
}

export default function FirebaseConfigurationFile({
  firebase_account_data,
  isWizard = false,
}: FirebaseConfigurationFileProps) {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isSubmitted, isSubmitting },
  } = useForm<FirebaseConfigurationFileFormValues>({
    defaultValues: { firebaseFileList: null },
    mode: "onChange",
  })

  const { mutate, isLoading } = useModifyPushNotificationsChannelFirebaseAccountData()

  const { increment } = useContext(ChannelWizardContext)

  const onSubmit = ({ firebaseFileList }: FirebaseConfigurationFileFormValues) => {
    if (isLoading || !firebaseFileList) return

    const formData = new FormData()
    formData.append("firebase_account_data", firebaseFileList[0])

    mutate(
      { data: formData },
      {
        onSuccess: () => {
          if (isWizard) increment()
        },
      },
    )
  }

  const onFirebaseFileDrop = (files: FileList) => {
    if (files && files[0])
      setValue("firebaseFileList", files, { shouldDirty: true, shouldValidate: true })
  }

  const clearImage = () => {
    setValue("firebaseFileList", null, {
      shouldDirty: true,
      shouldValidate: isSubmitted,
    })
  }

  const skipSubmit =
    isWizard && firebase_account_data !== null && firebase_account_data.length > 0 && !isDirty

  return (
    <>
      <Prompt
        when={isDirty && !isSubmitting && !isSubmitted}
        message="Changes you made will not be saved."
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <ChannelPaper
          title="Firebase configuration file"
          isSubmitting={isLoading}
          isWizard={isWizard}
          skipSubmit={skipSubmit}
        >
          <div className={styles.root}>
            {firebase_account_data && (
              <div className={styles.firebaseDetails}>
                <span className={styles.configuredMarkup}>Configured</span>
              </div>
            )}
            <FileUploadDnD
              onFileDrop={onFirebaseFileDrop}
              label={`${firebase_account_data ? "re" : ""}configure firebase`}
            >
              <Controller
                control={control}
                name="firebaseFileList"
                rules={{
                  validate: {
                    required,
                    jsonFile,
                    maxFileSize: maxFileSize(MAX_UPLOAD_BYTES),
                  },
                }}
                render={({ field: { value, ref, onBlur, onChange } }) => (
                  <div>
                    <FileField
                      ref={ref}
                      error={errors.firebaseFileList?.message}
                      accept=".json"
                      value={value?.[0]?.name}
                      onBlur={onBlur}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const target = event.target as HTMLInputElement
                        const { files } = target
                        if (files && files[0]) onChange(files)
                      }}
                      onClearClick={clearImage}
                      className={styles.fileUploadField}
                    />
                    <p className={styles.fileUploadDesc}>
                      Firebase configuration JSON file. Max size: 2MB
                    </p>
                  </div>
                )}
              />
            </FileUploadDnD>
          </div>
        </ChannelPaper>
      </form>
    </>
  )
}
