import {
  faTrashAlt,
  faStickyNote,
  faCalendarAlt,
  faUser,
  faEnvelope,
  faPaperPlane,
  faClone,
  faQuestionCircle,
  faEyeSlash,
  faEye as faEyeRegular,
  faClock,
  faStar as faStarRegular,
  faHourglass as faHourglassRegular,
} from "@fortawesome/free-regular-svg-icons"
import {
  faCogs,
  faWrench,
  faHourglass,
  faExclamation,
  faExclamationTriangle,
  faPlay as faPlaySolid,
  faTimes as faTimesSolid,
  faMinus,
  faFilter,
  faUser as farUser,
  faSearch,
  faGripVertical,
  faChevronLeft,
  faChevronRight,
  faArrowCircleRight,
  faArrowCircleUp,
  faCaretUp,
  faCaretDown,
  faSave,
  faPlusCircle,
  faCheck,
  faUsers,
  faInfoCircle,
  faCloud,
  faCode,
  faFileContract,
  faLifeRing,
  faPencilAlt,
  faBan,
  faStar,
  faExpandAlt as faExpandAltSolid,
  faTrashAlt as faTrashAltSolid,
  faPencilAlt as faPencilAltSolid,
  faDotCircle as faDotCircleSolid,
  faClone as faCloneSolid,
  faPowerOff as faPowerOffSolid,
  faUserCog as faUserCogSolid,
  faTerminal as faTerminalSolid,
  faClock as faClockSolid,
  faChartBar as faChartBarSolid,
  faBell as faBellSolid,
  faArrowRight as faArrowRightSolid,
  faChevronDown as faChevronDownSolid,
  faChevronUp as faChevronUpSolid,
  faBullhorn as faBullhornSolid,
  faPaste as faPasteSolid,
  faDatabase,
  faGlobe,
  faFolder,
  faCopy,
  faMobile,
  faBolt,
  faCircleCheck,
  faWandMagicSparkles,
  faEnvelope as faEnvelopeSolid,
  faRepeat,
  faDesktop,
  faCirclePlus,
} from "@fortawesome/free-solid-svg-icons"
import {
  faTachometer,
  faSignOut,
  faSparkles,
  faLockAlt,
  faBallPile as faBallPileSolid,
  faUserEdit as faUserEditSolid,
  faBracketsCurly as faBracketsCurlySolid,
  faHouse as faHouseSolid,
  faMapMarkerAlt as faMapMarkerAltSolid,
  faQuestionCircle as faQuestionCircleSolid,
  faChevronsLeft as faChevronsLeftSolid,
  faBook as faBookSolid,
  faFolderMagnifyingGlass as faFolderMagnifyingGlassSolid,
  faBrainCircuit,
  faChartColumn as faChartColumnSolid,
  faEnvelopeOpenText as faEnvelopeOpenTextSolid,
  faPrint as faPrintSolid,
  faComputer as faComputerSolid,
  faMagnifyingGlass as faMagnifyingGlassSolid,
  faUserPlus as faUserPlusSolid,
  faGripLinesVertical as faGripLinesVerticalSolid,
  faCommentCode,
  faGearCode,
  faBinary,
} from "@fortawesome/pro-solid-svg-icons"
import {
  faTimes,
  faTag,
  faFileAlt,
  faDownload,
  faQuoteLeft,
  faHashtag,
  faToggleOn,
  faQuestion,
  faLongArrowUp,
  faLongArrowDown,
  faUserEdit,
  faChevronRight as faChevronRightRegular,
  faChevronLeft as faChevronLeftRegular,
  faPlusCircle as faPlusCircleRegular,
  faRedo,
  faUserPlus,
  faSortAmountUp,
  faSortAmountDown,
  faExclamationTriangle as faExclamationTriangleRegular,
  faBullseyePointer,
  faArrowCircleRight as faArrowCircleRightRegular,
  faCheck as faCheckRegular,
  faUsers as faUsersRegular,
  faList,
  faPencil as faPencilRegular,
  faCode as faCodeRegular,
  faExpandAlt,
  faCompressAlt,
  faAngleDoubleDown,
  faAngleDoubleUp,
  faSyncAlt,
  faChartLineDown,
  faUserTag as farUserTag,
  faChartLine as farChartLine,
  faBars as farBars,
  faLocation as farLocation,
  faHistory as farHistory,
  faPlus as farPlus,
  faMinus as farMinus,
  faExternalLink as faExternalLinkRegular,
  faEllipsisH as faEllipsisHRegular,
  faHouse as faHouseRegular,
  faFilter as faFilterRegular,
  faBell as faBellRegular,
  faTachometer as faTachometerRegular,
  faChartBar as faChartBarRegular,
  faBullhorn as faBullhornRegular,
  faCogs as faCogsRegular,
  faWrench as faWrenchRegular,
  faBook as faBookRegular,
  faFolderMagnifyingGlass as faFolderMagnifyingGlassRegular,
  faInfoCircle as faInfoCircleRegular,
  faObjectsColumn as faObjectsColumnRegular,
  faChartColumn as faChartColumnRegular,
  faArrowPointer as faArrowPointerRegular,
  faEyes,
} from "@fortawesome/pro-regular-svg-icons"
import {
  faPowerOff,
  faUser as falUser,
  faPencil,
  faEye,
  faHome,
  faTachometer as faTachometerLight,
  faClipboardListCheck,
  faUsers as faUsersLight,
  faFilter as faFilterLight,
  faChevronUp as faChevronUpLight,
  faChevronDown as faChevronDownLight,
} from "@fortawesome/pro-light-svg-icons"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faAndroid, faApple } from "@fortawesome/free-brands-svg-icons"

/* import necessary fontAwesome icons */
library.add(
  faGripLinesVerticalSolid,
  faUserPlusSolid,
  faPrintSolid,
  faMagnifyingGlassSolid,
  faComputerSolid,
  faArrowPointerRegular,
  faEnvelopeOpenTextSolid,
  faChartColumnRegular,
  faChartColumnSolid,
  faInfoCircleRegular,
  faFolderMagnifyingGlassRegular,
  faFolderMagnifyingGlassSolid,
  faBookRegular,
  faBookSolid,
  faChevronsLeftSolid,
  faQuestionCircleSolid,
  faWrenchRegular,
  faCogsRegular,
  faBullhornRegular,
  faChartBarRegular,
  faTachometerRegular,
  faMapMarkerAltSolid,
  faBellRegular,
  faHouseRegular,
  faHouseSolid,
  faFilterRegular,
  faWrench,
  faHourglass,
  faExclamation,
  faPlaySolid,
  faTimesSolid,
  faMinus,
  faCogs,
  faFileAlt,
  faFilter,
  faTachometer,
  faUser,
  faPowerOff,
  faRedo,
  faUserPlus,
  faTrashAlt,
  faTimes,
  faSearch,
  faTag,
  faLongArrowUp,
  faLongArrowDown,
  falUser,
  faPencil,
  faEye,
  faGripVertical,
  faChevronLeft,
  faArrowCircleRight,
  faDownload,
  faCaretUp,
  faCaretDown,
  faSave,
  faStickyNote,
  faPlusCircle,
  faQuoteLeft,
  faHashtag,
  faCalendarAlt,
  faToggleOn,
  farUser,
  faEnvelope,
  faSignOut,
  faQuestion,
  faChevronRight,
  faCheck,
  faPaperPlane,
  faUserEdit,
  faChevronRightRegular,
  faChevronLeftRegular,
  faPlusCircleRegular,
  faUsers,
  faUsersRegular,
  faInfoCircle,
  faSortAmountUp,
  faSortAmountDown,
  faClone,
  faCloud,
  faCode,
  faExclamationTriangle,
  faBullseyePointer,
  faQuestionCircle,
  faArrowCircleRightRegular,
  faHome,
  faTachometerLight,
  faClipboardListCheck,
  faUsersLight,
  faFilterLight,
  faCheckRegular,
  faEyeRegular,
  faEyeSlash,
  faFileContract,
  faLifeRing,
  faSparkles,
  faList,
  faClock,
  faChevronDownLight,
  faChevronUpLight,
  faPencilRegular,
  faCodeRegular,
  faPencilAlt,
  faExpandAlt,
  faCompressAlt,
  faAngleDoubleDown,
  faAngleDoubleUp,
  faSyncAlt,
  faChartLineDown,
  faArrowCircleUp,
  farUserTag,
  faExclamationTriangleRegular,
  faBan,
  faLockAlt,
  faStar,
  faExpandAltSolid,
  faTrashAltSolid,
  faPencilAltSolid,
  faDotCircleSolid,
  faBallPileSolid,
  faCloneSolid,
  farChartLine,
  farBars,
  farLocation,
  farHistory,
  faPowerOffSolid,
  faUserCogSolid,
  faTerminalSolid,
  farPlus,
  faClockSolid,
  faChartBarSolid,
  faBellSolid,
  faArrowRightSolid,
  faChevronDownSolid,
  faChevronUpSolid,
  faBullhornSolid,
  faStarRegular,
  farMinus,
  faHourglassRegular,
  faExternalLinkRegular,
  faPasteSolid,
  faDatabase,
  faGlobe,
  faUserEditSolid,
  faFolder,
  faCopy,
  faMobile,
  faBracketsCurlySolid,
  faEllipsisHRegular,
  faBolt,
  faCircleCheck,
  faWandMagicSparkles,
  faEnvelopeSolid,
  faObjectsColumnRegular,
  faRepeat,
  faBrainCircuit,
  faDesktop,
  faCirclePlus,
  faCommentCode,
  faAndroid,
  faApple,
  faEyes,
  faGearCode,
  faBinary,
)
