import React from "react"
import Button from "components/UI/elements/Button/Button"
import SelectField from "components/UI/elements/SelectField"
import { required } from "helpers/validators.helper"
import "./ParametersController.scss"
import { Controller, useForm } from "react-hook-form"
import TextInput from "components/UI/elements/TextInput/TextInput"

const getParameterOptions = values =>
  Object.entries(values ?? {}).map(([id, label]) => ({ value: id, label }))

export default function ParametersForm({ onSubmit, fieldsConfig, isEditable, initialValues }) {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues: initialValues })

  if (fieldsConfig.length === 0) {
    // do not display parameters section if there aren't any fields defined by admin
    return null
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="body">
        {fieldsConfig.map(({ input_mode, name, required: isRequired, title, values }) => {
          const isSelect =
            Array.isArray(input_mode) &&
            (input_mode.length !== 1 || input_mode[0] === "value_from_list")
          const options = getParameterOptions(values)
          const isCreatable = Array.isArray(input_mode) && input_mode.length !== 1

          if (isSelect) {
            return (
              <div className="form-row" key={name}>
                <Controller
                  name={name}
                  control={control}
                  rules={isRequired ? { validate: required } : undefined}
                  render={({ field, fieldState: { error } }) => (
                    <SelectField
                      input={field}
                      meta={{ touched: true, error: error?.message }}
                      placeholder={title}
                      label={`${title}${isRequired ? " *" : ""}`}
                      disabled={!isEditable}
                      options={options}
                      isClearable
                      isCreatable={isCreatable}
                    />
                  )}
                />
              </div>
            )
          } else {
            return (
              <div className="form-row" key={name}>
                <TextInput
                  {...register(name, isRequired ? { validate: required } : undefined)}
                  error={errors[name]?.message}
                  placeholder={title}
                  label={`${title}${isRequired ? " *" : ""}`}
                  disabled={!isEditable}
                />
              </div>
            )
          }
        })}
      </div>
      <div className="action-area">
        <div className="right-part">
          <Button loading={isSubmitting} type="submit" disabled={!isEditable}>
            Save Parameters
          </Button>
        </div>
      </div>
    </form>
  )
}
