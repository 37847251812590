import React, { ComponentPropsWithRef, forwardRef, useCallback, useRef } from "react"
import styles from "./FileField.module.scss"
import classnames from "classnames"
import Button from "../Button/Button"

const NO_FILE_CHOSEN = "No file chosen"

type FileFieldProps = {
  label?: string
  error?: string
  disabled?: boolean
  loading?: boolean
  inputClassName?: string
  onClearClick?: () => void
} & Omit<ComponentPropsWithRef<"input">, "type">
const FileField = forwardRef(function FileField(
  {
    label,
    error,
    className,
    disabled,
    value,
    inputClassName,
    onClearClick,
    loading = false,
    ...props
  }: FileFieldProps,
  ref: any,
) {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const clearFileSelection = useCallback(() => {
    if (inputRef.current) inputRef.current.value = ""
    onClearClick!()
  }, [onClearClick])

  return (
    <div
      className={classnames(styles.fileField, className, {
        [styles.hasError]: error,
        [styles.disabled]: disabled,
      })}
    >
      <label className={styles.label}>
        {label && <div className={styles.labelText}>{label}</div>}
        <input
          type="file"
          className={styles.hiddenInput}
          ref={input => {
            inputRef.current = input
            if (typeof ref === "function") {
              ref(input)
            } else if (ref) {
              ref.current = input
            }
          }}
          {...props}
          disabled={disabled}
        />
        <div className={styles.inputAndButtonWrapper}>
          <div className={classnames(styles.box, inputClassName)}>
            <p className={styles.fileName}>{value ?? NO_FILE_CHOSEN}</p>
            <Button disabled={disabled} loading={loading} className={styles.button}>
              Choose file
            </Button>
          </div>
          {onClearClick && (
            <Button
              color="red"
              disabled={!value}
              icon="trash-alt"
              variant="outlined"
              onClick={clearFileSelection}
              className={styles.clearImageButton}
            >
              Clear file
            </Button>
          )}
        </div>
        {error && <div className={styles.errorMessage}>{error}</div>}
      </label>
    </div>
  )
})

export default FileField
