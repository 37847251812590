import React, { useCallback, useRef, useState } from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import SegmentPickerBar from "./SegmentPickerBar/SegmentPickerBar"
import { Segment, SegmentType } from "resources/segment/segment/segmentTypes"
import { SegmentOption } from "./types"
import useClickOutHandler from "hooks/useClickOutHandler"
import useKeyListener from "hooks/useKeyListener"
import { useFetchAllSegments } from "resources/segment/segment/segmentQueries"
import styles from "./SegmentPicker.module.scss"
import { isNil, whereEq } from "ramda"
import { Link } from "react-router-dom"
import { getRoutePath } from "routes"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import IconButton from "components/UI/elements/IconButton/IconButton"
import ErrorTippy from "components/UI/elements/ErrorTippy/ErrorTippy"
import lookalikesIcon from "images/lookalike.svg"

type ActivatedSegmentsIds = Array<Segment["id"]>

type Props = {
  className?: string
  errorMessage?: string
  hideLabel?: boolean
  disableActivated?: boolean
  activatedOptions?: ActivatedSegmentsIds
  label?: string
  disabled?: boolean
  enabledTypes?: SegmentType[]
} & (
  | {
      returnOption: true
      multiple: true
      value: Array<Segment["id"]> | null
      onChange: (newValue: Array<SegmentOption>) => void
    }
  | {
      returnOption: true
      multiple?: false
      value: Segment["id"] | null
      onChange: (newValue: SegmentOption) => void
    }
  | {
      returnOption?: false
      multiple: true
      value: Array<Segment["id"]> | null
      onChange: (newValue: Array<Segment["id"]>) => void
    }
  | {
      returnOption?: false
      multiple?: false
      value: Segment["id"] | null
      onChange: (newValue: Segment["id"]) => void
    }
)

const SegmentPicker = (props: Props) => {
  const {
    className,
    errorMessage,
    value,
    hideLabel,
    disableActivated,
    activatedOptions,
    multiple = false,
    label = "Segment",
    disabled = false,
    enabledTypes,
  } = props
  const [focused, setFocused] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const inputRef = useRef<HTMLInputElement>(null)
  const [isLoading, setIsLoading] = useState(false)

  const onClose = useCallback(() => {
    setSearchTerm("")
    setFocused(false)
    inputRef.current?.blur()
  }, [])

  const { isOpen, open, close, ref, toggle } = useClickOutHandler({
    closeCallback: onClose,
    openCallback: () => setFocused(true),
    preventCloseIf: e =>
      [
        "SegmentPicker_input",
        "SegmentPicker_button",
        "SegmentPickerBar",
        "SegmentPicker_segments",
        "SegmentPicker_placeholder",
      ].some(className => (e.target as HTMLElement)?.className.includes?.(className)),
  })

  useKeyListener("Escape", close)

  const { data: segments, isLoading: areSegmentsLoading } = useFetchAllSegments()
  const allSegmentOptions =
    segments?.map(({ id, name, type }) => ({
      value: id,
      label: name,
      type,
      isActivated: !!activatedOptions?.includes(id),
    })) ?? []

  const selectedSegmentOption = allSegmentOptions?.find(whereEq({ value })) ?? null
  const selectedSegmentOptions = Array.isArray(value)
    ? allSegmentOptions?.filter(({ value: id }) => value.includes(id))
    : null

  // Can't do this filtering in the query select because I need ALL the segments in order to display
  // the selected segment chips
  const filteredSegmentOptions = searchTerm
    ? allSegmentOptions.filter(({ label }) =>
        label.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()),
      )
    : allSegmentOptions

  return (
    <ErrorTippy disabled={!errorMessage} content={errorMessage}>
      <div className={className} data-testid="segment-picker">
        {!hideLabel && <label className={styles.label}>{label}</label>}
        <div className={styles.wrapper}>
          {!multiple && isLoading && (
            <LoadingIndicator className={styles.spinner} size="sm" fixedWidth />
          )}
          {!multiple && !isNil(value) && !isLoading && (
            <Link
              className={classNames(styles.idChip, { [styles.disabled]: disabled })}
              to={getRoutePath("segments.custom.detail", { id: value })}
            >
              ID: {value}
            </Link>
          )}
          {!multiple && !isLoading && (
            <input
              ref={inputRef}
              autoComplete="off"
              className={classNames(styles.input, {
                [styles.error]: errorMessage,
                [styles.open]: focused || !selectedSegmentOption,
                [styles.hasValue]: !isNil(value),
              })}
              data-testid="segment-picker-input"
              placeholder={
                isLoading ? "" : selectedSegmentOption?.label ?? "Select segment or type to search"
              }
              type="text"
              value={searchTerm}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setSearchTerm(event.target.value)
              }
              onFocus={open}
              disabled={disabled}
            />
          )}
          {/* Using un-destructured props to enable type inferrence for onChange */}
          {props.multiple && (
            <div
              className={classNames(styles.segments, { [styles.focused]: focused })}
              onClick={e => {
                if (
                  ["SegmentPicker_segments", "SegmentPicker_placeholder"].some(className =>
                    (e.target as HTMLElement)?.className.includes(className),
                  )
                )
                  open()
              }}
            >
              {selectedSegmentOptions?.map(item => (
                <div
                  key={item.value}
                  className={classNames(styles.segment, {
                    [styles.lookalike]: item.type === "lookalike",
                  })}
                >
                  {item.type === "lookalike" && (
                    <div className={styles.lookalikeIconWrapper}>
                      <img src={lookalikesIcon} className={styles.icon} alt="" />
                    </div>
                  )}
                  <span>{item.label}</span>
                  <IconButton
                    color="white"
                    icon="times"
                    variant="transparent"
                    className={styles.closeButton}
                    onClick={async () => {
                      setIsLoading(true)
                      const newOptions =
                        selectedSegmentOptions?.filter(o => o.value !== item.value) ?? []
                      try {
                        await (props.returnOption
                          ? props.onChange(newOptions)
                          : props.onChange(newOptions.map(({ value }) => value)))
                      } catch {
                      } finally {
                        setIsLoading(false)
                      }
                    }}
                  />
                </div>
              ))}
              {areSegmentsLoading ? (
                <LoadingIndicator size="xs" fixedWidth />
              ) : (
                <input
                  ref={inputRef}
                  className={classNames(styles.placeholder, { [styles.hasValue]: searchTerm })}
                  placeholder="Select segment or type to search"
                  type="text"
                  value={searchTerm}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setSearchTerm(event.target.value)
                  }
                />
              )}
            </div>
          )}
          <button type="button" className={styles.button} onClick={toggle} disabled={disabled}>
            <FontAwesomeIcon
              icon={["fas", focused ? "caret-up" : "caret-down"]}
              className={styles.icon}
            />
          </button>
          {isOpen && (
            <SegmentPickerBar
              ref={ref}
              loading={areSegmentsLoading}
              searchMode={!!searchTerm}
              options={filteredSegmentOptions}
              selectedOption={multiple ? null : selectedSegmentOption}
              onSelect={async newOption => {
                setIsLoading(true)
                close()
                try {
                  // Using un-destructured props to enable type inferrence for onChange
                  if (props.multiple) {
                    const newOptions = selectedSegmentOptions
                      ? [...selectedSegmentOptions, newOption]
                      : [newOption]

                    await (props.returnOption
                      ? props.onChange(newOptions)
                      : props.onChange(newOptions.map(({ value }) => value)))
                  } else {
                    await (props.returnOption
                      ? props.onChange(newOption)
                      : props.onChange(newOption.value))
                  }
                } catch {
                } finally {
                  setIsLoading(false)
                }
              }}
              disableActivated={disableActivated}
              enabledTypes={enabledTypes}
            />
          )}
        </div>
      </div>
    </ErrorTippy>
  )
}

export default SegmentPicker
