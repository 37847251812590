import Button from "components/UI/elements/Button/Button"
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import TextInput from "components/UI/elements/TextInput/TextInput"
import { isUuidValid, onlyValidAttributesUsed, required, url } from "helpers/validators.helper"
import { last, omit, pick } from "ramda"
import React, { useCallback, useMemo, useRef, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { Prompt, useHistory, useLocation } from "react-router-dom"
import { useFetchAttributesMap } from "resources/attribute/attributeQueries"
import {
  useFetchAllDeletedMobilePushNotifications,
  useFetchAllMobilePushNotifications,
  useModifyMobilePushNotification,
} from "resources/mobilePushNotification/mobilePushNotificationQueries"
import {
  MobilePushNotification,
  MobilePushNotificationAction,
  ModifyMobilePushNotificationPayload,
} from "resources/mobilePushNotification/mobilePushNotificationTypes"
import ActivationView from "./components/ActivationView/ActivationView"
import NotificationPreview from "./components/NotificationPreview/NotificationPreview"
import UsedAttributesList from "components/UsedAttributesList/UsedAttributesList"
import styles from "./MobilePushNotificationForm.module.scss"
import { TTL_4_WEEKS_IN_SEC, replaceAttributePlaceholders, ttlOptions } from "./utils"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useHasAccess } from "resources/user/currentUserQueries"
import TextArea from "components/UI/elements/TextArea/TextArea"
import ActivationButton from "components/UI/components/ActivationButton/ActivationButton"
import { showToast } from "app/toast"
import { getRoutePath } from "routes"
import ConfirmModal from "components/UI/components/ConfirmModal"
import { MODAL, TOAST } from "sharedConstants"
import AttributePickerButton from "components/UI/components/AttributePickerButton/AttributePickerButton"
import Tippy from "@tippyjs/react"
import SelectField from "components/UI/elements/SelectField"
import { useSendTestMobilePushNotification } from "resources/mobilePushNotification/export/mobilePushNotificationExportQueries"
import Checkbox from "components/UI/elements/Checkbox/Checkbox"
import {
  useFetchPushNotificationApps,
  useFetchPushNotificationFirebaseApps,
} from "resources/channel/channelQueries"
import { PushNotificationApp } from "resources/channel/channelTypes"
import FileField from "components/UI/elements/FileField/FileField"
import { useUploadImageToPushNotifications } from "resources/image/imageQueries"
import { SelectOption } from "types/util"
import { generateRandomString } from "utilities/random"

type MPNFormView = "content" | "activation"

type SendToTestTokenOptions = Array<{ label: string; value: string; __isNew: boolean }>

type MobilePushNotificationFormProps = {
  isSaving: boolean
  notification?: MobilePushNotification
  onSubmit: (
    values: ModifyMobilePushNotificationPayload,
  ) => Promise<{ push_notification: MobilePushNotification }>
  onDelete?: () => void
  isFromCopy?: boolean
}

const IOS_LIMIT = 178
const ANDROID_TITLE_LIMIT = 65
const ANDROID_BODY_LIMIT = 240
const SAFETY_MARGIN = 10
const MAX_UPLOAD_BYTES = 512 * 1024 // 500 kb

const truncate = (text: string, limit: number) =>
  text.length <= limit ? text : limit <= 0 ? "" : text.slice(0, limit - 1) + "…"

const interactionOptions: Array<SelectOption<MobilePushNotificationAction>> = [
  {
    label: "Open app",
    value: "app",
  },
  {
    label: "Open browser",
    value: "browser",
  },
  {
    label: "Open deep link",
    value: "deeplink",
  },
]

type Image = "url" | "upload"

const imageOptions: Array<{ label: string; value: Image }> = [
  { label: "URL", value: "url" },
  { label: "Upload", value: "upload" },
]

export default function MobilePushNotificationForm({
  notification,
  onDelete,
  onSubmit,
  isSaving,
  isFromCopy = false,
}: MobilePushNotificationFormProps) {
  const [sendTestToTokens, setSendTestToTokens] = useState<SendToTestTokenOptions>([])
  const [profileId, setProfileId] = useState<string>()
  const [imageUpload, setImageUpload] = useState<Image>("url")
  const [imageFileName, setImageFileName] = useState("")

  const history = useHistory()
  const location = useLocation<{ activation?: boolean }>()
  const [view, setView] = useState<MPNFormView>(
    location.state?.activation ? "activation" : "content",
  )

  const { data: attributesMapById = {} } = useFetchAttributesMap({ includeHidden: true })
  const { data: apps } = useFetchPushNotificationApps()
  const { data: firebaseApps } = useFetchPushNotificationFirebaseApps()

  const appOptions = useMemo(() => {
    if (!apps || !firebaseApps) return []

    return (
      apps.map(app => {
        const hasAnyAndroidApp = firebaseApps.android_apps.some(({ app_id }) =>
          app.app_ids.includes(app_id),
        )
        const hasAnyIosApp = firebaseApps.ios_apps.some(({ app_id }) =>
          app.app_ids.includes(app_id),
        )

        return { label: app.name, value: app.id, android: hasAnyAndroidApp, ios: hasAnyIosApp }
      }) ?? []
    )
  }, [apps, firebaseApps])

  const defaultValues = notification
    ? {
        ...pick(["name", "title_template", "body_template", "ttl_seconds", "action"], notification),
        application: {
          id: notification.application?.id ?? "",
          android: notification.application?.android ?? false,
          ios: notification.application?.ios ?? false,
        },
        url: notification.url ?? "",
        image_url: notification.image_url ?? "",
      }
    : {
        name: "",
        title_template: "",
        body_template: "",
        ttl_seconds: TTL_4_WEEKS_IN_SEC,
        application:
          appOptions.length === 1
            ? {
                id: appOptions[0].value,
                android: appOptions[0].android,
                ios: appOptions[0].ios,
              }
            : {
                id: "",
                android: false,
                ios: false,
              },
        action: "app" as MobilePushNotificationAction,
        url: "",
        image_url: "",
      }

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting, isSubmitted },
    watch,
    setValue,
    reset,
    trigger,
  } = useForm({ defaultValues })

  const [mobilePushCopyPayload, setMobilePushCopyPayload] =
    useState<ModifyMobilePushNotificationPayload | null>(null)

  const activeMobilePushes = useFetchAllMobilePushNotifications()
  const deletedMobilePushes = useFetchAllDeletedMobilePushNotifications()
  const saveMutation = useModifyMobilePushNotification()
  const testMutation = useSendTestMobilePushNotification()

  const nameIsUnique = async (name: string) =>
    new Promise<string | undefined>(resolve => {
      while (true) {
        if (!activeMobilePushes.isLoading || !deletedMobilePushes.isLoading) break
      }

      const nameCollision = (existing: MobilePushNotification) =>
        existing.name === name && existing.id !== notification?.id

      if (
        activeMobilePushes.data?.push_notifications.some(nameCollision) ||
        deletedMobilePushes.data?.some(nameCollision)
      ) {
        resolve("This name is already used")
      }

      resolve(undefined)
    })

  const templateTitle = watch("title_template") ?? ""
  const titleFieldRef = useRef<HTMLInputElement | null>(null)
  const { ref: titleFieldOrigRef, ...titleRegister } = register("title_template", {
    validate: {
      required,
      onlyValidAttributesUsed: value => onlyValidAttributesUsed(value, attributesMapById),
    },
  })
  function insertAttributeIdToTitle(attributeId: string | null) {
    if (!titleFieldRef.current || !attributeId) {
      return
    }
    const { selectionStart, selectionEnd } = titleFieldRef.current
    setValue(
      "title_template",
      `${templateTitle.slice(0, selectionStart ?? 0)}{{${attributeId}}}${templateTitle.slice(
        selectionEnd ?? 0,
      )}`,
      {
        shouldDirty: true,
      },
    )
  }

  const templateBody = watch("body_template") ?? ""
  const bodyFieldRef = useRef<HTMLTextAreaElement | null>(null)
  const { ref: bodyFieldOrigRef, ...bodyRegister } = register("body_template", {
    validate: {
      required,
      onlyValidAttributesUsed: value => onlyValidAttributesUsed(value, attributesMapById),
    },
  })
  function insertAttributeIdToBody(attributeId: string | null) {
    if (!bodyFieldRef.current || !attributeId) {
      return
    }
    const { selectionStart, selectionEnd } = bodyFieldRef.current
    setValue(
      "body_template",
      `${templateBody.slice(0, selectionStart ?? 0)}{{${attributeId}}}${templateBody.slice(
        selectionEnd ?? 0,
      )}`,
      {
        shouldDirty: true,
      },
    )
  }

  const interactionUrl = watch("url") ?? ""
  const interactionUrlFieldRef = useRef<HTMLInputElement | null>(null)
  const { ref: interactionUrlFieldOrigRef, ...interactionUrlRegister } = register("url", {
    validate: {
      required: (value, { action }) => {
        if (action === "browser" || action === "deeplink") return required(value)
      },
      onlyValidAttributesUsed: (value, { action }) => {
        if (action === "browser" || action === "deeplink")
          return onlyValidAttributesUsed(value, attributesMapById)
      },
    },
  })
  function insertAttributeIdToUrl(attributeId: string | null) {
    if (!interactionUrlFieldRef.current || !attributeId) {
      return
    }
    const { selectionStart, selectionEnd } = interactionUrlFieldRef.current
    setValue(
      "url",
      `${interactionUrl.slice(0, selectionStart ?? 0)}{{${attributeId}}}${interactionUrl.slice(
        selectionEnd ?? 0,
      )}`,
      {
        shouldDirty: true,
      },
    )
  }

  const toSubmitPayload = (
    values: ModifyMobilePushNotificationPayload,
  ): ModifyMobilePushNotificationPayload => {
    let payload = values

    if (values.action === "app") payload = omit(["url"], payload)
    if (!values.image_url) payload = omit(["image_url"], payload)

    return payload
  }

  const saveMobilePush = (data: ModifyMobilePushNotificationPayload, onSuccess: () => void) => {
    if (!notification) return

    saveMutation.mutate(
      { id: notification.id, data: toSubmitPayload(data) },
      {
        onSuccess: () => {
          showToast("Mobile push saved.")
          onSuccess()
        },
      },
    )
  }

  const switchToActivation = () => {
    if (!isFromCopy && notification && isDirty)
      handleSubmit(formValues =>
        saveMobilePush(formValues, () => {
          setView("activation")
          reset(formValues)
        }),
      )()
    else setView("activation")
  }

  const copyMobilePush = (payload: ModifyMobilePushNotificationPayload) => {
    history.push({
      pathname: getRoutePath("channels.mobile-push.create"),
      state: { mobilePush: { ...notification, name: "copy of " + payload.name } },
    })
  }

  const sendTestMobilePushNotification = async () => {
    if (!notification?.id || !profileId || sendTestToTokens.length === 0) return

    if (!isUuidValid(profileId)) {
      showToast("Wrong Profile ID is used.", TOAST.TYPE.ERROR)
      return
    }

    await testMutation.mutateAsync({
      profileId: profileId,
      mobilePushNotificationId: notification.id,
      registrationTokens: sendTestToTokens.map(({ value }) => value),
    })
  }

  const clearImage = () => {
    setValue("image_url", "", {
      shouldDirty: true,
      shouldValidate: isSubmitted,
    })
    setImageFileName("")
  }

  const { mutate: upload, isLoading: isUploading } = useUploadImageToPushNotifications()

  const getImageUrl = useCallback(
    (files: FileList) =>
      new Promise<string | ArrayBuffer | null>((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(files[0])
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      }),
    [],
  )

  const getImageDimensions = (src: string | ArrayBuffer | null) => {
    if (!src || typeof src !== "string") return

    const img = new Image()

    const promise = new Promise<{ width: number; height: number }>((resolve, reject) => {
      img.onload = () => {
        const width = img.naturalWidth
        const height = img.naturalHeight

        resolve({ width, height })
      }

      img.onerror = () => reject(new Error())
    })

    img.src = src

    return promise
  }

  const uploadImage = (fileList: FileList) => {
    const formData = new FormData()

    const file = fileList[0]
    const fileExtension = last(file.type.split("/"))
    const generatedFileName = generateRandomString(24)
    const fileName = `${generatedFileName}.${fileExtension}`

    formData.append("file", file, fileName)

    upload(
      { data: formData },
      {
        onSuccess: ({ image_url }) => {
          setImageFileName(fileName)
          setValue("image_url", image_url, { shouldDirty: true, shouldValidate: isSubmitted })
        },
      },
    )
  }

  const onChangeFileField = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target
    if (!files || files.length === 0) return

    if (files[0].size > MAX_UPLOAD_BYTES) {
      showToast("Max size is 500 kB", TOAST.TYPE.ERROR)
      return
    }

    const imageSrc = await getImageUrl(files)

    try {
      const imageDimensions = await getImageDimensions(imageSrc)
      if (imageDimensions!.width < 300 || imageDimensions!.width > 2000) {
        showToast("Image width has to between 300 px and 2000 px", TOAST.TYPE.ERROR)
        return
      }
    } catch (error) {
      showToast("Couldn't validate image dimensions.", TOAST.TYPE.ERROR)
      return
    }

    uploadImage(files)
  }

  const hasAccess = useHasAccess()
  const isEditDisabled = !hasAccess.mobilePushNotifications.edit

  const titleWithExamples = replaceAttributePlaceholders(templateTitle, attributesMapById)
  const bodyWithExamples = replaceAttributePlaceholders(templateBody, attributesMapById)

  const isTitleCloseToAndroidLimit = titleWithExamples.length > ANDROID_TITLE_LIMIT - SAFETY_MARGIN
  const isBodyCloseToAndroidLimit = bodyWithExamples.length > ANDROID_BODY_LIMIT - SAFETY_MARGIN
  const isMessageCloseToIOSLimit =
    (titleWithExamples + bodyWithExamples).length > IOS_LIMIT - SAFETY_MARGIN

  const androidTitlePreview = truncate(titleWithExamples, ANDROID_TITLE_LIMIT)
  const androidBodyPreview = truncate(bodyWithExamples, ANDROID_BODY_LIMIT)
  const iOSTitlePreview = truncate(titleWithExamples, IOS_LIMIT)
  const iOSBodyPreview = truncate(bodyWithExamples, IOS_LIMIT - iOSTitlePreview.length)

  const isTestButtonDisabled = sendTestToTokens.length === 0 || !profileId || !notification

  const selectedAppId = watch("application.id")
  const selectedApp = appOptions.find(({ value }) => value === selectedAppId)
  const checkboxDisabled =
    isEditDisabled || (selectedApp ? !selectedApp.android || !selectedApp.ios : false)

  const interaction = watch("action") as MobilePushNotificationAction
  const imageUrl = watch("image_url")

  return (
    <>
      <Prompt
        when={(isDirty || isFromCopy) && !isSubmitting && !isSubmitted}
        message="Changes you made will not be saved."
      />

      <Paper className={styles.tabs}>
        <button
          onClick={() => setView("content")}
          className={classNames(styles.tabButton, { [styles.active]: view === "content" })}
        >
          Content
        </button>
        <FontAwesomeIcon icon={["far", "chevron-right"]} />
        <ActivationButton
          active={view === "activation"}
          disabled={isEditDisabled}
          isLoading={saveMutation.isLoading}
          channel="push_notifications"
          onClick={() => switchToActivation()}
        />
      </Paper>

      {view === "content" && (
        <form
          onSubmit={handleSubmit(async formValues => {
            await onSubmit(toSubmitPayload(formValues))
            reset(formValues)
          })}
        >
          <PaperHeader
            size="small"
            className={styles.header}
            dataTestId="mobile-push-notification-detail-header"
          >
            <TextInput
              label="Name"
              labelPosition="left"
              {...register("name", { validate: { required, nameIsUnique } })}
              disabled={isEditDisabled}
              error={errors.name?.message}
              placeholder="Name"
              maxLength={100}
              className={styles.name}
            />
            <div className={styles.buttons}>
              {notification && !isFromCopy && (
                <Button
                  variant="outlined"
                  color="grey"
                  disabled={isEditDisabled}
                  onClick={handleSubmit(formValues => {
                    setMobilePushCopyPayload(formValues)
                  })}
                >
                  Copy
                </Button>
              )}
              {notification && !isFromCopy && onDelete && (
                <Button disabled={isEditDisabled} color="red" variant="outlined" onClick={onDelete}>
                  Delete
                </Button>
              )}
              <Button disabled={isEditDisabled} loading={isSaving} type="submit">
                {notification && !isFromCopy ? "Save" : "Create"}
              </Button>
            </div>
          </PaperHeader>
          <Paper className={styles.application}>
            <span className={styles.text}>Select application:</span>
            <Controller
              control={control}
              name="application.id"
              rules={{ validate: required }}
              render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                <SelectField
                  isSimpleValue
                  disabled={isEditDisabled}
                  error={error?.message}
                  input={{
                    value,
                    onBlur,
                    onChange: (newValue: PushNotificationApp["id"]) => {
                      const app = appOptions.find(({ value }) => value === newValue)!
                      setValue("application.android", app.android, { shouldDirty: true })
                      setValue("application.ios", app.ios, { shouldDirty: true })

                      onChange(newValue)
                    },
                  }}
                  placeholder="Application"
                  options={appOptions}
                  className={styles.selectField}
                />
              )}
            />
            <Tippy
              content="This application supports only one operating system. To add an additional OS, modify the channel configurations."
              disabled={!checkboxDisabled}
            >
              <div className={styles.checkboxes}>
                <Controller
                  control={control}
                  name="application.android"
                  rules={{
                    validate: {
                      both: (android, { application }) => {
                        if (!application) return

                        if (!android && !application.ios) return "One OS has to be checked."
                        else return
                      },
                    },
                  }}
                  render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                    <Checkbox
                      checked={value}
                      disabled={checkboxDisabled}
                      errorMessage={error?.message}
                      label="Android"
                      onBlur={onBlur}
                      onChange={() => {
                        onChange(!value)
                        if (isSubmitted) trigger("application.ios")
                      }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="application.ios"
                  rules={{
                    validate: {
                      both: (ios, { application }) => {
                        if (!application) return

                        if (!ios && !application.android) return "One OS has to be checked."
                        else return
                      },
                    },
                  }}
                  render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                    <Checkbox
                      checked={value}
                      disabled={checkboxDisabled}
                      errorMessage={error?.message}
                      label="iOS"
                      onBlur={onBlur}
                      onChange={() => {
                        onChange(!value)
                        if (isSubmitted) trigger("application.android")
                      }}
                    />
                  )}
                />
              </div>
            </Tippy>
          </Paper>
          <Paper className={styles.content} dataTestId="mobile-push-notifications-detail-paper">
            <div className={styles.formFields}>
              <div className={styles.titleWrapper}>
                <TextInput
                  {...titleRegister}
                  readOnly={isEditDisabled}
                  error={errors.title_template?.message}
                  label="Title"
                  ref={(el: HTMLInputElement) => {
                    titleFieldOrigRef(el)
                    titleFieldRef.current = el
                  }}
                  className={styles.titleInput}
                />
                {!isEditDisabled && <AttributePickerButton onChange={insertAttributeIdToTitle} />}
              </div>
              {isTitleCloseToAndroidLimit && (
                <div className={styles.warning}>
                  Warning: the title might be truncated on Android devices if it exceeds{" "}
                  {ANDROID_TITLE_LIMIT} characters in length.
                </div>
              )}
              <UsedAttributesList text={templateTitle} attributesMapById={attributesMapById} />
              <div className={styles.bodyWrapper}>
                {!isEditDisabled && (
                  <AttributePickerButton
                    onChange={insertAttributeIdToBody}
                    className={styles.attributePicker}
                    isInTextArea
                  />
                )}
                <TextArea
                  {...bodyRegister}
                  readOnly={isEditDisabled}
                  error={errors.body_template?.message}
                  label="Body"
                  rows={10}
                  ref={(el: HTMLTextAreaElement) => {
                    bodyFieldOrigRef(el)
                    bodyFieldRef.current = el
                  }}
                />
              </div>
              {isMessageCloseToIOSLimit && (
                <div className={styles.warning}>
                  Warning: the notification might be truncated on iOS devices if the title and body
                  combined exceed {IOS_LIMIT} characters in length.
                </div>
              )}
              {isBodyCloseToAndroidLimit && (
                <div className={styles.warning}>
                  Warning: the body might be truncated on Android devices if it exceeds{" "}
                  {ANDROID_BODY_LIMIT} characters in length.
                </div>
              )}
              <UsedAttributesList text={templateBody} attributesMapById={attributesMapById} />
              <div>
                <label className={styles.label}>Image</label>
                <div className={styles.imageWrapper}>
                  <SelectField
                    input={{
                      value: imageUpload,
                      onChange: () => {
                        clearImage()

                        if (imageUpload === "url") setImageUpload("upload")
                        else setImageUpload("url")
                      },
                    }}
                    isSimpleValue
                    options={imageOptions}
                  />
                  {imageUpload === "url" ? (
                    <TextInput
                      disabled={isEditDisabled}
                      error={errors.image_url?.message}
                      placeholder="https://www.example.com/image.jpg"
                      className={styles.imageUrl}
                      {...register("image_url", { validate: { url } })}
                    />
                  ) : (
                    <Controller
                      control={control}
                      name="image_url"
                      render={({ fieldState: { error } }) => (
                        <FileField
                          disabled={isEditDisabled}
                          loading={isUploading}
                          accept="image/gif, image/jpeg, image/jpg, image/png"
                          error={error?.message}
                          value={imageFileName}
                          onChange={onChangeFileField}
                          onClearClick={() => clearImage()}
                          inputClassName={styles.imageInput}
                        />
                      )}
                    />
                  )}
                </div>
                <p className={styles.imageNote}>
                  Image type: GIF, JPG, JPEG, PNG; Max size: 500 kB; Aspect ratio: 2:1, common
                  sizes: 512x256px, 1440x720px; GIF animations are not supported on Android devices,
                  recommended format is JPG.
                </p>
              </div>
              <Controller
                control={control}
                name="ttl_seconds"
                rules={{ validate: required }}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <SelectField
                    isSimpleValue
                    disabled={isEditDisabled}
                    error={error?.message}
                    input={{ value, onChange }}
                    label="TTL"
                    placeholder="TTL"
                    options={ttlOptions}
                  />
                )}
              />
              <hr className={styles.line} />
              <Controller
                control={control}
                name="action"
                rules={{ validate: required }}
                render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                  <SelectField
                    isSimpleValue
                    disabled={isEditDisabled}
                    error={error?.message}
                    input={{
                      value,
                      onBlur,
                      onChange: (newAction: MobilePushNotificationAction) => {
                        onChange(newAction)
                        setValue("url", "", { shouldDirty: true })
                      },
                    }}
                    label="Interaction"
                    placeholder="Interaction"
                    options={interactionOptions}
                  />
                )}
              />
              {(interaction === "browser" || interaction === "deeplink") && (
                <>
                  <div className={styles.urlWrapper}>
                    <TextInput
                      {...interactionUrlRegister}
                      readOnly={isEditDisabled}
                      error={errors.url?.message}
                      label={interaction === "deeplink" ? "Deep link" : "URL"}
                      placeholder={interaction === "deeplink" ? "Deep link" : "URL"}
                      ref={(el: HTMLInputElement) => {
                        interactionUrlFieldOrigRef(el)
                        interactionUrlFieldRef.current = el
                      }}
                      className={styles.urlInput}
                    />
                    {!isEditDisabled && <AttributePickerButton onChange={insertAttributeIdToUrl} />}
                  </div>
                  <UsedAttributesList text={interactionUrl} attributesMapById={attributesMapById} />
                </>
              )}
            </div>
            <NotificationPreview
              androidTitlePreview={androidTitlePreview}
              androidBodyPreview={androidBodyPreview}
              iOSTitlePreview={iOSTitlePreview}
              iOSBodyPreview={iOSBodyPreview}
              imgSrc={imageUrl}
            />
          </Paper>
          <Tippy
            disabled={!!notification}
            content="Please save the mobile push notification first"
            placement="bottom"
          >
            <Paper className={styles.sendTestTo}>
              <span className={styles.text}>Send test to:</span>
              <SelectField
                isCreatable
                isMulti
                disabled={!notification}
                input={{
                  value: sendTestToTokens,
                  onChange: (values: SendToTestTokenOptions) => setSendTestToTokens(values),
                }}
                options={[]}
                placeholder="Fill in registration tokens"
                className={styles.selectField}
              />
              <TextInput
                disabled={!notification}
                placeholder="Fill in profile ID"
                value={profileId}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setProfileId(event.target.value)
                }
                className={styles.textField}
              />
              <Button
                disabled={isTestButtonDisabled}
                loading={
                  !isTestButtonDisabled && (saveMutation.isLoading || testMutation.isLoading)
                }
                size="md"
                onClick={handleSubmit(formValues => {
                  if (isDirty)
                    saveMobilePush(formValues, () => {
                      sendTestMobilePushNotification()
                      reset(formValues)
                    })
                  else sendTestMobilePushNotification()
                })}
              >
                Send
              </Button>
            </Paper>
          </Tippy>
          <ConfirmModal
            open={!!mobilePushCopyPayload}
            type={MODAL.TYPE.SUCCESS}
            handleClose={() => setMobilePushCopyPayload(null)}
            handleConfirm={() => {
              if (isDirty)
                saveMobilePush(mobilePushCopyPayload!, () => {
                  copyMobilePush(mobilePushCopyPayload!)
                })
              else copyMobilePush(mobilePushCopyPayload!)
            }}
            title="Copy Mobile Push campaign"
            text="We will save your current campaign, so you won't lose any data."
            isLoading={saveMutation.isLoading}
          />
        </form>
      )}

      {view === "activation" && (
        <ActivationView notification={!isFromCopy ? notification : undefined} />
      )}
    </>
  )
}
