import { useQuery } from "@tanstack/react-query"
import requestFactory from "api/request"

type AuthOptionsResponse = {
  is_credentials_login_enabled: boolean
  is_okta_login_enabled: boolean
  okta_auth_link: string
}

const authOptions = (): Promise<AuthOptionsResponse> => requestFactory("get", "/users/auth_options")

export function useFetchAuthOptions() {
  return useQuery(["authOptions"], authOptions, { staleTime: 60 * 1000 })
}
